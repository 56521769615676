import React,{useState,useEffect} from 'react';
import classes from '../styles/HowToUse.module.css';

import Slide1 from './StudentHowTo/Slide1';
import Slide2 from './StudentHowTo/Slide2';
import Slide3 from './StudentHowTo/Slide3';
import Slide4 from './StudentHowTo/Slide4';

import TSlide1 from './TeacherHowTo/TSlide1';
import TSlide2 from './TeacherHowTo/TSlide2';
import TSlide3 from './TeacherHowTo/TSlide3';
import TSlide4 from './TeacherHowTo/TSlide4';
import { useTranslation } from 'react-i18next';
function HowToUse() {
  
  let [slide,setSlide] = useState(0);
  let [role,setRole] = useState(0);
  let {t,i18n} = useTranslation();
  return(
    <div className={`container-fluid py-1 py-xl-5 ${classes.howtouse}`}>
        <div className='row d-flex justify-content-center align-items-center py-xl-5'>
          {slide==0&&
          <div className={`col-10 col-lg-8 col-xxl-5 text-light p-3 d-flex flex-column poppins justify-content-center align-items-center ${classes.container}`}>
            <h3 className='my-5'><b>{t('HowToUse.1')}</b></h3>
            <div className='w-100 mb-5 row d-flex flex-column flex-md-row justify-content-center align-items-center'>
              <div className={`col-10 my-4 col-md-5 mx-3 p-3 d-flex flex-column align-items-center justify-content-center border ${classes.border}`} style={{cursor:'pointer'}} onClick={()=>{setRole(2);setSlide(1)}}>
                <div className={`my-4 ${classes.studentPic}`}></div>
                <h5><b>{t('HowToUse.2')}</b></h5>
              </div>
              <div className={`col-10 my-4 col-md-5 mx-3 p-3 d-flex flex-column align-items-center justify-content-center border ${classes.border}`}  style={{cursor:'pointer'}} onClick={()=>{setRole(1);setSlide(1)}}>
                <div className={`my-4 ${classes.teacherPic}`}></div>
                <h5><b>{t('HowToUse.3')}</b></h5>
              </div>
            </div>
        </div>}
            {slide==1&&role==2&&
            <Slide1 next={setSlide}/>
            }
            {slide==2&&role==2&&
            <Slide2 next={setSlide}/>}
            {
              slide==3&&role==2&&
              <Slide3 next={setSlide}/>
            }
            {
              slide==4&&role==2&&
              <Slide4 />}

            {slide==1&&role==1&&
             <TSlide1 next={setSlide}/>
            }
            {slide==2&&role==1&&
            <TSlide2 next={setSlide}/>}
            {
              slide==3&&role==1&&
              <TSlide3 next={setSlide}/>
            }
            {
              slide==4&&role==1&&
              <TSlide4 />}
        </div>

    </div>
  )
}

export default HowToUse