import { Fade } from "react-awesome-reveal";
import {Zoom} from 'react-awesome-reveal'
import classes from '../../styles/HowItWorks.module.css';
import { useTranslation } from 'react-i18next';
function HowItWorks(){

    const {t,i18n} = useTranslation();
    return(
        <div className='container my-5'>
            <Fade direction='up' triggerOnce='true'>
            <h2 className='m-auto d-flex justify-content-center pt-5 mb-4'>{t('HomePage.HowItWorks.1')}</h2>
            </Fade>
            <div className='row d-flex justify-content-center py-5'>
                
                <div className="col-11 col-md-4 ">
                    <Fade direction="left" triggerOnce className="w-100 d-flex flex-column justify-content-center align-items-center">
                    <div className={classes.picContainer}>
                        <div className={classes.choose}>

                        </div>
                    </div>
                    <h4 className="my-5">{t('HomePage.HowItWorks.2')}</h4>
                    <p>
                        {t('HomePage.HowItWorks.3')}
                    </p>
                    </Fade>
                </div>
                
                
                <div className="col-11 col-md-4 d-flex flex-column justify-content-center align-items-center">
                    <Fade direction="up" triggerOnce className="w-100 d-flex flex-column justify-content-center align-items-center">
                    <div className={classes.picContainer}>
                        <video src="https://scholaristdemo.s3.us-east-2.amazonaws.com/anim.mp4" autoPlay={true} playsInline loop className="w-100" muted controls={false} controlsList="nodownload">

                        </video>
                    </div>
                    <h4 className="my-5">{t('HomePage.HowItWorks.4')}</h4>
                    <p>
                        {t('HomePage.HowItWorks.5')}
                    </p>
                    </Fade>
                </div>
             
             
                <div className="col-11 col-md-4 d-flex flex-column  align-items-center">
                    <Fade direction="right" triggerOnce className="w-100 d-flex flex-column  align-items-center">
                    <div className={classes.picContainer}>
                        <div className={classes.start}>

                        </div>
                    </div>
                    <h4 className="my-5">{t('HomePage.HowItWorks.6')}</h4>
                    <p>
                        {t('HomePage.HowItWorks.7')}
                    </p>
                    </Fade>
                </div>
            </div>
            
        </div>
    )
}
export default HowItWorks;