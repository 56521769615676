import { TeacherContext } from "./TeacherProvider";
import { useContext, useEffect, useState } from "react";
import { TokenKey } from "../utils";

export function useTeacher(){
    return useContext(TeacherContext)
}
export function useProvideTeacher(){
    
    var [courses,setCourses] = useState([]);
    let [availability,setAvailability] = useState([]);
    let baseURL = 'https://scholarist.com:8000/api/teachers/';

    async function getCourses(){
        try{
            let reply = await fetch(baseURL+'courses',{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            await setCourses(response.data)
            return {success:true};
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }
    async function addCourse(course){
        try{
            let reply = await fetch(baseURL+'new/course',{
                method:'POST',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body: course

            });
            let resp = await reply.json();
            if(resp.success){
                let array = [...courses,resp.data];
                console.log(array,'hai ab','....');
                await setCourses(array);
                return {success:true}
            }
            else{
              
                return {success:false,message:resp.message}
            }
        }catch(err){
            console.log(err);
            return {success:false,message:'Server error'}
        }
    }

    async function fetchStudents(courseId){
        try{
            let reply = await fetch(baseURL+'students'+`/${courseId}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            return {data:response.data,success:true}

        }catch(err){
            console.log(err);
            return {success:false}
        }
    }

    async function addResource(resource){
        try{
            let reply = await fetch(baseURL+'new/resource',{
                method:'POST',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body: resource
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data}
            }
            return {success:false,message:response.message}
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function fetchResources(courseId){
        try{
            let reply = await fetch(baseURL+`fetch/resources/${courseId}`,{
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data}
            }
            return {success:false,message:response.message}
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function getAssignments(course){
        try{
            let reply = await fetch(baseURL+`fetch/assignments/${course}`,{
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data}
            }
            return {success:false,message:response.message}
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function getSubmissions(name,id){
        try{
            let reply = await fetch(baseURL+`fetch/submissions?name=${name}&course=${id}`,{
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data}
            }
   
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function getStudentResource(id){
        try{
            
            let reply = await fetch(baseURL+`fetch/student/resource?id=${id}`,{
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            return response
   
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function addAvailability(data){
        console.log(data,'in provider....\n\n')
        try{
            let reply = await fetch(baseURL+'new/availability',{
                method:'POST',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body: data

            });
            let resp = await reply.json();
            if(resp.success){
                // let array = [...courses,resp.data];
                // console.log(array,'hai ab','....');
                await setAvailability(resp.data)
                return {success:true}
            }
            else{
              
                return {success:false,message:resp.message}
            }
        }catch(err){
            console.log(err);
            return {success:false,message:'Server error'}
        }
    }

    async function fetchReviews(course){
        try{
            let reply = await fetch(baseURL+`fetch/reviews/${course}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                
            });
            let resp = await reply.json();
            console.log(resp,'nao...\n\n\n')
            if(resp.success){
                return {success:true,data:resp.data}
            }
            else{
              
                return {success:false,message:resp.message}
            }
        }catch(err){
            console.log(err);
            return {success:false,message:'Server error'}
        }
    }

    return ({
        courses,
        setCourses,
        getCourses,
        addCourse,
        fetchStudents,
        addResource,
        fetchResources,
        getAssignments,
        getSubmissions,
        getStudentResource,
        addAvailability,
        fetchReviews
    })
}
