import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import { useAuth } from '../../../providers/ProvideAuth';
import { TokenKey } from '../../../utils';
import { useNavigate } from 'react-router-dom';

function DeleteAcc({show,handleClose}) {

    let [del,setDel] = useState();
    let auth = useAuth();
    let [editing,setEditing] = useState(false);
    let navigate = useNavigate();

    async function handleDelete(){
        setEditing(true)
        if(del=='delete'||del=='Delete'){
            try{
                let reply = await fetch('https://scholarist.com:8000/api/delete/acc',{
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
                });
                let response = await reply.json();
                if(response.success){
                    let res=await auth.logout();
                    toast.success('Account deleted successfully');
                    return navigate('/',{replace:true})
                }
            }catch(err){
                return toast.error('There has been an error in deleting your account. Please try again later and if the issue still persists, contact support')
            }
        }
        else{
            setEditing(false)
            return toast.error('Input provided incorrect.')
        }
        
    }

    function cleanUp(){
        setEditing(false);
        setDel(false);
        handleClose();
    }

    return (
        <Modal show={show} onHide={cleanUp} centered>
            <Modal.Header className='d-flex justify-content-center' closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-start justify-content-center'>
                <p><i>Dear {auth.user.name},</i></p>
                <p>Before you proceed, please be aware that deleting your account is an irreversible action. Here's what you should know:</p>
                <br />
                <b>What will be deleted</b>
                <p>Your profile information, learning progress, and all associated data will be permanently removed from our systems.</p>
                <b>What might remain:</b>
                <p>Certain data might be retained for legal or operational reasons, but it will no longer be identifiable to you.</p>
                <b>Impact on your access:</b>
                <p>You'll lose access to all features and content associated with your account.</p>
                <br />
                <p>Please take a moment to consider this decision. If you proceed, your account and its data will be deleted immediately. Money paid for any ongoing courses/lessons or those that will start in the future won't be refunded.
                If you have any questions or need any kind of assistance, you can contact our support team instead.</p>

                <br />
                <p><b>If you wish to proceed, write "delete" in the box below:</b></p>
                <Form.Control
                    type='text'
                    onChange={e=>setDel(e.target.value)}
                >
                </Form.Control>

            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <button type='button' className='btn btn-success mx-5' disabled={editing} onClick={cleanUp}>Cancel</button>
                <button type='button' className='btn btn-danger' onClick={handleDelete} disabled={!(editing||del)}>Delete</button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteAcc