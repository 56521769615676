import {useState} from 'react';
import classes from '../../styles/Home.module.css'
import Queue from './Queue'
import HowItWorks from './HowItWorks'
import Testimonials from './Testimonials'
import {Link,Navigate, useNavigate} from 'react-router-dom';
import { useAuth } from '../../providers/ProvideAuth';
import Login from '../Login';
import { useTranslation } from 'react-i18next';
import AIChatbot from '../ContactPage/AIChatbot';
import { ChatDots } from 'react-bootstrap-icons';
function Home() {

    const navigate = useNavigate();
    const auth = useAuth();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {t,i18n} = useTranslation();
    const style={
        wrapper:{
            position:'relative',
            backgroundColor:'black',
        }
    }
    return(
        <>
            {auth.user?
            <Navigate to='/user'/>
            :
            <>
            <AIChatbot show={show} onHide={handleClose}/>
        <div className={`container-fluid ${classes.extra_ctr}`}>
            <div className='row d-flex pt-5 justify-content-lg-center'>
                <div className='col-12 col-lg-6 col-xxl-5 order-2 order-lg-1 mb-4 mb-lg-0 d-flex flex-column align-items-center pt-5'>
                    <h1 className={`${classes.heading1} animate__animated animate__fadeInLeft my-2 mt-xl-5`}>{t('HomePage.Banner.1')}</h1>
                    <h1 className={`${classes.heading2} animate__animated animate__fadeInLeft my-2`}>{t('HomePage.Banner.2')}</h1>
                    <h5 className={`${classes.heading1} w-75 animate__animated animate__fadeInLeft my-3 `}><i>{t('HomePage.Banner.3')}</i></h5>
                    <button type='button' className={`w-75 my-5 btn btn-success`} onClick={()=>navigate('/use')}>{t('HomePage.Banner.4')}</button>
                </div>
                <div className='col-12 col-lg-5 order-1 order-lg-2 my-3 my-lg-0 d-flex justify-content-center'>
                    <div className='w-100 d-flex justify-content-center justify-content-lg align-items-center align-items-xl-center'>
                        <div className={`animate__animated animate__fadeInRight ${classes.demo_img}`}>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid mb-5" style={style.wrapper}>
            <div className={classes.wave}>
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z" class="shape-fill"></path>
                </svg>
            </div>
        </div>

        <div className='container'>
            <div className='row d-flex justify-content-center text-light'>
                <div className={`col-11 ${classes.hangingBox} p-5 bg-primary d-flex flex-column align-items-center animate__animated animate__rubberBand`}>
                    <h2 className={`text-center ${classes.tagLine}`}><b>{t('HomePage.Box.1')} </b></h2>
                    <h4 className='my-3 text-center'>{t('HomePage.Box.2')}</h4>
                    <div className='row d-flex flex-column flex-md-row justify-content-center flex-nowrap pt-4'>
                        <div className='col-12 my-2 col-md-4 d-flex'>
                           <div><img className='float-start align-top me-2 my-0' src='https://files.codingninjas.in/boxicon1-9659.svg' /></div>
                           <div><b>{t('HomePage.Box.3')}</b></div>
                        </div>
                        <div className='col-12 my-2 col-md-4 d-flex'>
                            <div><img className='float-start align-top me-2 my-0' src='https://files.codingninjas.in/boxicon2-9658.svg' /></div>
                            <div><b>{t('HomePage.Box.4')}</b></div>
                        </div>
                        <div className='col-12 my-2 col-md-4 d-flex'>
                            <div><img className='float-start align-top me-2 my-0' src='https://files.codingninjas.in/boxicon3-9657.svg' /></div>
                            <div><b>{t('HomePage.Box.5')}</b></div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Queue />

        <HowItWorks />
        
        <Testimonials />
        <div className={show?'d-none':classes.chat_icon} onClick={handleShow}>
                            <ChatDots size={30}/>
        </div>
        
        </>

            }
        </>
        
        
    )
}

export default Home;