import React from 'react'
import classes from '../../styles/HowToUse.module.css';
import { useTranslation } from 'react-i18next';
export default function Slide3(props) {

    let {t,i18n} = useTranslation();
  return (
    <div className={`col-10 col-lg-8 col-xxl-5 text-light py-3 px-2 d-flex flex-column poppins justify-content-center align-items-center  ${classes.container}`}>
           <h3 className=' my-5 px-2 text-warning px-lg-5'>{t('HowToUse.8')}</h3>

           <div className='w-100 d-flex flex-column justify-content-center mb-5'>
                <div className='w-100 d-flex flex-nowrap'>
                    <div className={`w-100`}>
                       
                        <div className={`d-flex flex-nowrap justify-content-center justify-content-lg-between align-items-center px-1 py-sm-5 px-sm-3 text-warning row w-100`}>
                            <div className='col-10 col-xl-4 my-4 my-sm-0 py-3  rounded d-flex flex-column align-items-center' >
                                <div className='w-100 px-3 py-3 rounded d-flex flex-column align-items-center' style={{backgroundColor: 'rgb(98, 67, 200)'}}>
                                    <div className={`${classes.course} rounded`}>

                                    </div>
                                    <h3 className='my-5 text-light text-start'><b>{t('PageContent.2')}</b></h3>
                                </div>
                            </div>
                            <div className='d-none d-xl-inline-block col-10 col-xl-4  py-3 rounded d-flex flex-column align-items-center' >
                                <div className='w-100 px-3 py-3 rounded d-flex flex-column align-items-center' style={{backgroundColor: 'rgb(234, 129, 31)'}}>
                                    <div className={`${classes.course} rounded`}>

                                    </div>
                                    <h3 className='my-5 text-light text-start'><b>{t('PageContent.3')}</b></h3>
                                </div>
                                
                            </div>
                            <div className='d-none d-xl-inline-block col-10 col-xl-4'>
                                <div className='w-100 px-3 py-3 rounded d-flex flex-column align-items-center' style={{backgroundColor: 'rgb(17,125,201)'}}>
                                    <div className={`${classes.course} rounded`}>

                                    </div>
                                    <h3 className='my-5 text-light text-start'><b>{t('PageContent.4')}</b></h3>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
           </div>
           <h3 className=' my-5 px-2 text-warning px-lg-5'>{t('HowToUse.9')}</h3>
            <div className='w-100 rounded' style={{backgroundImage:'url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Courses.png)',backgroundSize:'contain',height:0,paddingBottom:'50%',backgroundRepeat:'no-repeat'}}>

            </div>
           
           <div className='w-100 px-5 py-5 d-flex justify-content-end'>
               <button className='rounded btn btn-danger text-light' onClick={()=>props.next(4)}>{t('HowToUse.5')}</button>
           </div> 
    </div>
  )
}
