import React from 'react';
import classes from '../../styles/HowToUse.module.css';
import { useTranslation } from 'react-i18next';
export default function Slide3(props) {

  let {t,i18n} = useTranslation();
  return (
    <div className={`col-10 col-lg-8 col-xxl-5 text-light p-3 d-flex flex-column poppins justify-content-center align-items-center px-lg-5 ${classes.container}`}>
           <h3 className='py-0 py-lg-5 my-5 text-warning'>{t('HowToUse.15')}</h3>
            <div className={`w-100 d-flex flex-nowrap my-5`}>
                        <div className={`${classes.tsidebar}`}>

                        </div>
                        <div className={`d-flex flex-column justify-content-center justify-content-lg-between align-items-center px-1 px-lg-3 text-warning row ${classes.remaining}`}>
                            <div className={`mx-1 ${classes.create} rounded`}>

                            </div>
                            <p className='text-warning' style={{width:'80%'}}>{t('HowToUse.16')}</p>
                            <div className={`mx-1 ${classes.main} rounded`}>

                            </div>
                        </div>
            </div>
            <h3 className='py-0 py-lg-5 my-5 text-warning'>{t('HowToUse.17')}</h3>
           <div className='w-100 px-5 py-5 d-flex justify-content-end'>
               <button className='rounded btn btn-danger text-light' onClick={()=>props.next(3)}>{t('HowToUse.5')}</button>
           </div> 
    </div>
  )
}
