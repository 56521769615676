import { AuthContext } from "./AuthProvider";
import { useContext, useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';
import { deleteItemFromLocalStorage, getItemFromLocalStorage, setItemInLocalStorage, TokenKey } from "../utils";

export function useAuth(){
    return useContext(AuthContext)
}
export function useProvideAuth(){
    let b = localStorage.getItem(TokenKey) ? jwt_decode(localStorage.getItem(TokenKey)):null;
    var [user,setUser] = useState(b);
    var [chats,setChats] = useState([]);
    var [selectedChat,setSelectedChat] = useState();
    var [courses,setCourses] = useState([]);
    var [notifications,setNotifications] = useState([]);
    let baseURL = "https://scholarist.com:8000/api";
    
    

    useEffect(()=>{
        (async()=>{
            console.log('executed');
            const token = getItemFromLocalStorage(TokenKey);
            if(token){
                let user = jwt_decode(token)
                if((new Date().getTime())/1000<user.exp){
                    await setUser(user)
                }
                else{
                    await setUser(null)
                }
                
            }
        })();
    },[])
    
    const login = async(data)=>{
        let url = baseURL + "/signin";
        console.log('requested',url);
        try{
            let res = await fetch(url,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(data)
            })
            let token = await res.json();
            if(!token.success){
                return {success:false,message:token.message}
            }
            setItemInLocalStorage(TokenKey,token.data)
            var user_details = jwt_decode(token.data);
            setUser(user_details) 
            return {success:true,name:user_details.name, role:user_details.role};
        }catch(err){
            console.log('Error',err)
            return {success:false,message:""};
        }
        
    };
   
    const signup = async (data)=>{
        let url = baseURL + "/signup"
        
        try{
            let res = await fetch(url,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(data)
            })
            let token = await res.json();
            console.log('server says',token);
            if(token.success){
                return {success:true}
            }
        }catch(err){
            console.log('!!Error',err)
            return {sucess:false,message:err}
        }
    };

    const logout = async()=>{
        

        try{

            //Since JWT is sessionless authentication, calling server is useless
            deleteItemFromLocalStorage(TokenKey)
            setUser(null);
        }catch(err){
            console.log('Error in signing out',err)
        }

        
        
    };

    const googleLogin = async(res)=>{
        try{
            let reply = await fetch('https://scholarist.com:8000/api/auth/google',{
                method:'POST',
                headers:{
                    'Content-type':'application/json'
                },
                body:JSON.stringify({code:res})
            })
            let token = await reply.json();
            console.log('server says',token)
            setItemInLocalStorage(TokenKey,token.data)
            const user_details = jwt_decode(token.data)
            setUser(user_details)
            return {success:true,name:user_details.name, role:user_details.role};
        }catch(err){
            console.log('!!Error',err)
            return {sucess:false,message:err}
        }
    }

    const facebookLogin = async(res)=>{
        try{
            let reply = await fetch('https://scholarist.com:8000/api/auth/facebook',{
                method:'POST',
                headers:{
                    'Content-type':'application/json'
                },
                body:JSON.stringify({accessToken:res})
            });
            let token = await reply.json();
            console.log('server says',token)
            setItemInLocalStorage(TokenKey,token.data)
            const user_details = jwt_decode(token.data)
            setUser(user_details)
            return {success:true,name:user_details.name, role:user_details.role};
        }catch(err){
            console.log('!!Error',err)
            return {sucess:false,message:err}
        }
    }

    const appleLogin = async(res)=>{
        try{
            let reply = await fetch('https://scholarist.com:8000/api/apple/login',{
                method:'POST',
                headers:{
                    'Content-type':'application/json'
                },
                body:JSON.stringify(res)
            });
            let response = await reply.json();
            console.log('server says',response)
            setItemInLocalStorage(TokenKey,response.data)
            const user_details = jwt_decode(response.data)
            setUser(user_details)
            return {success:true,name:user_details.name, role:user_details.role};
        }catch(err){
            console.log('!!Error',err)
            return {sucess:false,message:err}
        }
    }

    const update = async(data)=>{
        let url = baseURL+'/update';
        try{
            let reply = await fetch(url,{
                method:'POST',
                headers:{
                    'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                },
                body: data
            })
            let token = await reply.json();
            setItemInLocalStorage(TokenKey,token.data);
            const user_details = jwt_decode(token.data)
            setUser(user_details)
            return {success:true}
        }catch(err){
            console.log('Error in updating user profile',err)
            return {success:false,message:err}
        }
    }

    const email_verified_set_user = (token)=>{
        setItemInLocalStorage(TokenKey,token);
        const user_details = jwt_decode(token)
        setUser(user_details)
    }
    return ({
        user,
        login,
        signup,
        logout,
        update,
        googleLogin,
        facebookLogin,
        appleLogin,
        chats,
        setChats,
        selectedChat,
        setSelectedChat,
        courses,
        setCourses,
        notifications,
        setNotifications,
        email_verified_set_user
    })
}
