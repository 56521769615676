import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

const Slide1 = (props) => {
    let {t,i18n} = useTranslation();
    let {setCourseType} = props;

    function handleLessonType(e){
        if(e.target.checked){
            setCourseType('per-lesson')
        }
    }
    function handlePackageType(e){
        if(e.target.checked){
            setCourseType('per-package')
        }
    }
  return (
    <div className='w-100 d-flex flex-column align-items-center'>
      <h5 className='mt-4 w_90 mb-2' style={{fontWeight:'normal'}}>{t('AddCourse.9')} <sup style={{color:'red'}}>*</sup></h5>
      <div className='d-flex w_90 flex-column align-items-start my-4'>
            <Form.Check
            name='lesson-type'
            value='per-package' 
            type={'radio'}
            id={`package`}
            label={t('AddCourse.11')}
            onChange={handlePackageType}
            defaultChecked
          />   
            <Form.Check
            name='lesson-type'
            value='per-lesson'
            className='my-3' 
            type={'radio'}
            id={`per-lesson`}
            label={t('AddCourse.10')}
            onChange={handleLessonType}
          />
            
      </div>
                        
    </div>
  )
}

export default Slide1