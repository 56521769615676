import ScrollableFeed from 'react-scrollable-feed';
import { useAuth } from '../../../providers/ProvideAuth';
import classes from '../../../styles/ScrollableChat.module.css'
function ScrollableChat({messages}){
    //We will use scrollable feed from react-scrollable-feed to 
    //organize chats UI for us
    //Functions will help in knowing who is sender of message,
    //and when is last message. This will help in avatar setup in chat etc
    var {user} = useAuth()
    function isSameSender(messages,m,i,userId){
        //whether concurrent messages are from same sender
        return (
            i<messages.length-1 && (
                (messages[i+1].sender.id !== m.sender.id ||
                messages[i+1].sender.id == undefined) && 
                messages[i].sender.id !==userId
            )
        )
    }
    function isLastMessage(messages,i,userId) {
        return (
            i===messages.length-1 && 
            messages[messages.length-1].sender.id !=userId &&
            messages[messages.length-1].sender.id
        )
    }
    return (
        <ScrollableFeed className={`w-100 ${classes.scroll}`} >
            {messages&&messages.map((m,i)=>
                <div className={`d-flex ${classes.w_98} my-2 mx-2`} key={i}
                style={{
                    justifyContent:`${
                        m.sender.id==user.id?'end':'start'
                        }`}}>
                    {m.sender.id!=user.id?
                    <>
                    {!((isSameSender(messages,m,i,user.id))||
                        isLastMessage(messages,i,user.id))&&(
                            <div className={`me-1  ${classes.avatar}`}>
                                
                            </div>
                        )}
                    </>
                    :
                    <></>
                    }
                    
                  
                    
                    {(isSameSender(messages,m,i,user.id)||
                    isLastMessage(messages,i,user.id))&&(
                        <div className={`${classes.avatar} me-2 `} style={{backgroundImage:`url(${m.sender.avatar})`}}>
                            
                        </div>
                    )}
                    {m.sender.id==user.id ?
                    <span className={`${classes.messageUser} rounded p-2`}>
                        {m.isFile?<a target='_blank'  className='no-decor' href={`${m.file_path}`}><i style={{color:'rgb(250,66,53)'}} className="fa-solid fa-file mx-2"></i><span className='text-decoration-underline'>{m.content}</span></a>:m.content}
                        
                    </span>
                    :
                    <span className={`${classes.messageSender} rounded p-2`}>
                        {m.isFile?<a target='_blank'  className='no-decor' href={`${m.file_path}`}><i style={{color:'rgb(250,66,53)'}} className="fa-solid fa-file mx-2"></i><span className='text-decoration-underline'>{m.content}</span></a>:m.content}
                    </span>}
                    
                </div>
            )}
        </ScrollableFeed>
    )
}
export default ScrollableChat