import React, {useEffect, useState} from 'react'
import classes from '../styles/Services.module.css';
import { Link } from 'react-router-dom';
import Login from './Login';
import {useTranslation} from 'react-i18next'
const Services = () => {

    const [showLogin, setShowLogin] = useState(false);
    const handleClose = () => setShowLogin(false);
    const handleShow = () => setShowLogin(true);
    const {t,i18n} = useTranslation();
    const styles = {
        bolder:{
            fontWeight:'bold'
        }
    }
        return(
            <>
            <Login show={showLogin}
            onHide={() => setShowLogin(false)}/>
                <div className='w-100'> 
                    <div className='container py-5'>
                        <div className='row py-5'>
                            <div className='col-12 col-md-6 order-md-2'>
                                <div className='row w-100'>
                                        <div className='col-7'>
                                            <div className={`${classes.english}`}></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center'>
                                            <div className={`${classes.duolingo}`}></div>
                                        </div>
                                    </div>
                                </div>
                            <div className='col-12 col-md-6 order-md-1 d-flex flex-column'>
                                <h3 className={`${classes.text}`}>{t('Courses.1')}</h3>
                                <div className='my-3' >
                                    {t('Courses.2')}
                                </div>
                                <div className='my-4 d-flex justify-content-evenly'>
                                    <button className='btn btn-outline-success d-block p-2' onClick={handleShow}>{t('Courses.3')} <i className='fa-solid fa-chevron-down mx-1'></i></button>
                                    <Link className='no-decor' to='/contact'><button className={`btn ${classes.trialbtn}`}>{t('Courses.4')}</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`w-100 ${classes.bg}`}>
                    <div className='container py-5'>
                        <div className='row py-5'>
                            <div className='col-12 col-md-6 order-md-1 d-flex justify-content-center'>
                                <div className='row w-100'>
                                        <div className='col-7'>
                                            <div className={`${classes.french}`}></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center'>
                                            <div className={`${classes.frenchexam}`}></div>
                                        </div>
                                </div>
                                
                            </div>
                            <div className='col-12 col-md-6 order-md-2 d-flex flex-column'>
                                <h3 className={`${classes.text}`}>{t('Courses.5')}</h3>
                                <div className='my-3' >
                                    {t('Courses.6')}
                                </div>
                                <div className='my-4 d-flex justify-content-evenly'>
                                    <button className='btn btn-outline-success d-block p-2' onClick={handleShow}>{t('Courses.3')} <i className='fa-solid fa-chevron-down mx-1'></i></button>
                                    <Link className='no-decor' to='/contact'><button className={`btn ${classes.trialbtn}`}>{t('Courses.4')}</button></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='w-100'> 
                    <div className='container py-5'>
                        <div className='row py-5'>
                            <div className='col-12 col-md-6 order-md-2'>
                                <div className='row w-100'>
                                        <div className='col-7'>
                                            <div className={`${classes.dele}`}></div>
                                        </div>
                                        <div className='col-5 d-flex align-items-center'>
                                            <div className={`${classes.siele}`}></div>
                                        </div>
                                    </div>
                                </div>
                            <div className='col-12 col-md-6 order-md-1 d-flex flex-column'>
                                <h3 className={`${classes.text}`}>{t('Courses.7')}</h3>
                                <div className='my-3' >
                                    {t('Courses.8')}
                                </div>
                                <div className='my-4 d-flex justify-content-evenly'>
                                    <button className='btn btn-outline-success d-block p-2' onClick={handleShow}>{t('Courses.3')} <i className='fa-solid fa-chevron-down mx-1'></i></button>
                                    <Link className='no-decor' to='/contact'><button className={`btn ${classes.trialbtn}`}>{t('Courses.4')}</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}

export default Services

