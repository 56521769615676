import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollBy(0,-(5*window.pageYOffset))
  }, [pathname]);

  return (
    <>{console.log(pathname)}</>
  )
  
}