import React, { useEffect } from 'react'
import toast from 'react-hot-toast';
import { useNavigate,Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import classes from '../../../styles/Sidebar.module.css';
import { TokenKey } from '../../../utils';
import { useSocket } from '../../../providers/ProvideSocket';
import {useTranslation} from 'react-i18next';
const Sidebar = () => {
        const navigate = useNavigate();
        const auth = useAuth();
        const {joinRoom,msg,setMsg} = useSocket();
        const location = useLocation();
        let {t,i18n} = useTranslation();
        
        
        
  return (
        <div className="offcanvas offcanvas-start" tabindex="-1" id="sidebarOffcanvas" aria-labelledby="sidebarOffcanvasLabel" style={{backgroundColor:'rgb(33,41,54)'}}>
                <div className="offcanvas-header d-flex flex-column">
                        <div className="w-100 d-flex justify-content-center">
                                <div style={styles.logo}>

                                </div>
                        </div>
                        <div className='text-light w-100 d-flex justify-content-center'>
                                <h6>Admin Panel</h6> 
                        </div>   
                </div>
                        
                
                
                <div className="offcanvas-body">
                        <div className="list-group list-group-flush my-3 pb-5">
                                <Link to='/admin' className={`d-flex align-items-center px-2 ps-3 py-3 my-2 rounded-pill list-group_item ${location.pathname=='/admin'?'active_purple':''}`}><i
                                className={` fa-solid fa-house-user me-2`}></i><div><b>Dashboard</b></div></Link>
                                <Link to="/admin/analytics" className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item fw-bold ${location.pathname.includes('/admin/analytics')?'active_purple':''}`}><i
                                        className={` fa-solid fa-bell me-2`}></i>Analytics</Link>
                                <Link to='/admin/students' className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item  fw-bold ${location.pathname.includes('/admin/students')?'active_purple':''}`}><i
                                        className={`fa-solid fa-school me-2`}></i>Students</Link>
                                <Link to='/admin/teachers' className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item fw-bold ${location.pathname.includes('/admin/teachers')?'active_purple':''}`}><i
                                        className={` fa-solid fa-chalkboard-user me-2`}></i>Teachers</Link>
                                <Link to='/admin/menu' className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item fw-bold ${location.pathname.includes('/admin/menu')?'active_purple':''}`}><i
                                        className={`fa-solid fa-list me-2`}></i>Menu</Link>
                                <Link to='/admin/finances' className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item ${classes.gear} fw-bold ${location.pathname.includes('/admin/finances')?'active_purple':''}`}><i
                                        className="fa-solid fa-dollar-sign me-2"></i>Finances</Link>
                                <Link to='/admin/chat' className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item ${classes.gear} fw-bold ${location.pathname.includes('/admin/chat')?'active_purple':''}`}><i
                                        className={`fa-solid fa-comment me-2 `}></i>Chat</Link>
                                <Link to='/admin/support' className={`px-2 ps-3 py-3 my-2 d-flex align-items-center rounded-pill list-group_item ${classes.gear} fw-bold ${location.pathname.includes('/admin/support')?'active_purple':''}`}><i
                                        className={`fa-solid fa-headset me-2 `}></i>Support</Link>
                        </div>
                        
                </div>
        </div>

  )
}

const styles={
 logo:{
        width:'80%',
        height:0,
        paddingBottom: '30%',
        backgroundImage: 'url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/logo.svg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
 }
}
export default Sidebar