import React from 'react'
import { Link } from 'react-router-dom';
const Cancel = () => {

    return(
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 d-flex flex-column align-items-center justify-content-center'>
                    <h2 className='text-center my-4'>We are sorry but payment didn't succeed.</h2>
                    <Link className='no-decor' to='/user'><button className='btn btn-success'>Head to Dashboard</button></Link>
                </div>
            </div>

        </div>
    )
}

export default Cancel