import React, { useEffect, useState } from 'react'
import { useParams,Link, Navigate,useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import toast from 'react-hot-toast';
import { Rating } from 'react-simple-star-rating';
import {getItemFromLocalStorage, TokenKey } from "../../../utils";
import { useTeacher } from '../../../providers/ProvideTeacher';
import { useAuth } from '../../../providers/ProvideAuth';
import { useTranslation } from 'react-i18next';
import { MdSort } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import classes from '../../../styles/Courses.module.css'
const Courses = () => {
    const teacher = useTeacher();
    const auth = useAuth();
    let navigate = useNavigate();
    var [courses,setCourses] = useState({english:[],french:[],spanish:[]});
    let {t,i18n} = useTranslation();
    let [cart, setCart] = useState([]);
    let [loading,setLoading] = useState(true);

    useEffect(()=>{
        (async()=>{
            try{

                let reply = await fetch(`https://scholarist.com:8000/api/courses?lang=${i18n.language}`);
                let response = await reply.json();
                await setCourses(response.data);
                setLoading(false);
            }catch(err){
                return toast.error('Some error in fetching courses')
            }
        })();
    },[]);
    function slideLeftEnglish(){
        var slider = document.getElementById('eslider');
        slider.scrollLeft = slider.scrollLeft-250
    }

    function slideRightEnglish(){
        var slider = document.getElementById('eslider');
        slider.scrollLeft = slider.scrollLeft+250

    }
    function slideLeftFrench(){
        var slider = document.getElementById('fslider');
        slider.scrollLeft = slider.scrollLeft-250
    }

    function slideRightFrench(){
        var slider = document.getElementById('fslider');
        slider.scrollLeft = slider.scrollLeft+250

    }
    function slideLeftSpanish(){
        var slider = document.getElementById('sslider');
        slider.scrollLeft = slider.scrollLeft-250
    }

    function slideRightSpanish(){
        var slider = document.getElementById('sslider');
        slider.scrollLeft = slider.scrollLeft+250

    }
    
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    
    const styles={
        profileIcon:{
            backgroundImage:`url(${auth.user.avatar})`
        },
        heading:{
            fontFamily:'Baloo'
        },
        neon:{
            color:'rgb(144,251,220)!important',
            borderColor:'rgb(144,251,220)!important'
        }
    }
    return(
        <>
            <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light' /></a>
                    </div>
                    
                    <h2 className="m-0 poppins"><b>{t('Courses_2.1')}</b></h2>

                    <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
                        <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </nav>

                <div className='container-fluid text-white'>

                    <div className='row d-flex my-5 justify-content-center'>
                        <div className='col-10 col-xxl-7 py-4 ps-2 px-lg-4' style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                            <div className={` ${classes.bg_light} py-5 d-flex `} style={{position:'relative'}}>
                                <div className='ps-3 ps-lg-4 d-flex flex-column align-items-center me-xxl-5' style={{width:'55%'}}>
                                    <h3 className='poppins my-4'>🔥 <b>{t('PageContent.22')}</b></h3>
                                    <p className='poppins'>{t('PageContent.24')}</p>
                                </div>
                                <div className={`${classes.man_img}`}>

                                </div>
                            </div>
                        </div>
                    </div>
                  
                    <div className='row d-flex py-5 justify-content-center'>
                     <div className='col-12'>
                        {loading ? 
                                <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                    <CircularProgress />
                                </Box>
                        :
                            <>
                            {/* For english courses */}
                                <h3 className='m-auto my-5 px-2 text-light' style={styles.heading}>
                                    {t('Courses.1')}
                                </h3>
                                <div className='row my-5'>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                        <div className={classes.slideLeft} onClick={slideLeftEnglish}>
                                        </div>
                                    </div>
                                    <div className={`col-8 col-md-6 col-lg-8 col-xxl-10 d-flex justify-content-center align-items-center`}>
                                        {courses.english.length>0 ? 
                                        <div className={`row d-flex flex-nowrap ${classes.scroll} align-items-stretch`} id='eslider'>
                                            {courses.english.map((item,index)=>
                                            <div className='col-12 col-sm-9 col-md-9 col-lg-7 col-xxl-2 my-4 d-flex flex-column align-items-center mx-3 p-4' style={{backgroundColor:'rgb(41,45,54)',borderRadius:20}} key={index}>
                                                    <div className={`${classes.bg}`} style={{backgroundImage:`url(${item.avatar})`}}>

                                                    </div>
                                                    <div className='d-flex w-100 flex-column align-items-center ' style={{flexGrow:1}}>
                                                        <div className='mt-4 text-center' style={{fontWeight:'bold',color:'rgb(212,93,46)'}}>
                                                            {item.name}
                                                        </div>
                                                        <div className=' my-4 text-center' >
                                                            {item.description}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='d-flex w-100 justify-content-end' >
                                                            <Link to={`/teacher/course/info/${item.id}`} className='no-decor'><button className={`btn text-light ${classes.button_purple}`}>View More</button></Link>
                                                    </div>
                                            </div>
                                            )}
                                        </div>
                                        :
                                        <div className='w-100 text-center text-light'>
                                            <i>{t('Courses_2.10')}</i>
                                        </div>}
                                        
                                    </div>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                                <div className={classes.slideRight} onClick={slideRightEnglish}>
                                                </div>
                                    </div>
                                </div>  
                                {/* For French Courses */}
                                <h3 className='m-auto my-5 px-2 text-light' style={styles.heading}>
                                    {t('Courses.5')}
                                </h3>
                                <div className='row my-5'>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                        <div className={classes.slideLeft} onClick={slideLeftFrench}>
                                        </div>
                                    </div>
                                    <div className={`col-8 col-md-6 col-lg-8 col-xxl-10 d-flex justify-content-center align-items-center`}>
                                        {courses.french.length>0 ? 
                                        <div className={`row d-flex flex-nowrap ${classes.scroll}`} id='fslider'>
                                            {courses.french.map((item,index)=>
                                            <div className='col-12 col-sm-9 col-md-9 col-lg-7 col-xxl-2 my-4 d-flex flex-column align-items-center mx-3 p-4' style={{backgroundColor:'rgb(41,45,54)',borderRadius:20}} key={index}>
                                                <div className={`${classes.bg}`} style={{backgroundImage:`url(${item.avatar})`}}>

                                                </div>
                                                <div className='d-flex w-100 flex-column align-items-center ' style={{flexGrow:1}}>
                                                    <div className='mt-4 text-center' style={{fontWeight:'bold',color:'rgb(212,93,46)'}}>
                                                        {item.name}
                                                    </div>
                                                    <div className=' my-4 text-center' >
                                                        {item.description}
                                                    </div>
                                                    
                                                </div>
                                                <div className='d-flex w-100 justify-content-end' >
                                                        <Link to={`/teacher/course/info/${item.id}`} className='no-decor'><button className={`btn text-light ${classes.button_purple}`}>View More</button></Link>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                        :
                                        <div className='w-100 text-center text-light'>
                                            <i>{t('Courses_2.10')}</i>
                                        </div>}
                                        
                                    </div>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                                <div className={classes.slideRight} onClick={slideRightFrench}>
                                                </div>
                                    </div>
                                </div>  
                                {/* For Spanish Courses */}
                                <h3 className='m-auto my-5 px-2 text-light' style={styles.heading}>
                                    {t('Courses.7')}
                                </h3>
                                <div className='row my-5'>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                        <div className={classes.slideLeft} onClick={slideLeftSpanish}>
                                        </div>
                                    </div>
                                    <div className={`col-8 col-md-6 col-lg-8 col-xxl-10 d-flex justify-content-center align-items-center`}>
                                        {courses.spanish.length>0 ? 
                                        <div className={`row d-flex flex-nowrap ${classes.scroll}`} id='sslider' >
                                            {courses.spanish.map((item,index)=>
                                                <div className='col-12 no-decor col-sm-9 col-md-9 col-lg-7 col-xxl-2 my-4 d-flex flex-column align-items-center mx-3 p-4' style={{ backgroundColor:'rgb(41,45,54)',borderRadius:20}}  key={index}>
                                                    <div className={`${classes.bg}`} style={{backgroundImage:`url(${item.avatar})`}}>

                                                    </div>
                                                <div className='d-flex w-100 flex-column align-items-center ' style={{flexGrow:1}}>
                                                    <div className='mt-4 text-center' style={{fontWeight:'bold',color:'rgb(212,93,46)'}}>
                                                        {item.name}
                                                    </div>
                                                    <div className=' my-4 text-center' >
                                                        {item.description}
                                                    </div>
                                                    
                                                </div>
                                                <div className='d-flex w-100 justify-content-end' >
                                                        <Link to={`/teacher/course/info/${item.id}`} className='no-decor'><button className={`btn text-light ${classes.button_purple}`}>View More</button></Link>
                                                </div>
                                            
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <div className='w-100 text-center text-light'>
                                            <i>{t('Courses_2.10')}</i>
                                        </div>}
                                        
                                    </div>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                                <div className={classes.slideRight} onClick={slideRightSpanish}>
                                                </div>
                                    </div>
                                </div>  
                            </>
                        }
                     </div> 
                    </div>
                </div>

            </div>
        
        </>

    )
}

export default Courses