import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './translationEN.json';
import translationES from './translationES.json';
import translationFR from './translationFR.json';
import translationCH from './translationCH.json';

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  fr:{
    translation:translationFR
  },
  zh_CN:{
    translation:translationCH
  }
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng:'en',
    debug:true,
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;