import React, { useEffect, useState } from 'react';
import {useAuth} from '../../../providers/ProvideAuth'
import {Link, useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import { useStudent } from '../../../providers/ProvideStudent';
import { useTranslation } from 'react-i18next';
import { MdSort } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const Teachers = () => {

  const auth = useAuth();
  let [loading,setLoading] = useState(false)
  let {t,i18n} = useTranslation();
    let [profiles,setProfiles] = useState([]);
    let navigate = useNavigate()
  useEffect(()=>{
    (
        async function(){
            setLoading(true)
            try{
                let reply = await fetch(`https://scholarist.com:8000/api/aboutus?lang=${i18n.language}`);
                let response = await reply.json();
                setLoading(false)
                setProfiles(response.data);
            }catch(err){
                setLoading(false)
                console.log(err);
            }
        }
    )();
  },[i18n.language]);

  async function handleLogout(){
    let res=await auth.logout();
    toast.success('Logged out successfully');
    return navigate('/',{replace:true})
}
  const styles={
    image:{
        backgroundImage:`url(${auth.user.avatar})`
    }
  }
  return (
    <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    </div>
                    <h2 className={`mb-1 poppins`}><b style={{fontWeight:'bolder'}}>{t('AboutUs.5')}</b></h2>
                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                      <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                </nav>

                <div className='container-fluid px-2 px-lg-4 py-4'>
                    <div className='row d-flex py-5 justify-content-evenly'>
                                        {loading && 
                                        <div className='col-12 d-flex justify-content-center my-5 py-5 text-white'>
                                            <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                                <CircularProgress />
                                            </Box>
                                        </div>}
                                        {profiles.length>0&&profiles.map(item=>
                                            <div className='col-10 col-md-3 py-5 my-5 mx-md-3 bg-light' style={{borderRadius:20}}>
                                                <Link to={`/student/teacherprofile/${item.id}`} className='no-decor '>
                                                    <div className='row d-flex flex-column align-items-center'>
                                                        <div className='col-10 d-flex justify-content-center'>
                                                            <div style={{backgroundImage:`url(${item.avatar})`,width:'40%',height:'0',paddingBottom:'40%',borderRadius:'50%',backgroundPosition:'center',backgroundSize:'cover'}}>
                                                            </div>
                                                        </div>
                                                        <div className='col-10 d-flex flex-column justify-content-center text-dark text-center pt-2 my-3' style={{overflowWrap:'break-word'}}>
                                                            <h2>{item.name}</h2>
                                                            <div>{item.skills}</div>
                                                        </div>
                                                        <div className='px-4 my-4'>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        
                                        )}
                                        {profiles.length<=0 && loading==false && 
                                        <div className='col-12 my-4 d-flex justify-content-center'><h4><i>No teachers found yet</i></h4></div>}
                    </div>
                </div>
        </div>
 
  )
}

export default Teachers