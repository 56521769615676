import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

const Slide1 = (props) => {
    let {t,i18n} = useTranslation();
    let {courseType,price,setPrice,setSelectedFile} = props
  return (
    <div className='w-100 d-flex flex-column align-items-center'>
                    <Form.Group className='w_90 my-3'>
                        <Form.Label>{courseType=='per-lesson'?t('AddCourse.18'):t('AddCourse.17')}<sup style={{color:'red'}}>*</sup> <span style={{color:'red'}}>({t('AddCourse.20')})</span></Form.Label>
                        <Form.Control
                            placeholder="13"
                            aria-label="Price"
                            aria-describedby="basic-addon1"
                            onChange={e=>setPrice(e.target.value)}
                            value={price}
                        />
                    </Form.Group>
                    <Form.Group className='w_90 my-3'>
                        <Form.Label>{t('AddCourse.19')}</Form.Label>
                        <Form.Control
                            type='file'
                            onChange={e=>setSelectedFile(e.target.files[0])}
                        />
                    </Form.Group>
                    
                    
    </div>
  )
}

export default Slide1