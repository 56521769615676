import React from 'react'
import { useTranslation } from 'react-i18next'

const Terms = () => {

    let {t,i18n} = useTranslation();
    return(
        <div className='container-fluid terms py-5' >
        <div className='container'>
            <div className='row py-5 rounded d-flex justify-content-center'>
                <div className='col-10 bg-white d-flex flex-column'>
                    <h1 className='my-2'>{t('Terms.1')}</h1>
                    <p><i>{t('Terms.2')}</i></p>
                    <p>{t('Terms.3')}</p>

                    <h3 className='my-3'><b>{t('Terms.4')}</b></h3>
                    <p>{t('Terms.5')}</p>

                    <h3 className='my-3'><b>{t('Terms.6')}</b></h3>
                    <p>{t('Terms.7')}</p>

                    <h3 className='my-3'><b>{t('Terms.8')}</b></h3>
                    <p>{t('Terms.9')}</p>

                    <h3 className='my-3'><b>{t('Terms.10')}</b></h3>
                    <p>{t('Terms.11')}</p>

                    <h3 className='my-3'><b>{t('Terms.12')}</b></h3>
                    <p>{t('Terms.13')}</p>

                    <h3 className='my-3'><b>{t('Terms.14')}</b></h3>
                    <p>{t('Terms.15')}</p>

                    <h3 className='my-3'><b>{t('Terms.16')}</b></h3>
                    <p>{t('Terms.17')}</p>

                    <h3 className='my-3'><b>{t('Terms.18')}</b></h3>
                    <p>{t('Terms.19')}</p>

                    <h3 className='my-3'><b>{t('Terms.20')}</b></h3>
                    <p>{t('Terms.21')}</p>

                    <h3 className='my-3'><b>{t('Terms.22')}</b></h3>
                    <p>{t('Terms.23')}</p>

                    <h3 className='my-3'><b>{t('Terms.24')}</b></h3>
                    <p>{t('Terms.25')}</p>

                    <h3 className='my-3'><b>{t('Terms.26')}</b></h3>
                    <p>{t('Terms.27')}</p>

                    <h3 className='my-3'><b>{t('Terms.28')}</b></h3>
                    <p>{t('Terms.29')} <a href='mailto:scholaristicg@gmail.com'>scholaristicg@gmail.com</a></p>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Terms