
import {Navigate} from 'react-router-dom';
import {useAuth} from '../../providers/ProvideAuth'

function DashboardSettings(){
    var auth = useAuth();
    
    return(
        <>
           {auth.user ?
           <>
           {auth.user.role==1 && <Navigate to='/admin/settings' replace={true}/>}
           {auth.user.role==2 && <Navigate to='/teacher/settings' replace={true}/>}
           {auth.user.role==3 && <Navigate to='/student/settings' replace={true}/>}
           </>
            :
           <Navigate to='/'/>}
        </>
       
    )
}
export default DashboardSettings;