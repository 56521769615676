import React, { useEffect, useState } from 'react'
import { useParams,Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import { useTeacher } from '../../../providers/ProvideTeacher';
import classes from '../../../styles/Lecture.module.css';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Select from "react-select";

import Carousel from 'react-bootstrap/Carousel';
import Table from 'react-bootstrap/Table';
import Details from './Details';
import { useSocket } from '../../../providers/ProvideSocket';
import { useTranslation } from 'react-i18next';
import Clock from 'react-live-clock';
import {MdSort} from 'react-icons/md';
import { Rating } from 'react-simple-star-rating'
const Lecture = () => {
    const auth = useAuth();
    const {course}=useParams();
    const teacher = useTeacher();
    let navigate = useNavigate();
    let [lecture,setLecture] = useState({});
    let [name,setName] = useState("");
    let [description,setDesc] = useState("");
    let [students,setStudents] = useState([]);
    let [showForm,setShowForm] = useState(false);
    let [posting_day,setPosting_day] = useState(dayjs());
    let [expiry,setExpiry] = useState(dayjs());
    let [selectedType,setType] = useState({ value: 'pdf', label: 'PDF' });
    let [file_path,setSelectedFile] = useState("");
    let [assignments,setAssignments] = useState([]);
    let [assignment,setAssignment] = useState({value:'',label:' '});
    const [showDetails, setShowDetails] = useState(false);
    let [submissions,setSubmissions] = useState([[]]);
    let [resources,setResources] = useState([[],[]]);
    let [resource,setResource] = useState();
    let [reply, setReply] = useState();
    let [reviews,setReviews] = useState([]);
    let {newResource} = useSocket();
    let {t,i18n} = useTranslation();
    const options = [
        { value: 'pdf', label: 'PDF' },
        { value: 'video', label: 'Video' },
        { value: 'image', label: 'Image' },
      ];
    
    useEffect(()=>{
        (
            async function(){
                if(assignment){
                    let result = await teacher.getSubmissions(assignment.value,course);
                    if(result.success){
                        setSubmissions(result.data)
                    }else{
                        return toast.error('Error in loading submissions')
                    }
                }
            }
        )();
    },[assignment]);
    
    useEffect(()=>{
        (async ()=>{
            let lecture_tb_displayed = {};
            for(let ele of teacher.courses){
                if(ele.id==course){
                    lecture_tb_displayed = ele;
                    break;
                }
            }
            await setLecture(lecture_tb_displayed);
            await fetchStudents();
            await fetchResources();
            await handleAssignments()
            await setAssignment({value:'',label:'Select'});
            await setSubmissions([]);
            await fetchReviews(course)                 
        })();
        
    },[course]);
    
    
    
    async function handleShow(id){

        let result = await teacher.getStudentResource(id);
        if(result.success){
            await setResource(result.data);
            if(result.reply){
                await setReply(result.reply);
            }
            setShowDetails(true)
        }
        else{
            return toast.error('Error in viewing resource!');
        }
    }

    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }

    async function handleAssignments(){
        let result = await teacher.getAssignments(course);
        if(result.success){
            setAssignments(result.data);
        }else{
            return toast.error('Error in loading submissions')
        }
    }
    async function fetchResources(){
        let result = await teacher.fetchResources(course);
        
        if(result.success){
            await setResources(result.data)
        }else{
            return toast.error('Error in fetching resources')
        }
    }
    
    async function fetchReviews(course){
        let reply = await teacher.fetchReviews(course)
        if(reply.success){
            setReviews(reply.data)
        }else{
            return toast.error('Error in fetching reviews')
        }
    }

    async function handleSubmit(e){
        e.preventDefault();
        if(!name||!description||!expiry||!posting_day){
            return toast.error('Please fill all fields')
        }
        if(!file_path){
            return toast.error('Please upload file first before hitting Submit')
        }
        posting_day = posting_day.subtract(4,'hour');
        expiry = expiry.subtract(4,'hour');
        let form = new FormData()
        form.append('name',name);
        form.append('description',description);
        form.append('expiry',expiry);
        form.append('posting_day',posting_day);
        form.append('file',file_path);
        form.append('course',course)
        let result = await teacher.addResource(form);
        if(result.success){
            
            setName('');
            setDesc('');
            setSelectedFile('');
            setShowForm(false);
            setResources();
            await setResources(result.data)
            newResource(lecture.subject.substring(0,3).toUpperCase()+(100+parseInt(lecture.id)))
           
        }
        else{

            return toast.error(`${result.message}`);
        }

    }

   

    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    }
    async function fetchStudents(){
        let resp = await teacher.fetchStudents(course);
        if(resp.success){
            setStudents(resp.data);
        }
        else{
            return toast.error('Error in fetching enrolled students');
        }
    }
    const styles={
        profileIcon:{
            backgroundImage:`url(${auth.user.avatar})`
        },
        heading:{
            fontFamily:'Baloo'
        },
        neon:{
            color:'rgb(144,251,220)!important',
            borderColor:'rgb(144,251,220)!important'
        }
    }
    return(
        <>
            <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    </div>
                    
                    <h2 className="m-0">Learning Room</h2>

                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                        <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </nav>

                <Details show={showDetails}
                setShowDetails={setShowDetails} setResource={setResource} setReply={setReply} resource={resource} reply={reply}/>

                <div className='container-fluid text-white'>
                    <div className='row d-flex my-5 justify-content-center'>
                        <div className={`col-10 d-flex flex-column ${classes.scroll}`}>
                            <div className={`py-2 text-light px-0 px-md-5 m-auto`}>
                                <ul className='nav d-flex flex-nowrap justify-content-between nav-pills' id="pills-tab" role="tablist">
                                    <li className="nav-item " role="presentation" style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" id="pills-course-tab" data-bs-toggle="pill" data-bs-target="#pills-course" role="tab" aria-controls="pills-course" aria-selected="true">About</div>
                                    </li>
                                    <li className="nav-item " role="presentation" onClick={fetchStudents} style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" id="pills-students-tab" data-bs-toggle="pill" data-bs-target="#pills-students" role="tab" aria-controls="pills-students" aria-selected="false">Students</div>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={handleAssignments} style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" id="pills-Submissions-tab" data-bs-toggle="pill" data-bs-target="#pills-Submissions" role="tab" aria-controls="pills-Submissions" aria-selected="false">Submissions</div>
                                    </li>
                                    <li className="nav-item" role="presentation" style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" id="pills-faq-tab" data-bs-toggle="pill" data-bs-target="#pills-faq" role="tab" aria-controls="pills-faq" aria-selected="false">FAQs</div>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={fetchResources} style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" id="pills-resources-tab" data-bs-toggle="pill" data-bs-target="#pills-resources" role="tab" aria-controls="pills-resources" aria-selected="false">Resources</div>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={fetchResources} style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" id="pills-reviews-tab" data-bs-toggle="pill" data-bs-target="#pills-reviews" role="tab" aria-controls="pills-reviews" aria-selected="false">Reviews</div>
                                    </li>
                                </ul>    
                            </div> 
                        </div>
                        
                        <div className="col-10 d-flex flex-column justify-content-center align-items-center my-3">
                            <div className="tab-content w-100" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-course" role="tabpanel" aria-labelledby="pills-course-tab">
                                <div className='row d-flex py-5 justify-content-center' data-masonry={{percentPosition: true }}>
                                        <div className='col-10 col-lg-5 col-xxl-3 order-2 offset-lg-1 my-3'>
                                            <div className='card py-4 px-2 animate__animated animate__fadeInLeft' style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                                                <div className='card-primary d-flex justify-content-center align-items-center flex-column'>
                                                    <div className={`m-auto ${classes.pp}`} style={{backgroundImage:`url(${lecture.Teacher?lecture.Teacher.avatar:''})`}}>

                                                    </div>
                                                    <h3 className='poppins my-3 mt-5'><b>{lecture.Teacher?lecture.Teacher.name:'Scholarist Course'}</b></h3>
                                                    <h5 className='poppins my-3'>{lecture.Teacher?lecture.Teacher.skills:'English/French'}</h5>
                                                    <p className='poppins my-3'>{lecture.Teacher?lecture.Teacher.description:''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-10 col-lg-6 col-xxl-3 my-3 '>
                                            <div className='card py-4 px-3 animate__animated animate__fadeInLeft'  style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                                                <div className='card-primary d-flex justify-content-center align-items-center flex-column'>
                                                    <div className={`${classes.course_image} mb-3`} style={{backgroundImage:`url(${lecture.avatar})`}}>
                                                    
                                                    </div>
                                                    <h2 style={styles.heading} className='text-center my-3'>
                                                        {lecture.name}
                                                    </h2>
                                                    
                                                    <p className='mt-5'>
                                                        {lecture.description}
                                                    </p>
                                                    <div className='my-4'>
                                                    <Link to={`/teacher/videocall/${course}`} target='_blank' className='no-decor' replace={true}><button type='button' className='btn px-3 btn-success'><b>{t('Classes.6')}</b></button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-students" role="tabpanel" aria-labelledby="pills-students-tab">
                                    <div className='row d-flex py-5 justify-content-center justify-content-md-between'>
                                        {students.length>0&&students.map(item=>
                                        <div className='col-10 col-md-5 col-xxl-3 py-5 my-5 mx-md-3 text-light' style={{borderRadius:20,backgroundColor:'rgb(41,45,54)'}}>
                                             <div className='row d-flex flex-column align-items-center'>
                                                    <div className='col-10 d-flex justify-content-center'>
                                                        <div style={{backgroundImage:`url(${item.avatar})`,width:'40%',height:'0',paddingBottom:'40%',borderRadius:'50%',backgroundPosition:'center',backgroundSize:'cover'}}>
                                                        </div>
                                                    </div>
                                                    <div className='col-10 d-flex flex-column justify-content-center text-center pt-2 my-3' style={{overflowWrap:'break-word'}}>
                                                        <h2>{item.name}</h2>
                                                    </div>
                                                    <div className='px-4 my-4'>
                                                        <p>{item.description}</p>
                                                    </div>
                                             </div>
                                        </div>)}
                                        {students.length<=0 && 
                                        <div className='col-12 my-4 d-flex justify-content-center'><h4><i>No students enrolled in the course yet</i></h4></div>}
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-Submissions" role="tabpanel" aria-labelledby="pills-Submissions-tab">
                                    <div className='row my-4 d-flex justify-content-center'>
                                        <div className='col-11 col-md-5'>
                                            <Form.Group>
                                                <Form.Label>Type of Submission:</Form.Label>
                                                    <Select 
                                                        className='text-dark bg-customgrey'
                                                        onChange={(e)=>setAssignment(e)}
                                                        options={assignments}
                                                        value={assignment}
                                                    />
                                            </Form.Group> 
                                        </div>

                                    </div>
                                    <div className='row d-flex flex-column align-items-center'>

                                        <div className='col-12 col-lg-10 col-xxl-6 d-flex flex-column align-items-center my-5'>
                                            {(!assignment.value||submissions.length==0)&&<div className='my-5 d-block m-auto text-center'><i>No submissions yet</i></div>}
                                            {submissions.map(item=>
                                                    <div className={`w-100 m-auto py-5 poppins `}>
                                                        {item.map((i,index)=>
                                                            <div className={`p-2 py-4 row d-flex flex-nowrap flex-row ${classes.tab}`} key={index}>
                                                                {console.log(i,'hai....\n\n')}
                                                                <div className='col-3 col-md-2 col-xxl-1'>
                                                                    <div className={classes.studentSubPP} style={{backgroundImage:`url(${i.User.avatar})`}}></div>
                                                                </div>
                                                                <div className='col-4 d-flex align-items-center'>
                                                                    {i.User.name}
                                                                </div>
                                                                <div className='col-4 d-flex align-items-center'>
                                                                    <div onClick={handleShow.bind(null,i.id)} style={{color:'rgb(251,142,37)',cursor:'pointer'}}><b>Click Here</b></div>
                                                                </div>
                                                                <div className='col-4 d-flex align-items-center'>
                                                                    <b>{i.posting_day.slice(0,10)}</b>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-resources" role="tabpanel" aria-labelledby="pills-resources-tab">
                                    <div className='row d-flex justify-content-center my-5'>
                                        <div className='col-12 my-3'>
                                            <div className='w-100 bg-yellow d-flex flex-column rounded align-items-start text-white p-2 px-5'>
                                                <h3 className='my-3'><i className="fa-solid fa-circle-exclamation me-3"></i>Important Notice</h3>
                                                <p>Remember all the expiry and posted dates you select will get converted to GMT timezone. So choose them according to GMT</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-12 my-4'>
                                            <div className='row '>
                                                <div className='col-12 my-5'>
                                                    {resources.length==0&&<div className='text-center'><i>No resources posted yet</i></div>}
                                                    {!(resources.length==0)&&
                                                        <Carousel className='w-100 py-5'>
                                                            {resources.map(item=>
                                                            
                                                                <div className={`w-75 m-auto py-3 `}>
                                                                    <div className={`p-2 mb-4 border-bottom row d-flex flex-nowrap flex-row ${classes.heading}`}>
                                                                            <div className='col-7 col-md-4 d-flex align-items-center'>
                                                                                Resource Name
                                                                            </div>
                                                                            <div className='col-4 col-xxl-2 d-flex align-items-center'>
                                                                                File
                                                                            </div>
                                                                            <div className='col-5 col-md-4 col-xxl-2 d-flex align-items-center'>
                                                                                Posting Day
                                                                            </div>
                                                                            <div className='offset-1 offset-md-0 col-5 col-md-4 col-xxl-2 d-flex align-items-center'>
                                                                                Expiry
                                                                            </div>
                                                                        </div>
                                                                    {item.map((i,index)=>
                                                                        <div className={`p-2 py-4 row d-flex flex-nowrap flex-row ${classes.tab}`} key={index}>
                                                                            {console.log(i,'hai....\n\n')}
                                                                            
                                                                            <div className='col-7 col-md-4 d-flex align-items-center'>
                                                                                {i.name}
                                                                            </div>
                                                                            <div className='col-4 col-xxl-2 d-flex align-items-center'>
                                                                                <a className='no-decor' href={i.file_path} style={{color:'rgb(251,142,37)'}}><b>Click Here</b></a>
                                                                            </div>
                                                                            <div className='col-5 col-md-4 col-xxl-2 d-flex align-items-center'>
                                                                                <b>{i.posting_day.slice(0,10)}</b>
                                                                            </div>
                                                                            <div className='offset-1 offset-md-0 col-5 col-md-4 col-xxl-2 d-flex align-items-center'>
                                                                                <b>{i.expiry.slice(0,10)}</b>
                                                                            </div>
                                                                        </div>
                                                                    )}                       
                                                                
                                                                </div>
                                                            
                                                            
                                                            )}
                                                        </Carousel>
                                                    }
                                                
                                                </div>
                                                
                                                    {showForm ? 
                                                    <div className='col-12 d-flex flex-column align-items-center'>
                                                        <div className='rounded bg-white d-flex flex-column justify-content-center text-dark my-5' >
                                                            <h3 className='w-100 p-3'>Post New File</h3>
                                                                <div className='bg-customgrey border-top w-100 p-3'>
                                                                    <Form className='my-4 row d-flex justify-content-between'>
                                                                        <div className='col-12 col-md-6 my-2'>
                                                                        <Form.Group>
                                                                            <Form.Label>Name</Form.Label>
                                                                            <Form.Control
                                                                            placeholder="Assignment-1"
                                                                            aria-label="Assignment-1"
                                                                            aria-describedby="basic-addon1"
                                                                            onChange={e=>setName(e.target.value)}
                                                                            value={name}
                                                                            required
                                                                            />
                                                                        </Form.Group>
                                                                        </div>
                                                                        
                                                                        <div className='col-12 col-md-6 my-2 d-flex flex-column justify-content-around'>
                                                                        <div className='mb-3'>
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <DemoContainer components={['DateTimePicker']}>
                                                                                        <DateTimePicker
                                                                                        label="Posting Date(Today)"
                                                                                        value={posting_day}
                                                                                        onChange={(newValue) => setPosting_day(newValue)}
                                                                                        />
                                                                                    </DemoContainer>
                                                                                </LocalizationProvider>
                                                                                
                                                                            </div>
                                                                            <div>
                                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                    <DemoContainer components={['DateTimePicker']}>
                                                                                        <DateTimePicker
                                                                                        label="Last Day"
                                                                                        value={expiry}
                                                                                        onChange={(newValue) => setExpiry(newValue)}
                                                                                        />
                                                                                    </DemoContainer>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                            
                                                                            
                                                                        </div>
                                                                    
                                                                        <div className='col-12 col-md-6 my-2'>
                                                                        <Form.Group >
                                                                            <Form.Label>Description</Form.Label>
                                                                            <Form.Control as="textarea" rows={8} 
                                                                            maxLength={350}
                                                                            value={description}
                                                                            onChange={handleDescChange}
                                                                            required
                                                                        />
                                                                        </Form.Group>
                                                                        </div>

                                                                        <div className='col-12 col-md-6 my-4 '>
                                                                            <Form.Label>Any additional files (supports .png,.jpeg,.pdf,.docx,.mp4)</Form.Label>
                                                                            <Form.Control
                                                                                type='file'
                                                                                onChange={e=>setSelectedFile(e.target.files[0])}
                                                                                accept='.png,.jpg,.jpeg,.pdf,.mp4,.docx'
                                                                            />
                                                                        </div>

                                                                        <div className='col-12 mt-5 d-flex justify-content-center'>
                                                                            <button type='button' onClick={handleSubmit} className='btn btn-success mx-4'>
                                                                                Submit
                                                                            </button>
                                                                            <button type='button' onClick={()=>setShowForm(false)} className='btn btn-danger mx-4'>
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                            </div> 
                                                    </div>
                                                    :
                                                    <div className='col-12 my-5 d-flex flex-column align-items-center'>
                                                        <button type='button' className='d-inline-block btn btn-success' onClick={()=>setShowForm(true)}>New Resource</button>
                                                    </div>}
                                                    
                                                
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-faq" role="tabpanel" aria-labelledby="pills-faq-tab">
                                    
                                </div>
                                <div class="tab-pane fade" id="pills-reviews" role="tabpanel" aria-labelledby="pills-reviews-tab">
                                    <div className='row my-4 text-light d-flex justify-content-center'>
                                        {reviews.length==0 && <div className='text-center my-5'><i>No reviews to show at the moment</i></div>}
                                        {reviews.map((i,index)=>
                                            <div className='col-11 p-2 p-lg-4 col-lg-8 col-xl-7 col-xxl-6 my-4' style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}} key={index}>
                                                <div className='row'>
                                                    <div className='col-12 col-lg-6 p-3 p-lg-0 d-flex flex-column align-items-center'>
                                                        <div className={classes.ReviewPP} style={{backgroundImage:`url(${i.User?i.User.avatar:''})`}}>

                                                        </div>
                                                        <div className='mt-4'><b>{i.User?i.User.name:''}</b></div>
                                                        <div className='my-2'>Posted: {i.posting_day}</div>
                                                        <div><Rating readonly={true} initialValue={i.rating}/></div>
                                                    </div>
                                                    <div className='col-12 col-lg-6 p-3 p-lg-0 d-flex flex-column justify-content-center align-items-center'>
                                                    {i.description}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>

            </div>
        
        </>

    )
}

export default Lecture
