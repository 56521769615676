import React from 'react';
import classes from '../../styles/HowToUse.module.css';
import { useTranslation } from 'react-i18next';
export default function Slide3(props) {

    let {t,i18n} = useTranslation();
  return (
    <div className={`col-10 col-lg-8 col-xxl-5 text-light p-3 d-flex flex-column poppins justify-content-center align-items-center px-lg-5 ${classes.container}`}>
           <h3 className='py-0 py-lg-5 my-5 text-warning'>{t('HowToUse.6')}</h3>

           <div className='w-100 my-5 d-flex flex-column justify-content-center mb-5'>            
                    <div className={`w-100 d-flex flex-nowrap`}>
                        <div className={`${classes.sidebar}`}>

                        </div>
                        <div className={`d-flex justify-content-center justify-content-lg-between align-items-center px-1 px-lg-3 text-warning row ${classes.remaining}`}>
                            <div className={`rounded bg-white py-4 my-2 col-8 col-md-5 col-lg-3 my-3`} >
                                <div className='row d-flex flex-column align-items-center'>
                                    <div className='col-10 d-flex justify-content-center'>
                                        <div style={{backgroundImage:`url(https://scholaristdemo.s3.us-east-2.amazonaws.com/Neelam.jpg)`,width:'40%',height:'0',paddingBottom:'40%',borderRadius:'50%',backgroundPosition:'center',backgroundSize:'cover'}}>
                                        </div>
                                    </div>
                                    <div className='col-11 d-flex flex-column justify-content-center text-dark text-center pt-2 my-3' style={{overflowWrap:'break-word'}}>
                                        <h4>Neelam</h4>
                                        <div>English - IELTS,CELPIP</div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className={`rounded bg-white py-4 my-2 d-none d-lg-inline-block col-lg-3 my-3`} >
                                <div className='row d-flex flex-column align-items-center'>
                                    <div className='col-10 d-flex justify-content-center'>
                                        <div style={{backgroundImage:`url(https://scholaristdemo.s3.us-east-2.amazonaws.com/ChrisBlount.jpeg)`,width:'40%',height:'0',paddingBottom:'40%',borderRadius:'50%',backgroundPosition:'center',backgroundSize:'cover'}}>
                                        </div>
                                    </div>
                                    <div className='col-11 d-flex flex-column justify-content-center text-dark text-center pt-2 my-3' style={{overflowWrap:'break-word'}}>
                                        <h4>Chris</h4>
                                        <div>English - IELTS, CELPIP</div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className={`rounded bg-white py-4 my-2 d-none d-lg-inline-block col-lg-3 my-3`} >
                                <div className='row d-flex flex-column align-items-center'>
                                    <div className='col-10 d-flex justify-content-center'>
                                        <div style={{backgroundImage:`url(https://scholaristdemo.s3.us-east-2.amazonaws.com/Waqar.jpg)`,width:'40%',height:'0',paddingBottom:'40%',borderRadius:'50%',backgroundPosition:'center',backgroundSize:'cover'}}>
                                        </div>
                                    </div>
                                    <div className='col-11 d-flex flex-column justify-content-center text-dark text-center pt-2 my-3' style={{overflowWrap:'break-word'}}>
                                        <h4>Waqar</h4>
                                        <div>English - IELTS, CELPIP</div>
                                    </div>
                                
                                </div>
                            </div>
                            <div className='text-warning col-10 my-3'>
                                {t('HowToUse.7')}
                            </div>
                        </div>
                    </div>
                
                
                
           </div>
           <div className='w-100 px-5 py-5 d-flex justify-content-end'>
               <button className='rounded btn btn-danger text-light' onClick={()=>props.next(3)}>{t('HowToUse.5')}</button>
           </div> 
    </div>
  )
}
