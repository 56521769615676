import React, {useEffect,useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import { setItemInLocalStorage,TokenKey } from '../utils';
import { useAuth } from '../providers/ProvideAuth';
const Email_Verify = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    let [message,setMessage] = useState("Your identity is being verified, please wait....")
    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/verify/${token}`)
                    let response = await reply.json();
                    if(response.success){
                        auth.email_verified_set_user(response.data);
                    }else{
                        setMessage("Invalid Token");
                    }
                }catch(err){
                    console.log(err);
                    return toast.error('Error in verifying user')
                }
            }
        )();
    },[])

    useEffect(()=>{
        if(auth.user){
            return navigate('/user');
        }
    },[auth.user])
    return(
        <div className='container d-flex justify-content-center align-items-center'>
            <div className='row my-5'>
                <div className='col-11 d-flex justify-content-center min-height-100 py-5'>
                    <h3><b>{message}</b></h3>
                </div>
            </div>
        </div>
    )
}

export default Email_Verify