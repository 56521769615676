import React,{useState,useEffect} from 'react'
import Modal from 'react-bootstrap/Modal';
import classes from '../../styles/Contact.module.css';
import { Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { useTranslation } from 'react-i18next';
function AIChatbot(props) {
    let {t,i18n} = useTranslation();
    let [messages,setMessages] = useState([
        {sender:'system',content:t('AIChatbot.1'),avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/favicon.ico'}
    ])
    let {show,onHide} = props;
    let [count,setCount] = useState(0);
    let [input,setInput] = useState('');
    
    async function fetchMessage(input){
        let reply = await fetch(`https://scholarist.com:8000/api/fetch/aianswer/${input}?lang=${i18n.language}`);
        let res = await reply.json();
        return res.data
    }
    async function sendMessage(e){
        e.preventDefault();
        if (count==0){
            await setMessages([...messages,{sender:'user',content:`${input}`,avatar:'https://api-private.atlassian.com/users/4f5f736dffd9036ec97f3e366931bc7c/avatar'},{sender:'system',content:`${t('AIChatbot.2')} ${input}. ${t('AIChatbot.3')}`,avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/favicon.ico'},
            {sender:'system',content:t('AIChatbot.4'),avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/favicon.ico'}]);
            setCount(count+1);
            return;
        }
        var userMessage = {sender:'user',content:input,avatar:'https://api-private.atlassian.com/users/4f5f736dffd9036ec97f3e366931bc7c/avatar'}
        if(input==1||input==2||input==3||input==4){
            let ans = await fetchMessage(input);
            await setMessages([...messages,userMessage,{sender:'system',content:ans,avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/favicon.ico'}]);
            return;
        }

        await setMessages([...messages,userMessage,{sender:'system',content:t('AIChatbot.5'),avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/favicon.ico'}]);
        return;
        
        
    }
    function handleClose(){
        setCount(0);
        setInput('');
        setMessages([{sender:'system',content:t('AIChatbot.1'),avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/favicon.ico'}])
        onHide();
    }
    return(
        <Modal
            show={show}
            backdrop="static"
            onHide={handleClose}
            animation={true}
            centered
            contentClassName='aichatbot'
            scrollable       
        >
        <Modal.Header className={`${classes.modalGradient} text-light`} closeButton closeVariant='white'>
          <Modal.Title>Scholarist Chatbot</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`p-3 ${classes.chatbody}`}>
        
            {messages&&messages.map((m,i)=>
                <div className={`d-flex ${classes.w_98} my-2`} key={i}
                    style={{
                        justifyContent:`${
                            m.sender!='system'?'end':'start'
                            }`}}>
                        
                    
                  
                    
                   
                    {m.sender=='system' &&
                        <div className={`${classes.avatar} me-2 d-inline-block`} style={{backgroundImage:`url(${m.avatar})`}}> </div>
                    }                    
                    {m.sender=='user' ?
                    <span className={`${classes.messageUser} rounded p-2`}>
                        {m.content}
                    </span>
                    :
                    <span className={`${classes.messageSender} rounded p-2`} dangerouslySetInnerHTML={{__html:m.content}}>
                        
                    </span>}
                    {m.sender=='user' &&
                        <div className={`${classes.avatar} ms-2 d-inline-block`} style={{backgroundImage:`url(${m.avatar})`}}> </div>
                    }                    
                     
                    
                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            <Form className='w-100' onSubmit={sendMessage}>
                <InputGroup className="w-100">
                    <Form.Control
                        placeholder="Type here.."
                        aria-label="Input"
                        aria-describedby="basic-addon1"
                        onChange={e=>setInput(e.target.value)}
                    />
                    <InputGroup.Text ><i className="fa-solid fa-paper-plane" onClick={sendMessage} style={{cursor:'pointer'}}></i></InputGroup.Text>
                    
                </InputGroup>
            </Form>
            
        </Modal.Footer>
      </Modal>
    )
}

export default AIChatbot
