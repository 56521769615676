import React, {useEffect,useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import PopUp from './PopUp';
import toast from 'react-hot-toast';
import { TokenKey } from '../../../utils';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Clock from 'react-live-clock';
import classes from '../../../styles/PageContent.module.css';
import Calendar from 'react-calendar';
import '../Calendar.css';
import { useTeacher } from '../../../providers/ProvideTeacher';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { FaCaretLeft, FaCaretRight, FaForward, FaBackward } from 'react-icons/fa';
import {HiBadgeCheck} from 'react-icons/hi';
import {MdSort} from 'react-icons/md';

import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";


dayjs.extend(utc)
dayjs.extend(timezone)
const PageContent = () => {

    const navigate = useNavigate();
    const auth = useAuth();
    const [show, setShow] = useState(false);
    let [progress,setProgress] = useState([]);
    let [balance,setBalance] = useState(100);
    let [upcoming, setUpcoming] = useState([]);
    let [content,setContent] = useState('');
    let [schedule,setSchedule] = useState([1,2]);
    const [searchResults,setSearchResults] = useState(null)
    let teacher = useTeacher();
    let {courses} = teacher;
    let {t,i18n} = useTranslation();
    let [selectedType,setType] = useState( { value: 'en', label: '🇺🇸  English' });
    const options = [
        { value: 'en', label: '🇺🇸  English' },
        { value: 'fr', label: '🇫🇷  French' },
        { value: 'es', label: '🇪🇸  Spanish' },
        { value: 'zh_CN', label:'🇨🇳  Chinese'}
      ];
      let date = dayjs();
    useEffect(()=>{
        (async()=>{
            await handleProgress();
            await handleUpcoming();
            await handleBalance();

        })();

        if(i18n.language=='fr'){
            setType(options[1])
        }else if(i18n.language=='es'){
            setType(options[2])
        }else if(i18n.language=='zh_CN'){
            setType(options[3])
        }else{
            setType(options[0])
        }
         
    },[]);
   
    const handleProgress = async ()=>{
        try{
            let reply = await fetch('https://scholarist.com:8000/api/teachers/inprogress',{
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            await setProgress(response.data);
        }catch(err){
            console.log(err);
            
        }
    }

    const handleUpcoming = async ()=>{
        try{
            let reply = await fetch('https://scholarist.com:8000/api/teachers/upcoming',{
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            await setUpcoming(response.data);
        }catch(err){
            console.log(err);
            
        }
    }

    const handleBalance = async ()=>{
        try{

        }catch(err){
            console.log(err);
            return toast.error('Error in fetching balance in account')
        }
    }

    const handleSearch = async (e)=>{
        
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
       
        
        setSearchResults(['Add more data to website from teachers to start searching'])
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }

    const styles={
        image:{
            backgroundImage:`url(${auth.user.avatar})`
        },
        clock:{
            fontFamily:"DS-Digital, sans-serif;"
        },
        monospace:{
            fontFamily:'monospace',
            color:'rgb(228,138,105)'
        },
        label:{
            fontWeight:'bold',
        }
    }
  return (
    <div id="page-content-wrapper">
            {console.log(courses,'try karo....')}
            <PopUp show={show}
            onHide={() =>{setContent('');setShow(false)}} progress={progress} upcoming={upcoming} balance={balance} content={content}/>
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-0 px-md-4 text-light d-flex flex-nowrap justify-content-between">
                <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light  me-md-5'/></a>
                    <h2 className="mb-1 poppins"><b>{t('PageContent.1')}</b></h2>
                </div>
                <div className='w-25 d-none d-lg-inline-block' style={{position:'relative'}}>
                    <Form.Control
                        type="text"
                        placeholder={`🔍 ${t('PageContent.6')}...`}
                        className='rounded px-4'
                        onChange={handleSearch}
                    />
                    <div className={`w-100 rounded ${searchResults? classes.searchDropdown:classes.searchDropdownInvisible} mb-3`}>
                        <div className='row'>
                            <div className='col-11 d-flex justify-content-end p-1 py-2' style={styles.label} >
                                <CloseButton className='text-light' onClick={()=>setSearchResults(null)} />
                            </div>
                        </div>
                                        
                        {searchResults?<>{searchResults.length>0?searchResults.map((item)=>
                            <>
                                        
                                <div className={`row ${classes.searchBarResults} d-flex flex-column justify-content-center align-items-center`}>
                                    <div className="col-8 ">
                                        <div style={styles.label}><i>{item}</i></div>
                                    </div>
                                </div>
                            </>
                                            
                            ):
                                <>
                                    <div className='row text-dark'>
                                        <div className=' my-2 col-12 d-flex justify-content-center'>
                                            <i>Nothing to report</i>  
                                        </div>
                                                
                                    </div>
                                </>}</>
                            :
                            <></>
                         }
                    </div>
                </div>
                
                <div className='d-flex justify-content-between align-items-center'>
                    <Select 
                    value={selectedType}
                    onChange={(e)=>{setType(e);i18n.changeLanguage(e.value)}}
                    options={options}
                    required
                    styles={{
                        control:(bs)=>({
                            ...bs,
                            backgroundColor:'transparent',
                            border:'none'
                        }),
                        singleValue:(bs)=>({
                            ...bs,
                            color:'white !important',
                            fontWeight:'bold'
                        }),
                        menu:(bs)=>({
                            ...bs,
                            color:'black !important',
                                        
                        })
                        }}
                    />
                    <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
                       <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </div>
            </nav>

            <div className="container-fluid px-4 mt-5 mb-2">
                <div className='row d-flex justify-content-evenly my-5 text-light' data-masonry={{percentPosition: true }}>
                    
                    {/* This is for the Hi! section */}
                    <div className={`col-12 my-5 my-lg-0 col-lg-8 col-xxl-7  d-flex px-1 px-md-5 order-1`} >
                        <div className='card bg-transparent'>
                            <div className='card-primary'>
                                <div className={` ${classes.bg_light} py-5 d-flex `} style={{position:'relative'}}>
                                    <div className='ps-3 ps-lg-4 d-flex flex-column align-items-center me-xxl-5' style={{width:'60%'}}>
                                        <h3 className='poppins my-4'>👋 <b>{t('PageContent.13')}</b></h3>
                                        <p className='poppins'>Thank you for choosing Scholarist as your teaching platform ! We are glad to have you with us on this journey to empower students in their linguistic aspirations. Let us know in case you feel any encumberances. Happy teaching !</p>
                                    </div>
                                    <div className={`${classes.man_img}`}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* This is for the Profile! section */}
                    <div className={`col-12 my-3 my-lg-0 col-lg-4 col-xxl-4 d-flex order-3 order-lg-2`}>
                        <div className={` ${classes.bg_light} d-flex flex-column w-100`}>
                            <h3 className={`p-3 bg-danger text-center poppins`} style={{borderTopLeftRadius:20,borderTopRightRadius:20}}>
                               <b>My Profile</b>
                            </h3>
                            <div className='w-100 p-3 d-flex justify-content-start align-items-center'>
                                <div className={`${classes.profile_icon} ms-3`} style={styles.image}>

                                </div>
                                <h5 className='mx-3'>
                                    <b>{auth.user.name}</b><HiBadgeCheck className='text-primary ms-1 mb-1'/>
                                </h5>
                            </div>
                            <div className='w-100 p-3 my-3 d-flex justify-content-evenly'>
                                <div className='d-flex flex-column align-items-center'>
                                    <h5 className={`my-2 poppins text-center`}><b>{t('PageContent.19')}</b></h5>
                                    <div className={` my-2`}><Clock className={`${classes.clock}`} format={'HH:mm'} ticking={true}/></div>
                                    <h6 className={`my-2 poppins text-center`}>{date.format('MMMM D')}</h6>
                                </div>
                                <div className='d-flex flex-column align-items-center'>
                                    <h5 className={`my-2 poppins text-center`}><b>{t('PageContent.20')}</b></h5>
                                    <div className='my-2'><Clock className={`${classes.clock_orange}`} format={'HH:mm'} ticking={true} timezone={'America/Toronto'}/></div>
                                    <h6 className={`my-2 poppins text-center`}>{dayjs(date).tz("America/Toronto").format('MMMM D')}</h6>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 my-5 my-xl-0 col-lg-8 col-xxl-7 d-flex flex-column order-2 order-lg-3 py-3 py-xl-0'  >   
                            <div className='row d-flex flex-column flex-md-row flex-nowrap w-100'>
                                    <div className={`col-12 col-md-4 my-4 my-lg-0`}>
                                            <Link to='/teacher/courses' className={`w-100 d-flex flex-column no-decor me-2 p-4 ${classes.progress}`}>
                                                <div className='w-100'>
                                                    <div className={`rounded ${classes.bg_progress}`}></div>
                                                </div>
                                                <div className='w-100 mt-3'>
                                                    <h3 className='mb-2'><b>{t('PageContent.2')}</b></h3>
                                                    <p><>{t('PageContent.10')}</></p>
                                                </div>
                                                <div className='d-flex justify-content-end pe-3'>
                                                    <div className={`rounded ${classes.next} shadow d-flex justify-content-center align-items-center`}>
                                                        <i className='fa-solid fa-arrow-right text-light'></i>
                                                    </div>
                                                </div>
                                            </Link>
                                    </div>

                                    <div className={`col-12 col-md-4 my-4 my-lg-0`}>
                                        <div className={`w-100 d-flex flex-column no-decor me-2 p-4 ${classes.upcoming}`} onClick={()=>{setContent('upcoming');setShow(true)}}>
                                                <div className='w-100'>
                                                    <div className={`rounded ${classes.bg_upcoming}`}></div>
                                                </div>
                                                <div className='w-100 mt-3'>
                                                    <h3 className='mb-2'><b>{t('PageContent.3')}</b></h3>
                                                    <p><>{t('PageContent.14')}</></p>
                                                </div>
                                                <div className='d-flex justify-content-end pe-3'>
                                                    <div className={`rounded ${classes.next} shadow d-flex justify-content-center align-items-center`}>
                                                        <i className='fa-solid fa-arrow-right text-light'></i>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>

                                    <div className={`col-12 col-md-4 my-4 my-lg-0`}>
                                            <Link to='/teacher/checkout' className={`w-100 d-flex flex-column no-decor me-2 p-4 ${classes.balance}`}>
                                                <div className='w-100'>
                                                    <div className={`rounded ${classes.bg_balance}`}></div>
                                                </div>
                                                <div className='w-100 mt-3'>
                                                    <h3 className='mb-2'><b>{t('PageContent.4')}</b></h3>
                                                    <p><>{t('PageContent.12')}</></p>
                                                </div>
                                                <div className='d-flex w-100 justify-content-end pe-3'>
                                                    <div className={`rounded ${classes.next} shadow d-flex justify-content-center align-items-center`}>
                                                        <i className='fa-solid fa-arrow-right text-light'></i>
                                                    </div>
                                                </div>
                                            </Link>
                                    </div>  
                            </div>
                            <div className='w-100 my-5 d-flex flex-column justify-content-center py-5 '>
                                <div className={`row text-light my-5 p-5 w-100 ${classes.bg_light}`}>
                                    <h3 className='mb-3 col-12 poppins'>{t('PageContent.5')}</h3>
                                   
                                        <div className='col-12 d-flex flex-column'>
                                            <div className='w-100'>
                                                <div className={`row d-flex flex-nowrap pt-4 pb-2 poppins border-bottom ${classes.scroll}`} style={styles.slightwhite}>
                                                    <div className='col-10 col-lg-5'><b>{t('PageContent.7')}</b></div>
                                                    <div className='col-4 col-lg-3'><b>{t('PageContent.21')}</b></div>
                                                    <div className='col-5 col-lg-4'><b>{t('PageContent.9')}</b></div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12 py-3 poppins'>
                                                    {courses.map((i,index)=>
                                                    <Link to={`/teacher/${i.id}`} className={`row no-decor d-flex flex-nowrap my-4 text-light ${classes.scroll}`} key={index}>
                                                        <div className='col-10 col-lg-5 d-flex align-items-center'><div className={`d-inline-block align-baseline ${classes.course_pic} me-4 rounded`} style={{backgroundImage:`url(${i.avatar})`}}></div><b className='d-inline-block text-light'>{i.name}</b></div>
                                                        <div className='col-4 col-lg-3 d-flex align-items-center text-light'><b>$ {i.price}</b></div>
                                                        <div className='col-5 col-lg-4 d-flex align-items-center text-light'><b><i>{i.day_of_week} {t('PageContent.17')} {i.start_time} {t('PageContent.18')} {i.end_time}</i></b></div>
                                                    </Link>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                </div>
                            </div>

                        </div>
                        <div className='col-12 my-3 my-lg-0 col-lg-8 col-xxl-4 d-flex order-4 py-5'>
                            <div className={`d-flex flex-column w-100`}>
                                <div className='card bg-transparent'>
                                    <div className='card-primary py-5'>
                                        <div className={`w-100  ${classes.bg_light} py-4 my-4 text-light d-flex justify-content-center align-items-center`}>
                                            <Calendar className='py-2' prevLabel={<big><FaCaretLeft style={{color:'rgba(255,255,255,0.6)'}}/></big>} prev2Label={<FaBackward style={{color:'rgba(255,255,255,0.6)'}}/>} nextLabel={<big><FaCaretRight style={{color:'rgba(255,255,255,0.6)'}}/></big>} next2Label={<FaForward style={{color:'rgba(255,255,255,0.6)'}}/>}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

            </div>

        </div>
  )
}

export default PageContent