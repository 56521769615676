import '../Dashboard.css';
import Sidebar from './Sidebar';
import {Outlet,useParams,Navigate,useLocation} from 'react-router-dom';
import {useAuth} from '../../../providers/ProvideAuth'

function Dashboard(){
    var auth = useAuth();
    const params = useParams();
    const location = useLocation();
    return(
        <>
           
            {auth.user
            
            ?
            <>
            {auth.user.role!=2 && <Navigate to='/user'/>}
            {params.channel ?
            <div className='d-flex' id='wrapper'>
                <Outlet />

            </div>
            :
            <>
            {location.pathname.includes('chat')|| location.pathname.includes('checkout')?
            <div className='d-flex' id='wrapper'>
                <Sidebar />
                <Outlet />
            </div>
            :
            <div className='d-flex p-2 p-md-4' id='wrapper'>
                
                <Sidebar />
                <Outlet />
            </div>}
            </>
            }
            </> 
            :<Navigate to='/'/>}
            
        </>
       
    )
}
export default Dashboard;