import { SocketContext } from "./SocketProvider";
import { useContext, useEffect, useState } from "react";
import { deleteItemFromLocalStorage, getItemFromLocalStorage, setItemInLocalStorage, TokenKey } from "../utils";
import { useAuth } from "./ProvideAuth";
import io from 'socket.io-client';
const ENDPOINT = 'https://scholarist.com:8000/notifications';
let socket = io(ENDPOINT);

export function useSocket(){
    return useContext(SocketContext)
}
export function useProvideSocket(){
   
    var auth = useAuth();
    let [msg,setMsg] = useState("");
    
    useEffect(()=>{
        
        socket.on('assignment posted',(message)=>{
            setMsg(message);
        })
        socket.on('connected',(message)=>{
            console.log('server sent',message);
        })
        socket.on('submission made',(message)=>{
            setMsg(message);
        })
        socket.on('new student',(obj)=>{
            if(obj.name==auth.user.name){
                setMsg(obj.message)
            }
        })
    })
   
    function joinRoom(courseId){
        socket.emit('join room',courseId);
    }

    function newResource(courseId){
        socket.emit('new resource',courseId);
    }

    function newReply(courseId){
        socket.emit('reply added',courseId);
    }
    function studentAdded(courseId){
        socket.emit('student added',courseId)
    }
    return ({
        msg,
        joinRoom,
        newResource,
        setMsg,
        newReply,
        studentAdded
    })
}
