
import { useEffect,useState } from "react";
import { useAuth } from "../../../providers/ProvideAuth";
import { TokenKey } from "../../../utils";
import {Link} from 'react-router-dom';
import classes from '../../../styles/MyChats.module.css';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import GroupModal from "./GroupModal";
import CloseButton from 'react-bootstrap/CloseButton';
function MyChat(props){

    
    var auth = useAuth();
    var {user,chats,setChats,selectedChat,setSelectedChat} = auth;
    const [show,setShow] = useState(false);
    const [searchResults,setSearchResults] = useState(null)
    const handleClose = () => {setShow(false);
    } ;
    const handleShow = ()=>{setShow(true);
    } ;
    async function addChat(item){
        let url = 'https://scholarist.com:8000/api/access'
        try {
            let response = await fetch(url,{
                method:'POST',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body:JSON.stringify(item)
            });
            let data = await response.json();
            let b = false;
            for(var ele of chats){
                console.log(ele.name==data.data.name)
                if(ele.name==data.data.name){
                    b=true;
                    break;
                }
            }
            if(b){
                return toast('Chat already exists',{
                    icon:'💡'
                })
            }
            auth.setChats([...auth.chats,data.data])
            auth.setSelectedChat(data.data)
            
        }catch(err){
            console.log(err)
            return toast.error('Some error in starting new chat')
        }
    }
    async function getChats(){
        try{
            let url = 'https://scholarist.com:8000/api/chats'
            let response = await fetch(url,{
                method:'GET',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            })
            let data = await response.json();
            await setChats(data.data)

        }catch(error){
            console.log(error)
        }
        
    }
    useEffect(()=>{
            getChats();
    },[props.fetch])
    const handleSearch = async (e)=>{
        
        let url='https://scholarist.com:8000/api'
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
       
        let response = await fetch(url+`/fetch?search=${e.target.value}`,{
            method:'GET',
            headers:{
                'Content-type':'application/json',
                'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
            }
        })
        let data = await response.json();
        console.log(data)
        if(data.success){
            setSearchResults(data.data)
        }
        else {
            return toast.error('Some error occurred in fetching users')
        }
        
    }
    function otherOne(item){

        if(!item.isGroupChat) {
            let other = item.users.filter((a)=>a.name!=user.name)
            let chat = {...item,name:other[0].name,avatar:other[0].avatar}
            return chat
        }
        else {
            console.log(item,'group');
            let other = {...item,name:item.name,avatar:'https://us.123rf.com/450wm/keviz/keviz1802/keviz180200567/96530338-teamwork-people-working-together-icon-vector.jpg?ver=6'}
            return other
        }
  
    }
    return (
        <div className="h-100 d-flex flex-column">
        
        <div className="row " style={{fontFamily:'sans-serif'}}>
            <div className="col-7 p-3 d-flex align-items-center">
                <Link to='/teacher' className="no-decor"><div className="d-flex me-5 align-items-center mb-2"><b><i className="fa-solid fa-arrow-left"></i></b></div></Link>
                <h2><b>Chats</b></h2>
            </div>
            <div style={{cursor:'pointer'}} className="col-5 d-flex justify-content-end align-items-center p-3 pb-4" onClick={handleShow}>
                <div className={`p-1 rounded px-3 ${classes.groupBox}`}>+ <b>Group</b></div>
            </div>
     
        </div>
        <div className="row d-flex justify-content-center  my-2" style={{fontFamily:'sans-serif'}}>
            <div className="col-10">
                <Form className='w-100' style={{position:'relative'}}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><i className="fa-solid fa-magnifying-glass"></i></InputGroup.Text>
                        <Form.Control
                        placeholder="Search users.."
                        aria-label="Users"
                        aria-describedby="basic-addon1"
                        onChange={handleSearch}
                        />
                    </InputGroup>
                    <div className={`w-100 ${searchResults? classes.searchDropdown:classes.searchDropdownInvisible} mb-3`}>
                        <div className='row'>
                            <div className='col-11 d-flex justify-content-end p-1 py-2' style={style.label} >
                                <CloseButton onClick={()=>setSearchResults(null)} />
                            </div>
                        </div>
                                        
                        {searchResults?<>{searchResults.length>0?searchResults.map((item)=>
                            <>
                                        
                                <div className={`row ${classes.searchBarResults}`} onClick={addChat.bind(null,item)}>
                                                
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className={`m-1 my-2 ${classes.searchPic}`} style={{backgroundImage:`url(${item.avatar})`}}></div>
                                    </div>
                                    <div className="col-8 d-flex flex-column justify-content-center align-items-start">
                                        <div style={style.label}>{item.name}</div>
                                    </div>
                                </div>
                            </>
                                            
                            ):
                                <>
                                    <div className='row text-dark'>
                                        <div className=' my-2 col-12 d-flex justify-content-center'>
                                            <i>No users found</i>  
                                        </div>
                                                
                                    </div>
                                </>}</>
                            :
                            <></>
                         }
                    </div>
                </Form>
                
            </div>
        </div>
        <div className={`row ${classes.list_container}`}>
            {chats.length>0 ? 
            chats.map((item,i)=>
                <>
                {item && 
                <>
                {selectedChat ? 
                    <>
                    {console.log(selectedChat)}
                    {selectedChat.id==item.id&&
                    <div style={{cursor:'pointer'}} className={` col-12 py-2 ${classes.highlighted}`} onClick={()=>setSelectedChat(otherOne(item))} key={i}>
                    <div className="row py-1">
                        <div className="col-2 offset-1 d-flex justify-content-center align-items-center">
                            <div className={`${classes.profilePic}`} style={{backgroundImage:`url(${otherOne(item).avatar})`}}>

                            </div>
                        </div>
                        <div className="col-9 d-flex flex-column justify-content-center py-2 ">
                            <div style={{fontWeight:'bold'}}>       
                                {otherOne(item).name}
                            </div>
                            <div style={{overflow:'hidden',maxHeight:'1.5rem'}}>
                                <i>{item.lastMessage ? item.lastMessage.content:'Start Chatting...'}</i>
                            </div>
                        </div>
                    </div>
                </div>}
                    {selectedChat.id!=item.id&&
                    <div style={{cursor:'pointer'}} className={`col-12 py-2 `} onClick={()=>setSelectedChat(otherOne(item))} key={i}>
                    <div className="row py-1">
                        <div className="col-2 offset-1 d-flex justify-content-center align-items-center">
                            <div className={`${classes.profilePic}`} style={{backgroundImage:`url(${otherOne(item).avatar})`}}>

                            </div>
                        </div>
                        <div className="col-9 d-flex flex-column justify-content-center py-2 border-bottom">
                            <div style={{fontWeight:'bold'}}>       
                                {otherOne(item).name}
                            </div>
                            <div style={{overflow:'hidden',maxHeight:'1.5rem'}}>
                                <i>{item.lastMessage ? item.lastMessage.content:'Start Chatting...'}</i>
                            </div>
                        </div>
                    </div>
                </div>}
                    </>
                    :
                    <div style={{cursor:'pointer'}} className={` col-12 py-2 `} onClick={()=>setSelectedChat(otherOne(item))} key={i}>
                        <div className="row py-1">
                            <div className="col-2 offset-1 d-flex justify-content-center align-items-center">
                                <div className={`${classes.profilePic}`} style={{backgroundImage:`url(${otherOne(item).avatar})`}}>

                                </div>
                            </div>
                            <div className="col-9 d-flex flex-column justify-content-center py-2 border-bottom">
                                <div style={{fontWeight:'bold'}}>       
                                    {otherOne(item).name}
                                </div>
                                <div style={{overflow:'hidden',maxHeight:'1.5rem'}}>
                                    <i>{item.lastMessage ? item.lastMessage.content:'Start Chatting...'}</i>
                                </div>
                            </div>
                        </div>
                    </div>}
                    
                </>}
                    
                </>
                
            )
            :
            <div className="col-12 my-5 py-5 d-flex justify-content-center">
                <i>No chats for now. Text someone!!</i>
            </div>}
        </div>
        <GroupModal show={show} handleClose={handleClose}/>
        </div>
    )
}


const style={
    bold:{
        fontWeight:'bold'
    },
    label:{
        fontWeight:'bold',
    },
    groupIcon:{
        fontWeight:'bold',
        backgroundColor:'rgba(110,117,124,0.3)',
        cursor:'pointer'
    }
}
export default MyChat;