import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import classes from '../styles/Navbar.module.css';
import { useTranslation } from 'react-i18next';
function Reset() {
    const {token} = useParams();
    const navigate = useNavigate();
    let {t,i18n} = useTranslation()
    let [valid,setValid] = useState(true);
    let [pwd,setPWD] = useState('');
    let [cpwd,setCPWD] = useState('');
    let [finishing,setFinishing] = useState(false);
    useEffect(()=>{
        (
            async function(){
                let reply = await fetch(`https://scholarist.com:8000/api/reset/validcheck/${token}`);
                let response = await reply.json();

                if(!response.success){
                    setValid(false)
                }
            }
        )();
    },[]);

    async function handleReset(e){
        e.preventDefault();
        if(!pwd||!cpwd){
            return toast.error('Please fill all fields')
        }
        else if (pwd!==cpwd){
            return toast.error('Both fields should match')
        }
        setFinishing(true)
        let reply = await fetch(`https://scholarist.com:8000/api/reset/password/${token}`,{
            method:'POST',
            headers:{
                'Content-type':'application/json'
            },
            body:JSON.stringify({password:pwd,confirm_password:cpwd})
        });
        let response = await reply.json();
        if(response.success){
            toast.success('Password reset successful')
        }
        else {
            toast.error('Some error in reseting password')
        }
        setFinishing(false)
        return navigate('/')
    }

    return (
        <div className='container d-flex justify-content-center align-items-center my-5'>
            <div className='row w-100 my-5 d-flex justify-content-center'>
                <div className='col-12 col-md-6 d-flex flex-column justify-content-center min-height-100 py-5'>
                    {valid && 
                    <h2 className='my-4 text-center'>{t('Auth.24')}</h2>}
                    {valid ?
                    <>
                        <Form onSubmit={handleReset} className='w-100 p-4 d-flex flex-column'>
                            <Form.Group controlId="validationCustom01" className='my-4'>
                                <Form.Label>{t('Auth.3')}</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        onChange={(e)=>setPWD(e.target.value)}
                                    />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01" >
                                <Form.Label>{t('Auth.8')}</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        onChange={(e)=>setCPWD(e.target.value)}
                                    />
                            </Form.Group>
                            <div className='mt-5'>
                                    <button type='submit' disabled={finishing} className={`w-100 btn rounded-pill ${classes.submit}`}>
                                    {t('Auth.4')}</button>
                            </div>
                        </Form>
                    </> 
                    
                    :
                    <h2>{t('Auth.26')}</h2>}
                </div>
            </div>
        </div>
    )
}

export default Reset