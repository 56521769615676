import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import classes from '../styles/Navbar.module.css'; 
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
export default function ForgotPassword(props) {

    let {show,onHide} = props;
    let [finishing,setFinishing] = useState(false)
    let [email,setEmail] = useState('')
    let {t,i18n} = useTranslation();
    const [validated, setValidated] = useState(false);

    async function handleSubmit(e){
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        }
        setValidated(true);
        setFinishing(true);

        let reply = await fetch('https://scholarist.com:8000/api/reset',{
                    method:'POST',
                    headers:{
                        'Content-type':'application/json'
                    },
                    body:JSON.stringify({email})
        });
        let response = await reply.json();
        if(response.success){
            setValidated(false);
            setEmail('');
            setFinishing(false);
            onHide();
            if(!response.alreadyDone){
                return toast.success('Check your inbox')
            }
            return toast('Email has been sent already', {
                icon:'💡'
            })
        }
        else{
            setValidated(false);
            setEmail('');
            setFinishing(false);
            onHide();
            return toast.error('Error in requesting reset. Verify if your email is assosciated with us')
        }
    }
    return (
        <Modal {...props} centered onHide={()=>{setFinishing(false);props.onHide()}}>
                <Modal.Header closeButton className='border-0'>
                <Modal.Title>{t('Auth.24')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-1'>
                    
                    <Form noValidate validated={validated} onSubmit={handleSubmit} className='w-100 p-4'>
                        <Form.Group controlId="validationCustom01" >
                            <Form.Label>{t('Auth.2')}</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="abc@example.com"
                                    onChange={(e)=>setEmail(e.target.value)}
                                />
                        </Form.Group>
                        <div className='mt-5'>
                                <button type='submit' disabled={finishing} className={`w-100 btn rounded-pill ${classes.submit}`}>
                                {t('Auth.4')}</button>
                        </div>
                    </Form>
                    <div className='w-100 p-4 text-primary' style={{fontWeight:'bold'}}>
                        {t('Auth.25')}
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                
                </Modal.Footer>
                </Modal>
    )
  
}
