import React,{useEffect,useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

const PopUp = (props)=>{
    let {t,i18n} = useTranslation();
    let {show,onHide,progress,upcoming,balance,content} = props;
    return (
        <Modal show={show} onHide={onHide} centered>
        
            <Modal.Header closeButton>
            <Modal.Title>
                {/* {content=='progress'&&'Courses in Progress'}
                {content=='balance'&&'Balance in Wallet'}
                {content=='upcoming'&&'Upcoming Deadlines'} */}
                {t('PageContent.14')}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4 d-flex flex-column'>

                {/* {content=='progress'&&
                <div className='my-3'>
                    You are enrolled in {progress.length} course(s). More details about these courses that are in progress is as follows:
                    <br>
                    </br>
                    {progress.length<=0&&<div className='my-2'><i>Not enrolled in any course yet</i></div>}
                    <ul className='mt-3 mb-2'>
                    {progress.map(p=>
                        <li className='my-2'><b>{p.name}</b> (<i>Taught By: {p.teacher.name}</i>) </li>
                      )}
                    </ul>
                </div>
                }

                {content=='balance'&&
                <div className='my-3'>
                    Your online wallet has a net balace of $100. Check your financial history and transaction record / contact support for any issues
                </div>
                } */}
               
                <div className='my-3'>
                    {t('PageContent.15')}
                    <br />
                    {upcoming.length<=0&&<div className='my-2'><i>{t('PageContent.16')}</i></div>}
                    <ul className='mt-3 mb-2'>
                    {upcoming.map(u=>
                       <li className='my-2'><b>{u.name}</b> in <i>{u.Course.subject.substring(0,3).toUpperCase()+(100+parseInt(u.Course.id))}</i></li>
                    )}
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-0'>
        
            </Modal.Footer>
        
        </Modal>
    )
}

export default PopUp;