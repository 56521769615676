import React,{useState} from 'react'
import classes from '../styles/Footer.module.css';
import {Link, useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from './Login';

function Footer(){
    let navigate = useNavigate();
    const [showLogin, setShowLogin] = useState(false);
    const handleShow = () => setShowLogin(true);
    let {t,i18n} = useTranslation();
    return (
        <div className={`container-fluid ${classes.bg}`}>
            <Login show={showLogin}
            onHide={() => setShowLogin(false)}/>
            <div className='container'>
                <div className='row py-3'>
                    <div className='col-12 col-lg-6 my-3 my-lg-0'>
                        <div className='row'>
                            <div className='col-6 d-flex flex-column justify-content-center  align-items-center' style={{cursor:'pointer'}} onClick={()=>navigate('/use')}>
                                <h3 className={`${classes.white} mt-2 `}>{t('Footer.1')}</h3>
                                <h3 className={`${classes.white}  text-center`}><span className={classes.red}>{t('Footer.2')}</span>{t('Footer.3')}</h3>
                            </div>
                            <div className='col-6 d-flex flex-column justify-content-center  align-items-center'>
                                <Link to='/about' style={styles.noDecoration}><h6 className={`${classes.white} my-1`}>{t('Footer.4')}</h6></Link>
                                <Link to='/contact' style={styles.noDecoration}><h6 className={`${classes.white} my-1`}>{t('Footer.5')}</h6></Link>
                                <Link to='/services' style={styles.noDecoration}><h6 className={`${classes.white} my-1`}>{t('Footer.6')}</h6></Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 my-3 my-lg-0'>
                        <div className='row py-2'>
                            <div className='col-6 d-flex flex-column justify-content-center  align-items-center'>
                                <Link to='/privacy' style={styles.noDecoration}><h6 className={`${classes.white} my-1`}>{t('Footer.7')}</h6></Link>
                                <Link to='/faq' style={styles.noDecoration}><h6 className={`${classes.white} my-1`}>{t('Footer.8')}</h6></Link>
                                <Link to='/terms' style={styles.noDecoration}><h6 className={`${classes.white} my-1`}>{t('Footer.9')}</h6></Link>
                                
                            </div>
                            <div className='col-6 d-flex flex-column justify-content-center  align-items-center'>
                                <a href='mailto:info@scholarist.com' target='_blank' className='no-decor'><h6 className={`${classes.white} my-1`}><i className="fa-brands fa-google"></i></h6></a>
                                <a href='https://www.facebook.com/people/Scholarist/61550232733014/' className='no-decor' target='_blank'><h6 className={`${classes.white} my-1`}><i className="fa-brands fa-facebook-f"></i></h6></a>
                                <a href='https://www.instagram.com/scholarist_/' className='no-decor' target='_blank'><h6 className={`${classes.white} my-1`}><i className="fa-brands fa-instagram"></i></h6></a>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    )
}
const styles={
    noDecoration:{
        textDecoration:'none'
    }
}
export default Footer;