import React,{useState,useEffect} from 'react'
import classes from '../../styles/Teachers.module.css'
import { Fade } from "react-awesome-reveal";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from '../Login';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
function Teacher(){

    let {id} = useParams()
    var array=[1,2,3,4,5]
    let {t,i18n} = useTranslation()
    let [teacher,setTeacher] = useState({});
    let [courses,setCourses] = useState([]);
    let [name,setName] = useState("");
    let [description,setDesc] = useState("");
    let [email,setEmail] = useState("");
    let [subject,setSubject] = useState("");
    let [finishing,setFinishing] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const handleClose = () => setShowLogin(false);
    const handleShow = () => setShowLogin(true);
    useEffect(()=>{
        (
            async function(){
                let reply = await fetch(`https://scholarist.com:8000/api/teacher/${id}?lang=${i18n.language}`)
                let response = await reply.json()
                setTeacher(response.teacher)
                setCourses(response.courses)
            }
        )();
    },[i18n.language])
    async function submit(e){
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        }

        setValidated(true);
        if(!name||!description||!subject||!email){
            return toast.error('Please fill all the fields')
        }
        setFinishing(true)
        let reply = await fetch(`https://scholarist.com:8000/api/contact/teacher/${id}`,{
            method:'POST',
            headers:{
                'Content-type':'application/json',
            },
            body:JSON.stringify({name,description,email,subject})
        })
        let response = await reply.json()
        if(response.success){
            setFinishing(false)
            return toast.success('We have received your request and will get in touch shortly')
        }
        setFinishing(false);
    }
    function slideLeft(){
        var slider = document.getElementById('courses');
        slider.scrollLeft = slider.scrollLeft-400

    }
    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    }
    
    function slideRight(){
        var slider = document.getElementById('courses');
        slider.scrollLeft = slider.scrollLeft+400

    }

    return(
        <div className={`${classes.background}`}>
            <Login show={showLogin}
            onHide={() => setShowLogin(false)}/>
            <div className={`${classes.bg}`}>
                
            </div>
            <div className='container'>

                <div className={`${classes.profilepic} m-auto`} style={{backgroundImage:`url(${teacher.avatar})`}}></div>

                <div className='row mt-3 d-flex justify-content-center'>

                    <div className='col-8 d-flex justify-content-center mb-5'>
                        <div className='rounded-pill bg-secondary p-2 px-4 mx-3 text-light'>{teacher.skills}</div>
                    </div>

                    <div className={`col-12 col-md-8 d-flex justify-content-between ${classes.text}`}>
                        <div><h2>{teacher.name}</h2></div>
                        <div>
                            <button onClick={handleShow} type='btn' className='btn btn-primary rounded'>{t('Teachers.4')}</button>
                        </div>
                    </div>

                    <div className={`col-12 col-md-8 d-flex my-3 flex-column justify-content-between ${classes.text}`}>
                        <p className='my-5'>
                        {teacher.about}
                        </p>
                        <h5 style={{fontWeight:'bold'}}>
                            {t('Teachers.1')}:
                        </h5>
                        <p className='my-5'>
                        {teacher.description}
                        </p>
                    </div>

                </div>

                        <Fade direction='up' triggerOnce>
                        <h2 className='m-auto d-flex justify-content-center mb-4'>{t('Teachers.2')}</h2>
                        </Fade>
                        <div className='row w-100 d-flex flex-nowrap mb-5'>
                            {courses.length!=0 ? 
                            <>
                                <div className='col-2 col-md-3 col-lg-2 d-flex justify-content-center justify-content-md-end align-items-center'>
                                    <div className={classes.slideLeft} onClick={slideLeft}></div>
                                </div>
                                <div className={`col-8 col-md-6 col-lg-8`}>
                                    <div className={`row d-flex flex-nowrap ${classes.scroll}`} id='courses'>
                                        {courses.map((item,index)=>
                                        <>
                                        <div className='col-9 col-xxl-5 d-flex flex-column no-decor  me-2 p-4 rounded' key={index} onClick={handleShow} style={{cursor:'pointer'}}>
                                            
                                            <div className={`${classes.img} rounded`} style={{backgroundImage:`url(${item.avatar})`}}>

                                            </div>
                                            <div className={`my-1`}>
                                                <b>{item.name}</b>
                                            </div>
                                            <div className={classes.courseinstructor}>
                                                <b>{item.name}</b>
                                            </div>
                                            <div className={classes.rating}>
                                                <b>4.9 &nbsp;</b>
                                                {array.map(item=>
                                                    <i style={{color:'rgb(218,154,60)'}}className='fa-solid fa-star mx-1'></i>)}
                                            </div>
                                            <div>
                                                <b>{item.price} CAD$</b>
                                            </div>
                                            
                                    
                                        </div>
                                        
                                        </>
                                        
                                        )}
                                        
                                        
                                    </div>
                                </div>
                                <div className='col-2 col-md-3 col-lg-2 d-flex justify-content-center justify-content-md-start align-items-center'>
                                    <div className={classes.slideRight} onClick={slideRight}></div>
                                </div>
                            </>
                            :
                            <div className='d-flex col-12 justify-content-center align-items-center'>
                                <p><i>{t('Teachers.3')}</i></p>
                            </div>
                            }
                            
                        </div>
                   

                <div className='row my-5 py-5 d-flex justify-content-center'>
                    <div className='col-12 col-md-8 py-3 shadow d-flex flex-column'>
                        <div className={classes.hi}>
                            <div className={`w-100 d-flex flex-column justify-content-center align-items-center  p-4 ${classes.contact}`}>
                                <h1 className='my-4'>{t('Teachers.5')}</h1>
                                <p className='my-4'>{t('Teachers.6')}</p>
                            </div>
                        </div>
                        <div className='w-100'>
                        <Form noValidate validated={validated} className='my-4 row d-flex justify-content-center'>
                            <div className='col-11  my-2'>
                              <Form.Group>
                                <Form.Label>{t('Teachers.7')}</Form.Label>
                                <Form.Control
                                  placeholder="John"
                                  aria-label="Assignment-1"
                                  aria-describedby="basic-addon1"
                                  onChange={e=>setName(e.target.value)}
                                  value={name}
                                  required
                                />
                              </Form.Group>
                            </div>       
                            <div className='col-11  my-2'>
                              <Form.Group>
                                <Form.Label>{t('Teachers.8')}</Form.Label>
                                <Form.Control
                                  placeholder="abc@example.com"
                                  type='email'
                                  aria-label="Assignment-1"
                                  aria-describedby="basic-addon1"
                                  onChange={e=>setEmail(e.target.value)}
                                  value={email}
                                  required
                                />
                              </Form.Group>
                            </div>       
                            
                            <div className='col-11  my-2'>
                              <Form.Group>
                                <Form.Label>{t('Teachers.10')}</Form.Label>
                                <Form.Control
                                  placeholder=""
                                  aria-label="Assignment-1"
                                  aria-describedby="basic-addon1"
                                  onChange={e=>setSubject(e.target.value)}
                                  value={subject}
                                  required
                                />
                              </Form.Group>
                            </div>       

                            <div className='col-11  my-2'>
                              <Form.Group >
                                <Form.Label>{t('Teachers.11')}</Form.Label>
                                  <Form.Control as="textarea" rows={8} 
                                    maxLength={350}
                                    value={description}
                                    onChange={handleDescChange}
                                    required
                                  />
                              </Form.Group>
                            </div>                                                                        

                            <div className='col-11 mt-5 d-flex justify-content-center'>
                                <button type='button' disabled={finishing} onClick={submit} className='btn btn-success mx-4'>
                                {t('Teachers.12')}
                                </button>
                            </div>
                            </Form>
                        </div>
                    </div>               
                </div>

            </div>
            
        </div>
    )
}
export default Teacher;