import React,{useState, useEffect} from 'react';
import classes from '../../styles/Queue.module.css'
import QueueComponent from './QueueComponent';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import { Fade } from "react-awesome-reveal";
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function Queue(){
    const {t,i18n} = useTranslation();
    let [loading,setLoading] = useState(true)
    let [profiles,setProfiles] = useState([]);

    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/aboutus?lang=${i18n.language}`);
                    let response = await reply.json();
                    setProfiles(response.data);
                    setLoading(false)
                }catch(err){
                    setLoading(false)
                    console.log(err);
                }
            }
        )();
    },[i18n.language])
    function slideLeft(){
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft-600
    }

    function slideRight(){
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft+600

    }

    return(
        <div className={`container ${classes.my_high}`} style={{color:'black'}}>
            
                    <Fade direction='up' triggerOnce>
                    <h2 className='m-auto d-flex justify-content-center mb-4'>{t('HomePage.HowItWorks.0')}</h2>
                    </Fade>
                    <div className='row'>
                        <div className='col-2 col-md-2 d-flex justify-content-center justify-content-md-end align-items-center'>
                            <div className={classes.slideLeft} onClick={slideLeft}>
                            </div>
                        </div>
                        <div className={`col-8 col-md-8 `}>
                            {loading && 
                                <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                <CircularProgress />
                            </Box>}
                            {!loading &&
                                <div className={`row d-flex flex-nowrap ${classes.scroll}`} id='slider'>
                                {profiles.map((item,index)=>
                                <QueueComponent item={item} key={index}/>
                                )}
                                </div>
                            }
                            
                        </div>
                        <div className='col-2 col-md-2 d-flex justify-content-center justify-content-md-start align-items-center'>
                            <div className={classes.slideRight} onClick={slideRight}>
                            </div>
                        </div>
                    </div>
                    
                
        </div>
    )
}
export default Queue;