import React, {useEffect,useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import toast from 'react-hot-toast';
import { TokenKey } from '../../../utils';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import classes from '../../../styles/PageContent.module.css';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import {Line, Bar,  Doughnut, Pie} from 'react-chartjs-2';
import { FaCaretLeft, FaCaretRight, FaForward, FaBackward } from 'react-icons/fa';
import {HiBadgeCheck} from 'react-icons/hi';
import {MdSort} from 'react-icons/md';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import './Analytics.css'

import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highcharts3d from "highcharts/highcharts-3d";
import cylinder from "highcharts/modules/cylinder";

highcharts3d(Highcharts);
cylinder(Highcharts);

dayjs.extend(utc)
dayjs.extend(timezone)

const Analytics = () => {

    const auth = useAuth();
    let {t,i18n} = useTranslation();
    let [selectedType,setType] = useState( { value: '2023', label: '2023' });
    const options = [
        { value: '2019', label: '2019' },
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' },
        { value: '2022', label:'2022'}
    ];
    const [searchResults,setSearchResults] = useState(null)
    const navigate = useNavigate();
    let [courses,setCourses] = useState(345);
    let [revenue, setRevenue] = useState(3715.90);
    let [teachers, setTeachers] = useState(5);
    let [students, setStudents] = useState(130);

    let date = dayjs();

    const labels = ["Jan", "Feb", "Mar", "April", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const labels_bar = ["13-18", "18-25","25-30","30-40","40-50","50-60"];

    const data = {
        labels: labels,
        datasets: [
          {
            label:"Sales",
            backgroundColor: "rgb(135,211,108)",
            data: [0,17,24,32,20,34, 8,6,2,10],
            borderColor:'rgb(220,74,60)'
          }, 
          {
            label:"Leads",
            backgroundColor: "rgb(135,211,108)",
            data: [20,30,32, 45,29,36, 18,16,22,50],
            borderColor:'rgb(157,107,247)'
          }
        ],
    };
    
   const bar_data = {
       labels:labels_bar,
       datasets:[
        {
            label:"All",
            backgroundColor: "rgb(150 ,147, 230)",
            data: [170,170,170,170,170,170],
            borderColor:'rgb(220,74,60)'
          }, 
          {
            label:"Men",
            backgroundColor: "rgb(106, 167, 248)",
            data: [148,148,148,148,148,148],
            borderColor:'rgb(157,107,247)'
          },
          {
            label:"Women",
            backgroundColor: "rgb(242, 158,73)",
            data: [22,22,22,22,22,22],
            borderColor:'rgb(220,74,60)'
          }, 
       ]
   }

    const doughnut_labels = ['IELTS Prep', 'CELPIP Prep', 'French - TEF/TCF'];
    const doughnut_data = {
       labels:doughnut_labels,
       datasets:[
           {
               label:'',
               data:[300, 50, 100],
               backgroundColor:[
                   'rgb(184,213,112)',
                   'rgb(125,136,244)',
                   'rgb(255, 99, 132)'
               ]
           }
       ]
   }

    function slideLeft(){
        var slider = document.getElementById('scrollAble');
        slider.scrollLeft = slider.scrollLeft-173

    }
    function slideRight(){
        var slider = document.getElementById('scrollAble');
        slider.scrollLeft = slider.scrollLeft+173

    }

    

    const handleSearch = async (e)=>{
        
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
        setSearchResults(['Add more data to website from teachers to start searching']);
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    
  return (
    <div id="page-content-wrapper">
            
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-0 px-md-4 text-light d-flex flex-nowrap justify-content-between">
                <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    <h2 className={`mb-1 poppins`}><b style={{fontWeight:'bolder'}}>Analytics</b></h2>
                </div>
                <div className='w-25 d-none d-lg-inline-block' style={{position:'relative'}}>
                    <Form.Control
                        type="text"
                        placeholder={`${t('PageContent.6')}...`}
                        className='rounded px-4 py-2'
                        style={{backgroundColor:'rgb(0,0,0)',border:0}}
                        onChange={handleSearch} 
                    />
                    <div className={`w-100 rounded ${searchResults? classes.searchDropdown:classes.searchDropdownInvisible} mb-3`}>
                        <div className='row'>
                            <div className='col-11 d-flex justify-content-end p-1 py-2' style={styles.label} >
                                <CloseButton className='text-light' onClick={()=>setSearchResults(null)} />
                            </div>
                        </div>
                                        
                        {searchResults?<>{searchResults.length>0?searchResults.map((item)=>
                            <>
                                        
                                <div className={`row ${classes.searchBarResults} d-flex flex-column justify-content-center align-items-center`}>
                                    <div className="col-8 ">
                                        <div style={styles.label}><i>{item}</i></div>
                                    </div>
                                </div>
                            </>
                                            
                            ):
                                <>
                                    <div className='row text-dark'>
                                        <div className=' my-2 col-12 d-flex justify-content-center'>
                                            <i>Nothing to report</i>  
                                        </div>
                                                
                                    </div>
                                </>}</>
                            :
                            <></>
                         }
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div style={styles.mail} className='mx-2'>
                        <i className="text-light fa-regular fa-envelope"></i>
                    </div>
                    <div style={styles.mail} className='mx-2'>
                        <i className="text-light fa-regular fa-bell"></i>
                    </div>
                    
                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                       <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </div>
            </nav>

            <div className='container-fluid'>
                <div className={`row w-100 ${classes.bg} my-4 p-3`}>
                    <div className='col mx-3 rounded p-4 d-flex flex-column' style={styles.bg}>
                         <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i className="fa-brands text-light fa-discourse"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Courses
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{courses}</h2>
                         </div>
                    </div>
                    <div className='col me-3 rounded p-4' style={styles.bg}>
                        <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i class="fa-solid text-light fa-money-bill"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Revenue
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{revenue}</h2>
                         </div>
                    </div>
                    <div className='col me-3 rounded p-4' style={styles.bg}>
                        <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i class="fa-solid text-light fa-bag-shopping"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Teachers
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{teachers}</h2>
                         </div>
                    </div>
                    <div className='col me-3 rounded p-4' style={styles.bg}>
                        <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i class="fa-solid text-light  fa-people-line"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Students
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{students}</h2>
                         </div>
                    </div>
                </div>
            </div>

            {/**Some Analytics Metrics */}

            <div className='container-fluid'  style={{marginTop:200}}>
                <div className='row'> 
                    <div className='col-7 p-3' style={styles.panel}>
                        <div className='d-flex justify-content-between'>
                            <h4 style={{color:'white'}}>Interactions</h4>
                            <div className='px-3 d-flex align-items-center'>
                                <i className="fa-regular fa-calendar fa-lg text-light me-1"></i>
                                <Select 
                                    value={selectedType}
                                    onChange={(e)=>{setType(e)}}
                                    options={options}
                                    required
                                    styles={{
                                        control:(bs)=>({
                                            ...bs,
                                            backgroundColor:'transparent',
                                            border:'none'
                                        }),
                                        singleValue:(bs)=>({
                                            ...bs,
                                            color:'white !important',
                                            fontWeight:'bold'
                                        }),
                                        menu:(bs)=>({
                                            ...bs,
                                            color:'black !important',
                                                        
                                        })
                                        }}
                                />
                                <button type='button' className='btn btn-warning text-dark mx-3'>Download <i className="fa-solid fa-download mx-2"></i></button>
                            </div>

                            
                        </div>
                        <div className='my-5'>
                            <Line data={data} ></Line>
                        </div>
                        
                    </div>
                    <div className='offset-1 col-4 p-3' style={styles.panel}>
                        <h5 style={{color:'white'}}>Statistic By Gender</h5>
                        
                        <div className='pt-4'>
                            <Pie 
                                data={doughnut_data} 
                                options={{
                                    radius:'50%',
                                    padding:0, 
                                    margin:0
                                }}
                            ></Pie>
                        </div>
                    </div>
                </div>

                {/**For 2nd metrics */}
                <div className='row my-5'>

                    <div className='col-7 p-3' style={styles.panel}>
                        <div className='d-flex justify-content-between'>
                            <h4 style={{color:'white'}}>Age</h4>

                            <ul className='p-1 rounded-pill nav d-flex flex-nowrap justify-content-between nav-pills' id="age-tab" role="tablist" style={{backgroundColor:'rgb(28,35,46)'}}>
                                <li className="nav-item rounded-pill" role="presentation" style={{cursor:'pointer',color:'rgb(105,110,117)'}}>
                                    <div className="nav-link no-decor px-2 mx-1 rounded-pill" id="age-all-tab" data-bs-toggle="pill" data-bs-target="#age-all" role="tab" aria-controls="age-all" aria-selected="true" style={{color:'rgb(105,110,117)'}}>All</div>
                                </li>
                                <li className="nav-item rounded-pill" role="presentation" style={{cursor:'pointer',color:'rgb(105,110,117)'}}>
                                    <div className="nav-link no-decor p-2 px-3 mx-1 rounded-pill" id="age-men-tab" data-bs-toggle="pill" data-bs-target="#age-men" role="tab" aria-controls="age-men" aria-selected="false" style={{color:'rgb(105,110,117)'}}>Men</div>
                                </li>
                                <li className="nav-item rounded-pill" role="presentation" style={{cursor:'pointer',color:'rgb(105,110,117)'}}>
                                    <div className="nav-link no-decor p-2 px-3 mx-1 rounded-pill" id="age-women-tab" data-bs-toggle="pill" data-bs-target="#age-women" role="tab" aria-controls="age-women" aria-selected="false" style={{color:'rgb(105,110,117)'}}>Women</div>
                                </li>
                                <li className="nav-item rounded-pill" role="presentation" style={{cursor:'pointer',color:'rgb(105,110,117)'}}>
                                    <div className="nav-link no-decor p-2 px-3 mx-1 rounded-pill" id="age-others-tab" data-bs-toggle="pill" data-bs-target="#age-others" role="tab" aria-controls="age-others" aria-selected="false">Others</div>
                                </li>
                                
                            </ul>

                            
                        </div>
                        
                        {/**Tab Content Panes */}
                        <div className='my-5 tab-content w-100' id='age-tabContent'>

                            {/**Pane for All */}
                            <div className="tab-pane fade show active" id="age-all" role="tabpanel" aria-labelledby="age-all-tab">
                                <Bar data={bar_data} />
                            </div>

                            {/**Pane for Men */}
                            <div className="tab-pane fade" id="age-men" role="tabpanel" aria-labelledby="age-men-tab">
                                <Bar data={bar_data} />
                            </div>

                            {/**Pane for Women */}
                            <div className="tab-pane fade" id="age-women" role="tabpanel" aria-labelledby="age-women-tab">
                                <Bar data={bar_data} />
                            </div>

                            {/**Pane for Others */}
                            <div className="tab-pane fade" id="age-others" role="tabpanel" aria-labelledby="age-others-tab">
                                <Bar data={bar_data} />
                            </div>
                        </div>
                        
                    </div>
                    <div className='offset-1 col-4 p-3' style={styles.panel}>
                        <h5 style={{color:'white'}}>Popular Course</h5>
                        <div className='pt-4'>
                            <Doughnut 
                                data={doughnut_data} 
                                options={
                                    {radius:'50%',
                                    padding:0, 
                                    margin:0}
                                }>
                            </Doughnut>
                        </div>
                    </div>

                </div>

                <div className='row'> 
                    <div className='col-7 p-3' style={styles.panel}>
                        <div className='d-flex flex-column'>
                            <h5 style={{color:'white'}}>Conversion Rate</h5>
                            <div className='d-flex justify-content-between mt-3 align-items-center'>
                                <h2 style={{color:'white'}}><b>9.73%</b></h2>
                                <h6 style={{color:'rgb(113,196,142)'}}><i class="fa-solid fa-arrow-up-long"></i>  3.5% increase</h6>
                            </div>

                            
                        </div>
                        <div className='mt-3'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        type: 'cylinder',
                                        options3d: {
                                            enabled: true,
                                            alpha: 15,
                                            beta: 15,
                                            depth: 50,
                                            viewDistance: 25
                                        },
                                        backgroundColor:'transparent'
                                    },
                                    title:{
                                        text:''
                                    },
                                    xAxis: {
                                        categories: ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90+'],
                                        visible:false
                                    },
                                    yAxis: {
                                        visible:false
                                    },
                                    tooltip: {
                                        headerFormat: '<b>Age: {point.x}</b><br>'
                                    },
                                    plotOptions: {
                                        series: {
                                            depth: 25,
                                            colorByPoint: true
                                        }
                                    },
                                    series: [{
                                        data: [95321, 169339, 121105, 136046, 106800, 58041, 26766, 14291,
                                            7065, 3283],

                                    }]
                                }}
                            
                            />
                        </div>
                        
                    </div>
                    <div className='offset-1 col-4 p-3' style={styles.panel}>
                        <h5 style={{color:'white'}}>Visitors per hour</h5>
                        <div className='d-flex justify-content-between mt-3 align-items-center'>
                            <h2 style={{color:'white'}}><b>1,912</b></h2>
                            <h6 style={{color:'rgb(220,74,60)'}}><i class="fa-solid fa-arrow-down-long"></i>  13.5% decrease</h6>
                        </div>

                        <div className='mt-3'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        type: 'cylinder',
                                        options3d: {
                                            enabled: true,
                                            alpha: 15,
                                            beta: 15,
                                            depth: 50,
                                            viewDistance: 25
                                        },
                                        backgroundColor:'transparent'
                                    },
                                    title:{
                                        text:''
                                    },
                                    xAxis: {
                                        categories: ['0-9', '10-19', '20-29', '30-39', '40-49', '50-59', '60-69', '70-79', '80-89', '90+'],
                                        visible:false
                                    },
                                    yAxis: {
                                        visible:false
                                    },
                                    tooltip: {
                                        headerFormat: '<b>Age: {point.x}</b><br>'
                                    },
                                    plotOptions: {
                                        series: {
                                            depth: 25,
                                            colorByPoint: true
                                        }
                                    },
                                    series: [{
                                        data: [95321, 169339, 121105, 136046, 106800, 58041, 26766, 14291,
                                            7065, 3283],

                                    }]
                                }}
                            
                            />

                        </div>
                        
                    </div>
                </div>
                
                
            </div>

        </div>
  )
}

const styles={
    mail:{
        height:'2.5rem',
        width:'2.5rem',
        borderRadius:'1.25rem',
        backgroundColor:'rgb(0,0,0)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    bg:{
        backgroundColor:'rgba(18,18,18,0.6)',
        position:'relative',
        top:100
    },
    roundedC:{
        backgroundColor:'rgb(0,0,0)'
        
    },
    panel:{
        backgroundColor:'rgb(33,41,54)',
        borderRadius:'3%'
    }
}

export default Analytics