import React from 'react';
import {useAuth} from '../../../providers/ProvideAuth'
import {Link, useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import DeleteAcc from './DeleteAcc';
import classes from '../../../styles/UserProfile.module.css'
import 'react-phone-input-2/lib/style.css';
import { MdSort } from 'react-icons/md';
import { FaPencilAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const UserProfile = () => {
    const auth = useAuth();
    const [editInfo,setEditInfo] = useState(false);
    const [date,onPickingDate] = useState(new Date());
    const [phone,setPhone] = useState("");
    const [pwd,setPWD] = useState("");
    const [description,setdesc] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedLanguage,setSelectedLanguage] = useState({});
    let [file_path,setSelectedFile] = useState("");
    let {t,i18n} = useTranslation();
    let navigate = useNavigate();
    let [selectedType,setType] = useState({ value: 'image', label: 'Image' });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const options = [
        { value: 'english', label: 'English' , code:'en' },
        { value: 'hindi', label: 'Hindi', code:'hi' },
        { value: 'chinese', label: 'Chinese', code:'ch' },
        { value: 'russian', label: 'Russian' , code:'ru'},
        { value: 'spanish', label: 'Spanish', code:'es' },
        { value: 'arabic', label: 'Arabic' , code:'ar'}
      ];
    useEffect(()=>{
        fetch(
            "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
          )
            .then((response) => response.json())
            .then((data) => {
              setCountries(data.countries);
              setSelectedCountry(data.userSelectValue);
            });
    },[])
    async function handleUpdate(e){
        e.preventDefault();
        let formData = new FormData()
        formData.append('file',file_path)
        formData.append('phone',phone);
        formData.append('description',description);
        formData.append('country',selectedCountry);
        formData.append('language_name',selectedLanguage.label);
        formData.append('dob',date)
        
        let res = await auth.update(formData);
        if(res.success){
            toast.success('Profile updated successfully!')
        }
        else{
            toast.error('Some error in updating user')
        }
        setEditInfo(false);
        onPickingDate(new Date());
        setPhone('');
        setdesc('');
        setSelectedCountry('');
        setSelectedLanguage('');
        setSelectedFile();
    };
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    const styles={
        image:{
            backgroundImage:`url(${auth.user.avatar})`
        }
    }
    return (
        <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-3 me-md-5'/></a>  
            </div>
            <h2 className="m-0">My Info</h2>
            <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
                <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
            </div>
        </nav>


        <div className="container-fluid px-4 my-5">
            <div className='row d-flex justify-content-center text-light'>
                <div className='col-11 col-lg-8 p-2 p-lg-4 col-xxl-6 d-flex flex-column' style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                    <div className='w-100 p-2 d-flex justify-content-end mb-3'>
                        <FaPencilAlt size={30} className='text-light' style={{cursor:'pointer'}} onClick={()=>setEditInfo(true)}/>
                    </div>
                    <div className={`${classes.pp} m-auto mb-5`} style={{backgroundImage:`url(${auth.user.avatar})`}}>

                    </div>
                    <div className='mb-3 w-100'>
                        {editInfo?
                        <Form className='w-100 ps-2 mt-3 d-flex flex-column justify-content-center justify-content-lg-between'>
                            <div className='row w-100 mb-4'>
                                <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                    <Form.Label><b>Country:</b></Form.Label>
                                </div>
                                <div className='col-12 col-lg-7'>
                                    <Select
                                        id='country'
                                        options={countries}
                                        onChange={(option) => setSelectedCountry(option)}
                                        required
                                        styles={{
                                            control:(bs)=>({
                                                ...bs,
                                                backgroundColor:'white',
                                                border:'none'
                                            }),
                                            singleValue:(bs)=>({
                                                ...bs,
                                                color:'white !important',
                                                fontWeight:'bold'
                                            }),
                                            menu:(bs)=>({
                                                ...bs,
                                                color:'black !important',
                                                            
                                            })
                                            }}
                                    />
                                </div>
                            </div>
                            <div className='row w-100 mb-4'>
                                <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                    <Form.Label><b>Native Language:</b></Form.Label>
                                </div>
                                <div className='col-12 col-lg-7'>
                                    <Select 
                                        id='language'
                                        styles={{
                                            control:(bs)=>({
                                                ...bs,
                                                backgroundColor:'white',
                                                border:'none'
                                            }),
                                            singleValue:(bs)=>({
                                                ...bs,
                                                color:'white !important',
                                                fontWeight:'bold'
                                            }),
                                            menu:(bs)=>({
                                                ...bs,
                                                color:'black !important',
                                                            
                                            })
                                            }}
                                        onChange={(e)=>setSelectedLanguage(e)}
                                        options={options}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='row w-100 mb-4'>
                                <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                    <Form.Label><b>Phone Number:</b></Form.Label>
                                </div>
                                <div className='col-12 col-lg-7'>
                                    <Form.Group>
                                        <PhoneInput
                                            country={'us'}
                                            value={phone}
                                            onChange={e=>setPhone(e)} 
                                            dropdownClass='text-dark'
                                        />
                                    </Form.Group>
                                    
                                </div>
                            </div>
                            <div className='row w-100 mb-4'>
                                <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                    <Form.Label><b>About Me:</b></Form.Label>
                                </div>
                                <div className='col-12 col-lg-7'>
                                    <Form.Control as="textarea" rows={6} onChange={e=>setdesc(e.target.value)}/>
                                </div>
                            </div>
                            <div className='row w-100 mb-4'>
                                <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                    <Form.Label><b>Profile Picture: (must be .png, .jpg/.jpeg)</b></Form.Label>
                                </div>
                                <div className='col-12 col-lg-7'>
                                    <Form.Control
                                        type='file'
                                        onChange={e=>setSelectedFile(e.target.files[0])}
                                        accept='.png,.jpg,.jpeg'
                                    />
                                </div>
                            </div>
                            <div className='w-100 my-5 d-flex justify-content-center'>
                                <button className='btn btn-success mx-3' onClick={handleUpdate}>Update Me</button>
                                <button className='btn btn-danger' onClick={()=>setEditInfo(false)}>Cancel</button>
                            </div>
                        </Form>
                        :
                        <Form className='w-100 ps-2 mt-3 d-flex flex-column justify-content-center justify-content-lg-between'>
                            <div className='col-12 mb-4 text-center'>
                                {auth.user.description ? auth.user.description:
                                <span className='text-secondary'>Hmm...Looks pretty empty. Tell us about more yourself in the description box.....</span>}
                            </div>
                            <div className='col-12 col-md-6 my-2'>
                                <div className='row w-100 mb-4'>
                                    <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                        <Form.Label><b>Name:</b></Form.Label>
                                    </div>
                                    <div className='col-12 col-lg-7 d-lg-flex align-items-center justify-content-center'>
                                        {auth.user.name}
                                    </div>
                                </div>
                                <div className='row w-100 mb-4'>
                                    <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                        <Form.Label><b>Country:</b></Form.Label>
                                    </div>
                                    <div className='col-12 col-lg-7 d-lg-flex align-items-center justify-content-center'>
                                        {auth.user.country}
                                    </div>
                                </div>
                                <div className='row w-100 mb-4'>
                                    <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                        <Form.Label><b>Native Language:</b></Form.Label>
                                    </div>
                                    <div className='col-12 col-lg-7 d-lg-flex align-items-center justify-content-center'>
                                        {auth.user.language_name}
                                    </div>
                                </div>
                                <div className='row w-100 mb-4'>
                                    <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                        <Form.Label><b>Date of Birth:</b></Form.Label>
                                    </div>
                                    <div className='col-12 col-lg-7 d-lg-flex align-items-center justify-content-center'>
                                        {auth.user.dob?auth.user.dob:'N/A'}
                                    </div>
                                </div>
                                <div className='row w-100 mb-4'>
                                    <div className='col-8 my-2 offset-lg-1 col-lg-3 d-flex align-items-center'>
                                        <Form.Label><b>Phone Number:</b></Form.Label>
                                    </div>
                                    <div className='col-12 col-lg-7 d-lg-flex align-items-center justify-content-center'>
                                        {auth.user.phone?auth.user.phone:'N/A'}
                                    </div>
                                </div>
                            </div>
                        </Form>
                        }
                        
                    </div>

                    <div className='mb-3 w-100 d-flex justify-content-center'>
                            <h6 className='text-danger' style={{fontWeight:'bold', cursor:'pointer'}} onClick={handleShow}>Want to delete account ? Click here</h6>
                    </div>

                    <DeleteAcc show={show} handleClose={handleClose}/>

                    
                </div>
            </div>
        </div>


    </div> 
    )
}

export default UserProfile