import React, {useState,useEffect, useTransition} from 'react'
import {Link,useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-hot-toast';
import { MdSort } from 'react-icons/md';
import NewCourseModal from './NewCourseModal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
const NewCourse = () => {
    
    const auth = useAuth();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();
    let {t,i18n} = useTranslation();
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    const styles={
        profileIcon:{
            backgroundImage:`url(${auth.user.avatar})`,
            width:'3rem',
            height:'3rem',
            borderRadius:'50%',
            backgroundSize:'cover'
        }
    }
    
    
    return(
        <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light '/></a>
                </div>
                <h2 className="m-0">Create a Course</h2>
                <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                        <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                </div>
                
            </nav>
            
            <NewCourseModal open={show} onClose={handleClose}/>
            <div className='container text-white my-5'>
                <div className='row d-flex py-5 justify-content-center'>
                    <h5 className='col-10' style={{fontWeight:'normal'}}>
                    Welcome to the <i>Add a new course</i> wizard. After clicking on the Set up a course button below, you will be assisted by our tool to set up a new course based on your needs. Kindly note that in case you face any issue in adding a course, feel free to contact support or chat with admin directly.
                        Happy teaching !
                    </h5>
                </div>
                <div className='row my-5 py-5 d-flex justify-content-center'>
                    <div className='col-10 d-flex justify-content-center'>
                        <button onClick={handleShow} className='btn btn-danger'><b>Set up a course</b></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewCourse