
import { useEffect,useState } from "react";
import { useAuth } from "../../../providers/ProvideAuth";
import { TokenKey } from "../../../utils";
import {Link} from 'react-router-dom';
import classes from '../../../styles/MyChats.module.css';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CloseButton from 'react-bootstrap/CloseButton';
function MyChat(props){

    
    var auth = useAuth();
    var {user,chats,setChats,selectedChat,setSelectedChat} = auth;
    const [searchResults,setSearchResults] = useState(null)
    
    useEffect(()=>{
        getChats();
    },[props.fetch])
    async function addChat(item){
        let url = 'https://scholarist.com:8000/api/access'
        try {
            let response = await fetch(url,{
                method:'POST',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body:JSON.stringify(item)
            });
            let data = await response.json();
            let b = false;
            for(var ele of chats){
                console.log(ele.name==data.data.name)
                if(ele.name==data.data.name){
                    b=true;
                    break;
                }
            }
            if(b){
                return toast('Chat already exists',{
                    icon:'💡'
                })
            }
            auth.setChats([...auth.chats,data.data])
            auth.setSelectedChat(data.data)
            
        }catch(err){
            console.log(err)
            return toast.error('Some error in starting new chat')
        }
    }
    async function getChats(){
        try{
            let url = 'https://scholarist.com:8000/api/chats'
            let response = await fetch(url,{
                method:'GET',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            })
            let data = await response.json();
            await setChats(data.data)

        }catch(error){
            console.log(error)
        }
        
    }
    
    const handleSearch = async (e)=>{
        
        let url='https://scholarist.com:8000/api'
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
       
        let response = await fetch(url+`/fetch?search=${e.target.value}`,{
            method:'GET',
            headers:{
                'Content-type':'application/json',
                'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
            }
        })
        let data = await response.json();
        console.log(data)
        if(data.success){
            setSearchResults(data.data)
        }
        else {
            return toast.error('Some error occurred in fetching users')
        }
        
    }
    function otherOne(item){

        if(!item.isGroupChat) {
            let other = item.users.filter((a)=>a.name!=user.name)
            let chat = {...item,name:other[0].name,avatar:other[0].avatar}
            return chat
        }
        else {
            console.log(item,'group');
            let other = {...item,name:item.name,avatar:'https://us.123rf.com/450wm/keviz/keviz1802/keviz180200567/96530338-teamwork-people-working-together-icon-vector.jpg?ver=6'}
            return other
        }
  
    }
    return (
        <div className="h-100 d-flex flex-column">
        
        <div className="row " style={{fontFamily:'sans-serif'}}>
            <div className="col-7 p-3 d-flex align-items-center">
                <Link to='/student' className="no-decor"><div className="d-flex me-5 align-items-center mb-2"><b><i className="fa-solid fa-arrow-left"></i></b></div></Link>
                <h2><b>Chats</b></h2>
            </div>
            
        </div>
        <div className="row d-flex justify-content-center  my-2" style={{fontFamily:'sans-serif'}}>
            <div className="col-10">
            </div>
        </div>
        <div className={`row ${classes.list_container}`}>
            {chats.length>0 ? 
            chats.map((item,i)=>
                <>
                    {selectedChat ? 
                    <>
                    {console.log(selectedChat)}
                    {selectedChat.id==item.id&&
                    <div style={{cursor:'pointer'}} className={` col-12 py-2 ${classes.highlighted}`} onClick={()=>setSelectedChat(otherOne(item))} key={i}>
                    <div className="row py-1">
                        <div className="col-2 offset-1 d-flex justify-content-center align-items-center">
                            <div className={`${classes.profilePic}`} style={{backgroundImage:`url(${otherOne(item).avatar})`}}>

                            </div>
                        </div>
                        <div className="col-9 d-flex flex-column justify-content-center py-2 ">
                            <div style={{fontWeight:'bold'}}>       
                                {otherOne(item).name}
                            </div>
                            <div style={{overflow:'hidden',maxHeight:'1.5rem'}}>
                                <i>{item.lastMessage ? item.lastMessage.content:'Start Chatting...'}</i>
                            </div>
                        </div>
                    </div>
                </div>}
                    {selectedChat.id!=item.id&&
                    <div style={{cursor:'pointer'}} className={`col-12 py-2 `} onClick={()=>setSelectedChat(otherOne(item))} key={i}>
                    <div className="row py-1">
                        <div className="col-2 offset-1 d-flex justify-content-center align-items-center">
                            <div className={`${classes.profilePic}`} style={{backgroundImage:`url(${otherOne(item).avatar})`}}>

                            </div>
                        </div>
                        <div className="col-9 d-flex flex-column justify-content-center py-2 border-bottom">
                            <div style={{fontWeight:'bold'}}>       
                                {otherOne(item).name}
                            </div>
                            <div style={{overflow:'hidden',maxHeight:'1.5rem'}}>
                                <i>{item.lastMessage ? item.lastMessage.content:'Start Chatting...'}</i>
                            </div>
                        </div>
                    </div>
                </div>}
                    </>
                    :
                    <div style={{cursor:'pointer'}} className={`col-12 py-2 `} onClick={()=>setSelectedChat(otherOne(item))} key={i}>
                        <div className="row py-1">
                            <div className="col-2 offset-1 d-flex justify-content-center align-items-center">
                                <div className={`${classes.profilePic}`} style={{backgroundImage:`url(${otherOne(item).avatar})`}}>

                                </div>
                            </div>
                            <div className="col-9 d-flex flex-column justify-content-center py-2 border-bottom">
                                <div style={{fontWeight:'bold'}}>       
                                    {otherOne(item).name}
                                </div>
                                <div style={{overflow:'hidden',maxHeight:'1.5rem'}}>
                                    <i>{item.lastMessage ? item.lastMessage.content:'Start Chatting...'}</i>
                                </div>
                            </div>
                        </div>
                    </div>}
                    
                </>
                
            )
            :
            <div className="col-12 my-5 py-5 d-flex justify-content-center">
                <i>No chats for now. Text someone!!</i>
            </div>}
        </div>
        
        </div>
    )
}


const style={
    bold:{
        fontWeight:'bold'
    },
    label:{
        fontWeight:'bold',
    },
    groupIcon:{
        fontWeight:'bold',
        backgroundColor:'rgba(110,117,124,0.3)',
        cursor:'pointer'
    }
}
export default MyChat;