import React, { useEffect } from 'react'
import toast from 'react-hot-toast';
import { useNavigate,Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import { useStudent } from '../../../providers/ProvideStudent';
import classes from '../../../styles/Sidebar.module.css';
import { TokenKey } from '../../../utils';
import { useSocket } from '../../../providers/ProvideSocket';
import {useTranslation} from 'react-i18next';
const Sidebar = () => {
        const navigate = useNavigate();
        const auth = useAuth();
        const student = useStudent();
        const {joinRoom,msg,setMsg} = useSocket();
        const location = useLocation();
        let {t,i18n} = useTranslation();
        
        useEffect(()=>{
                (
                        async function(){
                            try{
                                let reply = await fetch(`https://scholarist.com:8000/api/students/classes?lang=${i18n.language}`,{
                                    method:'GET',
                                    headers:{
                                        'Content-type':'application/json',
                                        'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                                    }
                                });
                                let response = await reply.json();
                                await student.setCourses(response.data);
                            }catch(err){
                                console.log(err,student)
                            }
                        }
                )();
        },[i18n.language])

        useEffect(()=>{
                if(student.courses.length>0){
                        for(let item of student.courses){
                                joinRoom(item.subject.substring(0,3).toUpperCase()+(100+parseInt(item.id)))
                                console.log('connected to notifications io',item.subject.substring(0,3).toUpperCase()+(100+parseInt(item.id)))
                        }
                }
        },[student.courses])
        useEffect(()=>{
                console.log('dillagi bhula doge',msg)
                if(msg){
                        
                        toast(`${msg}`,{
                                icon:'ℹ️'
                        })
                        setMsg('')
                }
        },[msg])        

        
  return (
        <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarOffcanvas" aria-labelledby="sidebarOffcanvasLabel">
                <div class="offcanvas-header">
                        <div className="w-100 d-flex justify-content-center">
                                <div className={`${classes.scholarist_logo}`}>

                                </div>
                        </div>
                </div>
                <div class="offcanvas-body">
                        <div className="list-group list-group-flush my-3 pb-5">
                                <Link to='/student' className={`d-flex align-items-center list-group-item px-2 ps-3 py-3 my-2 rounded-pill list-group-item-action ${location.pathname=='/student'?'active':''}`}><i
                                className={`${classes.purple} fa-solid fa-house-user me-2`}></i><div><b>{t('Sidebar.1')}</b></div></Link>
                                <a className="d-flex align-items-center list-group-item px-2 ps-3 py-3 my-2 rounded-pill list-group-item-action fw-bold" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"><i 
                                className={`fa-solid fa-book ${classes.green} me-2`}></i>{t('Sidebar.2')} <i className="fa-solid fa-chevron-down mx-1"></i></a>
                                <div className="collapse w-100" id="collapseExample">
                                        {student.courses.length>0?
                                        <>
                                        {student.courses.map(item=>
                                        <div className='w-100 d-flex flex-column align-items-center'>
                                        <Link to={`/student/${item.id}`} className="no-decor px-2 ps-3 my-2 d-flex justify-content-center rounded-pill w-100 text-dark"><div className=' w-50 my-2'>{item.subject.substring(0,3).toUpperCase()+(100+parseInt(item.id))}</div></Link>
                                        </div>)}
                                        </>
                                        :
                                        <div className='w-100 d-flex flex-column align-items-center'>
                                        <i>Purchase to enrol</i>
                                        </div>
                                        }
                                        
                                </div>
                                <Link to="/student/teachers" className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action fw-bold ${location.pathname.includes(t('Sidebar.16').toLowerCase())?'active':''}`}><i
                                        className={`${classes.red} fa-solid fa-chalkboard-user me-2`}></i>{t('Sidebar.16')}</Link>
                                <Link to="/student/notifications" className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action fw-bold ${location.pathname.includes(t('Sidebar.4').toLowerCase())?'active':''}`}><i
                                        className={`${classes.red} fa-solid fa-bell me-2`}></i>{t('Sidebar.4')}</Link>
                                
                                <Link to='/student/chat' className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action fw-bold ${location.pathname.includes(t('Sidebar.6').toLowerCase())?'active':''}`}><i
                                        className={`text-primary fa-solid fa-comment-dots me-2`}></i>{t('Sidebar.6')}</Link>
                                <Link to='/student/checkout' className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action fw-bold ${location.pathname.includes(t('Sidebar.13').toLowerCase())?'active':''}`}><i
                                        className={`${classes.green} fa-solid fa-dollar-sign me-2`}></i>{t('Sidebar.13')}</Link>
                                <Link to='/student/support' className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action fw-bold ${location.pathname.includes(t('Sidebar.9').toLowerCase())?'active':''}`}><i
                                        className={`${classes.purple} fa-solid fa-phone me-2`}></i>{t('Sidebar.9')}</Link>
                                <Link to='/student/settings' className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action ${classes.gear} fw-bold ${location.pathname.includes(t('Sidebar.14').toLowerCase())?'active':''}`}><i
                                        className="fa-solid fa-gear me-2"></i><span className='text-dark'>{t('Sidebar.14')}</span></Link>
                                <Link to='/student/videocall/0' target='_blank' className={`list-group-item px-2 ps-3 py-3 my-2 d-flex  align-items-center rounded-pill list-group-item-action fw-bold`} replace={true}><i
                                        className={`fa-solid fa-video me-2 ${classes.video} `}></i><span className='text-dark'>{t('Sidebar.17')}</span></Link>
                        </div>
                        <div className={classes.review} style={{cursor:'pointer'}} onClick={()=>navigate('/student/review/page',{replace:true})}>

                        </div>
                </div>
        </div>

  )
}

export default Sidebar