import React,{useEffect,useState} from 'react';
import Modal from 'react-bootstrap/Modal';

const PopUp = (props)=>{

    let {show,onHide,progress,upcoming,balance,content} = props;
    return (
        <Modal show={show} onHide={onHide} centered>
        
            <Modal.Header closeButton>
            <Modal.Title>
                {content=='progress'&&'Courses in Progress'}
                {content=='balance'&&'Balance in Wallet'}
                {content=='upcoming'&&'Upcoming Deadlines'}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body className='py-4 d-flex flex-column'>

                {content=='progress'&&
                <div className='my-3'>
                    You are currently teaching {progress.length} course(s). More details about these courses that are in progress is as follows:
                    <br>
                    </br>
                    {progress.length<=0&&<div className='my-2'><i>Didn't register any new course</i></div>}
                    <ul className='mt-3 mb-2'>
                    {progress.map(p=>
                        <li className='my-2'><b>{p.name}</b></li>
                      )}
                    </ul>
                </div>
                }

                {content=='balance'&&
                <div className='my-3'>
                    Your online wallet has a net balace of $100. Check your financial history and transaction record / contact support for any issues
                </div>
                }
                
                {content=='upcoming'&&
                <div className='my-3'>
                    You have posted {upcoming.length} resources that are nearing respective deadline(s). These resources are as follows:
                    <br />
                    {upcoming.length<=0&&<div className='my-2'><i>Didn't post new resources !</i></div>}
                    <ul className='mt-3 mb-2'>
                    {upcoming.map(u=>
                       <li className='my-2'><b>{u.name}</b> in <i>{u.Course.subject.substring(0,3).toUpperCase()+(100+parseInt(u.Course.id))}</i></li>
                    )}
                    </ul>
                </div>}
            </Modal.Body>
            <Modal.Footer className='border-0'>
        
            </Modal.Footer>
        
        </Modal>
    )
}

export default PopUp;