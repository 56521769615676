import React,{useState} from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as SingleDatePicker} from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import classes from '../../../../styles/NewCourse.module.css'
import DatePicker from "react-multi-date-picker"

function Slide3(props){

    let {startDay,setStartDay,startTime,setStartTime,endDay,setEndDay,endTime, setEndTime, daysOfPackage,setDays} = props;

    return (
        <div className='w-100 d-flex flex-column align-items-center'>
            <div className='row d-flex py-3 justify-content-between'>
                <div className='col-12 col-md-6 my-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <SingleDatePicker
                                label='Starting Day'
                                value={startDay}
                                onChange={(newValue) => setStartDay(newValue)}
                                format='YYYY/MM/DD'
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className='col-12 col-md-6 my-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <SingleDatePicker
                                label='Ending Day'
                                value={endDay}
                                onChange={(newValue) => setEndDay(newValue)}
                                format='YYYY/MM/DD'     
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className='col-12 col-md-6 my-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePicker
                                label='Class Start Time'
                                value={startTime}
                                onChange={(newValue) => setStartTime(newValue)}
                                        
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className='col-12 col-md-6 my-2'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePicker
                                label='Class End Time'
                                value={endTime}
                                onChange={(newValue) => setEndTime(newValue)}
                                        
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className='col-12 my-4'>
                    <div><Form.Label style={{fontWeight:'bold'}}>Days of classes:</Form.Label></div>
                    <DatePicker 
                        multiple
                        minDate={startDay.toDate()}
                        maxDate={endDay.toDate()}
                        onChange={arr=>{setDays(arr.join(","));console.log('hjbfhbf....',arr)}}
                    />
                </div>
                {console.log(daysOfPackage)}
                <div className='col-12 my-3'>
                    <b style={{color:'purple'}}>Note: Before moving ahead, please keep in mind that these start and end dates/times are according to Scholarist time i.e. Toronto timezone. For putting dates/times according to your own timezone, kindly convert and then select accordingly</b>
                </div>
            </div>
        </div>
    )
}

export default Slide3;