import { useEffect,useState } from 'react';
import classes from '../../styles/Contact.module.css';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';
import { ChatDots } from 'react-bootstrap-icons';
import AIChatbot from './AIChatbot';
function ContactUs(){

    
    let [questions,setQuestions] = useState([]);
    let [loading,setLoading] = useState(false);
    
    let {t,i18n} = useTranslation();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        (
            async function(){
                setLoading(true);
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/fetch/faq?lang=${i18n.language}`);
                    let response = await reply.json();
                    if(response.success){
                        setQuestions(response.data)
                    }
                    setLoading(false)
                }catch(err){
                    console.log(err);
                    setLoading(false)
                    return toast.error('Some error occurred in server')
                }
            }
        )();
    },[i18n.language])
    
    
    
    const styles={
        
        grey:{
            color:'rgb(144,155,156)'
        }
    }
    return(
        <>
            <AIChatbot show={show} onHide={handleClose}/>
            <div className='container my-5 py-5'  style={{minHeight:'80vh'}}>
                <div className='row d-flex flex-column align-items-center justify-content-evenly'>
                    <div className='col-12 mb-5 d-flex justify-content-center'>
                        <h1 style={styles.color}>{t('ContactUs.1')}</h1>
                    </div>
                    {loading ? <h3 className='text-center text-secondary'><i>Loading...</i></h3>:
                    <>
                    <div className='col-12 col-md-8 my-2 my-lg-5 accordion'>
                        {questions.map((q,index)=>
                        <div className={`accordion-item border-0 my-3 ${classes.button}`} key={index} >
                                <h2 className="accordion-header border-bottom" style={{backgroundColor:'white !important'}}>
                                    <button className={`accordion-button ${classes.button} collapsed`} style={{backgroundColor:'white !important'}} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                        <h4>{q.name}</h4>
                                    </button>
                                </h2>
                                <div id={`collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {q.description}
                                    </div>
                                </div>

                            
                        </div>
                        )}
                    </div>
                    
                    </>}
                    
                </div>
                <div className={show?'d-none':classes.chat_icon} onClick={handleShow}>
                            <ChatDots size={30}/>
                </div>
            </div>
            
        </>
    )
}
export default ContactUs;