import React from 'react';
import classes from '../../styles/HowToUse.module.css';
import { useTranslation } from 'react-i18next';
export default function Slide1(props) {

  let {t,i18n} = useTranslation();
  return (
    <div className={`col-10 col-lg-8 col-xxl-5 text-light p-3 d-flex flex-column poppins justify-content-center align-items-center px-lg-5 ${classes.container}`}>
           <h3 className='py-5 my-5 text-warning'>{t('HowToUse.4')}</h3>

           <div className='w-100 d-flex flex-nowrap mb-5'>
                <div className={`${classes.walking_student}`}>

                </div>
                <div className={`${classes.signUpForm}`}>

                </div>
           </div>
           <div className='w-100 px-5 py-5 d-flex justify-content-end'>
                <button className='rounded btn btn-danger text-light' onClick={()=>props.next(2)}>{t('HowToUse.5')}</button>
           </div> 
    </div>
  )
}
