import React, {useEffect,useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import toast from 'react-hot-toast';
import { TokenKey } from '../../../utils';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import classes from '../../../styles/PageContent.module.css';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import Chart from "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2"
import {MdSort} from 'react-icons/md';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps"

const geoUrl="https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";


dayjs.extend(utc)
dayjs.extend(timezone)

const PageContent = () => {

    const auth = useAuth();
    let {t,i18n} = useTranslation();
    const [searchResults,setSearchResults] = useState(null);
    let [courses,setCourses] = useState(345);
    let [revenue, setRevenue] = useState(3715.90);
    let [teachers, setTeachers] = useState(5);
    let [students, setStudents] = useState(130);
    const navigate = useNavigate();
    const labels = ["Jan", "Feb", "Mar", "April", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    const data = {
        labels: labels,
        datasets: [
          {
            label:"Sales",
            backgroundColor: "rgb(135,211,108)",
            data: [0,17,24,32,20,34, 8,6,2,10],
          }
        ],
    };

    const doughnut_labels = ['Red', 'Blue', 'Yellow'];
    const doughnut_data = {
        labels:doughnut_labels,
        datasets:[
            {
                label:'My First Dataset',
                data:[300, 50, 100],
                backgroundColor:[
                    'rgb(184,213,112)',
                    'rgb(125,136,244)',
                    'rgb(255, 99, 132)'
                ]
            }
        ]
    }
    
    const markers = [
        {
          markerOffset: -15,
          name: "Buenos Aires",
          coordinates: [-58.3816, -34.6037]
        },
        { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
        { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
        
        { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
        { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464] },
        {markerOffset: 25, name: "Toronto", coordinates: [-79.3832, 43.6532]},
        { markerOffset: -15, name: "Delhi", coordinates: [77.1025, 28.7041] },

    ];

    let date = dayjs();
    
   
    function slideLeft(){
        var slider = document.getElementById('scrollAble');
        slider.scrollLeft = slider.scrollLeft-173

    }
    function slideRight(){
        var slider = document.getElementById('scrollAble');
        slider.scrollLeft = slider.scrollLeft+173

    }

    

    const handleSearch = async (e)=>{
        
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
        setSearchResults(['Add more data to website from teachers to start searching']);
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }

    const styles = {
        mail:{
            height:'2.5rem',
            width:'2.5rem',
            borderRadius:'1.25rem',
            backgroundColor:'black',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
        },
        image:{
            backgroundImage:`url(${auth.user.avatar})`
        },
        clock:{
            fontFamily:"DS-Digital, sans-serif;"
        },
        monospace:{
            fontFamily:'monospace',
            color:'rgb(228,138,105)'
        },
        slightwhite:{
            color:'rgba(255,255,255,0.56)'
        },
        label:{
            fontWeight:'bold',
        },
        bg:{
            backgroundColor:'rgba(18,18,18,0.6)',
            position:'relative',
            top:100
        },
        roundedC:{
            backgroundColor:'rgb(0,0,0)'
            
        },
        panel:{
            backgroundColor:'rgb(33,41,54)',
            borderRadius:'3%'
        }
        
    }
   
  return (
    <div id="page-content-wrapper">

            {/** Navigation Bar */}
            
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-0 px-md-4 text-light d-flex flex-nowrap justify-content-between">
                <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    <h2 className={`mb-1 poppins`}><b style={{fontWeight:'bolder'}}>Dashboard</b></h2>
                </div>
                <div className='w-25 d-none d-lg-inline-block' style={{position:'relative'}}>
                    <Form.Control
                        type="text"
                        placeholder={`${t('PageContent.6')}...`}
                        className='rounded px-4 py-2 text-light'
                        style={{backgroundColor:'rgb(0,0,0)',border:0}}
                        onChange={handleSearch} 
                    />
                    <div className={`w-100 rounded ${searchResults? classes.searchDropdown:classes.searchDropdownInvisible} mb-3`}>
                        <div className='row'>
                            <div className='col-11 d-flex justify-content-end p-1 py-2' style={styles.label} >
                                <CloseButton className='text-light' onClick={()=>setSearchResults(null)} />
                            </div>
                        </div>
                                        
                        {searchResults?<>{searchResults.length>0?searchResults.map((item)=>
                            <>
                                        
                                <div className={`row ${classes.searchBarResults} d-flex flex-column justify-content-center align-items-center`}>
                                    <div className="col-8 ">
                                        <div style={styles.label}><i>{item}</i></div>
                                    </div>
                                </div>
                            </>
                                            
                            ):
                                <>
                                    <div className='row text-dark'>
                                        <div className=' my-2 col-12 d-flex justify-content-center'>
                                            <i>Nothing to report</i>  
                                        </div>
                                                
                                    </div>
                                </>}</>
                            :
                            <></>
                         }
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div style={styles.mail} className='mx-2'>
                        <i className="text-light fa-regular fa-envelope"></i>
                    </div>
                    <div style={styles.mail} className='mx-2'>
                        <i className="text-light fa-regular fa-bell"></i>
                    </div>
                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                       <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </div>
            </nav>

            {/** Total Panels */}

            <div className='container-fluid'>
                <div className={`row w-100 ${classes.bg} my-4 p-3`}>
                    <div className='col mx-3 rounded p-4 d-flex flex-column' style={styles.bg}>
                         <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i className="fa-brands text-light fa-discourse"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Courses
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{courses}</h2>
                         </div>
                    </div>
                    <div className='col me-3 rounded p-4' style={styles.bg}>
                        <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i class="fa-solid text-light fa-money-bill"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Revenue
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{revenue}</h2>
                         </div>
                    </div>
                    <div className='col me-3 rounded p-4' style={styles.bg}>
                        <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i class="fa-solid text-light fa-bag-shopping"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Teachers
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{teachers}</h2>
                         </div>
                    </div>
                    <div className='col me-3 rounded p-4' style={styles.bg}>
                        <div>
                            <div className='px-2 py-1 rounded d-inline-block' style={styles.roundedC}>
                                <i class="fa-solid text-light  fa-people-line"></i>
                            </div>
                         </div>
                         <div className='mt-5 text-secondary'>
                            Total Students
                         </div>
                         <div className='my-2 text-light'>
                            <h2>{students}</h2>
                         </div>
                    </div>
                </div>
            </div>

            {/** Revenue + Stats */}

            <div className='container-fluid' style={{marginTop:200}}>
                <div className='row'>

                    <div className={`col mx-3 p-2`} style={styles.panel}>
                        <div className={`w-100 d-flex justify-content-between align-items-center ${classes.border_bottom}`}>
                            <h5 className={`p-3 text-light `}>Revenue</h5>
                            <i style={{cursor:'pointer'}} class="fa-solid fa-ellipsis-vertical text-secondary fa-lg mx-3"></i>
                        </div>
                        

                        <div>
                            <h3 className='p-3 text-light'>$ {revenue}</h3>
                        </div>

                        <div>
                            <Bar data={data} />
                        </div>

                    </div>

                    <div className={`col mx-3`} style={styles.panel}>
                        <div className={`w-100 d-flex justify-content-between align-items-center ${classes.border_bottom}`}>
                            <h5 className={`p-3 text-light `}>Student Statistics</h5>
                            <i style={{cursor:'pointer'}} class="fa-solid fa-ellipsis-vertical text-secondary fa-lg mx-3"></i>
                        </div>

                        <div className='pt-3'>
                            <Doughnut
                                data={doughnut_data}
                                options={
                                    {radius:'50%'}
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className='row d-flex justify-content-center align-items-center'>
                    <div className='col-11 p-2 mx-3 my-5' style={styles.panel}>

                        <div className={`w-100 d-flex justify-content-between align-items-center ${classes.border_bottom}`}>
                            <h5 className={`p-3 text-light `}>Students Map</h5>
                        </div>

                        {/**Map */}
                        <div>
                            <ComposableMap>

                                <Geographies geography={geoUrl}>
                                {({ geographies }) =>
                                geographies.map((geo) => (
                                    <Geography key={geo.rsmKey} geography={geo} fill='rgb(203,210,211)'/>
                                ))
                                }
                                </Geographies>
                                {markers.map(({ name, coordinates, markerOffset }) => (
                                    <Marker key={name} coordinates={coordinates}>
                                    <circle r={7} fill="#F00" stroke="#fff" strokeWidth={2} />
                                    
                                    </Marker>
                                ))}

                            </ComposableMap>
                        </div>
                    </div>
                </div>
            </div>



        </div>
  )
}


export default PageContent