import {createContext} from 'react';
import { useProvideSocket} from './ProvideSocket';
var initialState={
    courses:[],
    setCourses:()=>{}
}

export let SocketContext = createContext(initialState);

export function SocketProvider({children}){
    var Socket= useProvideSocket();
    return (
        <SocketContext.Provider value = {Socket}>
            {children}
        </SocketContext.Provider>
    )

}