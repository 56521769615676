import React, { useEffect, useState } from 'react';
import {useAuth} from '../../../providers/ProvideAuth'
import {Link} from 'react-router-dom';
import toast from 'react-hot-toast';
import { HiCog } from 'react-icons/hi';
const Reports = () => {
    const auth = useAuth();

    useEffect(()=>{
        var el = document.getElementById("wrapper");
        var toggleButton = document.getElementsByClassName("menu-toggle");
        for (var ele of toggleButton){
            ele.onclick = function () {
                el.classList.toggle("toggled");
            };
        }
      },[])

    const styles={
        image:{
            backgroundImage:`url(${auth.user.avatar})`
        }
    }
    return(
        <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                <div className="d-flex align-items-center">
                <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><HiCog size={40} className='text-light me-3'/></a>
                </div>
                <h2 className="m-0">Reports</h2>
                <Link className='no-decor' to='/student/profile'>
                    <div className='profile-icon'  style={styles.image}></div>
                </Link>
            </nav>
        </div>
    )
}

export default Reports