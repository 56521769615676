import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useStudent } from '../../../providers/ProvideStudent';
import Select from "react-select";
import classes from '../../../styles/Lecture.module.css';
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import dayjs from 'dayjs';
import { useSocket } from '../../../providers/ProvideSocket';
const Details = (props) => {
    const student = useStudent();
    let [submit,setSubmit] = useState(false);
    let [review,setReview] = useState(false);
    let [selectedType,setType] = useState({ value: 'pdf', label: 'PDF' });
    let [description,setDesc] = useState("");
    let [file_path,setSelectedFile] = useState("");
    let today = dayjs();
    let [submitting,setSubmitting] = useState(false);
    let {resource,reply,show,onHide,expiredModal,socketID} = props;
    let {newReply} = useSocket();
    const options = [
        { value: 'pdf', label: 'PDF' },
        { value: 'video', label: 'Video' },
        { value: 'image', label: 'Image' },
      ];
    useEffect(()=>{
        return ()=>{
            setSubmit(false);
            setReview(false);
        }
    },[])
    async function handleSubmit(e){
        e.preventDefault();
        if(!description){
            return toast.error('Please fill all fields')
        }
        if(!file_path){
            return toast.error('Please upload file first before hitting Submit')
        }
        setSubmitting(true);
        let form = new FormData()
        form.append('description',description);
        form.append('file',file_path);
        form.append('posting_day',today.subtract(4,'hour'));
        
        let result = await student.addReply(form,resource.id);
        if(result.success){
            setSubmit(false);
            setReview(true);
            newReply(socketID);

        }
        else{

            return toast.error(`${result.message}`);
        }

    } 
    
    function isEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
            return false;
        }
        return true;
      }
    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    };

    
    return(

        <Modal show={show} onHide={onHide} centered>
                {console.log(socketID,'jadu')}
                <Modal.Header closeButton>
                <Modal.Title>{resource.name}{expiredModal?<sup className={`rounded-pill border mx-1 px-2 ${classes.red}`}>EXPIRED</sup>:<></>}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4 d-flex flex-column'>
                    <div className='mb-3'>
                        <b>Description:</b> <i>{resource.description}</i>
                    </div>
                    <div className='my-2'>
                        <b>Status:</b> &nbsp;{isEmpty(reply)?<i>Not Submitted</i>:<i>Submitted</i>}
                    </div>
                    <div className='my-2'>
                        {!expiredModal ?
                        <>
                        {!isEmpty(reply)?
                        <button className='btn btn-primary' hidden={review} onClick={()=>setReview(true)}>Click for review</button>
                        :
                        <button className='btn btn-outline-success' hidden={submit} onClick={()=>setSubmit(true)}>Click to submit</button>}
                        </> 
                        :
                        <>
                        </>
                        }
                    </div>
                    
                    {review&&!isEmpty(reply)&&
                    <div className='m-auto border rounded p-2 d-flex flex-column justify-content-between' style={{width:'90%'}}>
                        <div className='m-auto my-2'>
                            <b>Your reply: &nbsp;</b>
                            {reply.Received_Resource?
                                <a target='_blank' href={reply.Received_Resource.file_path}><span>{reply.Received_Resource.name}</span></a>
                            :''}
                        </div>
                        <div className='m-auto my-2'>
                            <b>Posted: &nbsp;</b>{reply.Received_Resource?reply.Received_Resource.posting_day.slice(0,10):''}
                        </div>
                        
                        <div className='m-auto my-2'>
                            <b>Description: &nbsp;</b>{reply.Received_Resource?reply.Received_Resource.description:''}
                        </div>
                        <div className='m-auto my-2'>
                            <b>Review: &nbsp;</b><i>{reply.review?reply.review:'Pending Review'}</i>
                        </div><div className='m-auto my-2'>
                            <b>Score: &nbsp;</b><i>{reply.score?reply.score:'Pending Grading'}</i>
                        </div>
                    </div>}
                    {submit&&
                    <Form className='my-2 d-flex flex-column'>
                        
                        <div className='my-2'>
                            <Form.Group >
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={6} 
                                    maxLength={350}
                                    value={description}
                                    onChange={handleDescChange}
                                    required
                                />
                            </Form.Group>
                        </div>
                        <div className='m-2 my-4 d-inline-block '>
                            <Form.Label>Any additional files (supports .png,.jpeg,.pdf,.docx,.mp4)</Form.Label>
                              <Form.Control
                                  type='file'
                                  onChange={e=>setSelectedFile(e.target.files[0])}
                                  accept='.png,.jpg,.jpeg,.pdf,.mp4,.docx'
                              />
                        </div>
                        <div className='my-2 d-flex justify-content-center'>
                            <button type='submit' disabled={submitting} className='btn btn-success' onClick={handleSubmit}>{submitting?'Submitting':'Submit'}</button>
                        </div>
                    </Form>}

                    
                    
                </Modal.Body>
                <Modal.Footer className='border-0'>
                
                </Modal.Footer>
                
        </Modal>
    )
}

export default Details