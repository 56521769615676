import { Fade } from "react-awesome-reveal";
import classes from '../../styles/Testimonials.module.css'
import {Zoom} from 'react-awesome-reveal'
import { useTranslation } from "react-i18next";
function Testimonials(){
    let arr = [1,2,3,4]
    const {t,i18n} = useTranslation();
    function slideLeft(){
        var slider = document.getElementById('tslider');
        slider.scrollLeft = slider.scrollLeft-600
    }

    function slideRight(){
        var slider = document.getElementById('tslider');
        slider.scrollLeft = slider.scrollLeft+600

    }
    return(
        <div className='container my-5'>
            <Fade direction="up" triggerOnce>
                <h2 className='m-auto d-flex justify-content-center mb-4'>{t('HomePage.Testimonial.1')}</h2>
            </Fade>
            <div className='row mb-5'>
                <div className='col-2 col-md-3 col-lg-2 d-flex justify-content-center justify-content-md-end align-items-center'>
                    <div className={classes.slideLeft} onClick={slideLeft}>
                    </div>
                </div>
                <div className={`col-8 col-md-6 col-lg-8`}>
                    <div className={`row d-flex flex-nowrap ${classes.scroll}`} id='tslider'>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.winfei} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Winfei
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.8')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.ricarda} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Ricarda
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.2')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.jenny} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Jenny
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.3')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.rodrigo} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Rodrigo
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.4')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.isabella} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Isabella
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.5')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.senya} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Senya
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.10')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.balwinder} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Balwinder
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.6')}
                                </div>
                            
                        </div>
                        
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.neha} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Neha
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.7')}
                                </div>
                            
                        </div>
                        <div className='col-12 col-sm-9 col-md-9 col-xxl-5 my-4 d-flex justify-content-center flex-column align-items-center rounded me-5 p-4' style={{ backgroundColor:'rgb(243,240,227)'}}>
                            
                            
                                <div className={`${classes.arav} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Arav
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.9')}
                                </div>
                            
                        </div>
                        {/* <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.ricarda} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bolder'}}>
                                    Ricarda
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.2')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div>
                        <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.jenny} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bolder'}}>
                                    Jenny
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.3')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div>
                        <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.rodrigo} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bolder'}}>
                                    Rodrigo
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.4')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div>
                        <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.isabella} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bolder'}}>
                                    Isabella
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.5')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div>
                        <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.balwinder} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bolder'}}>
                                    Balwinder
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.6')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div>
                        <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.neha} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Neha
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.7')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div>
                        <div className='col-6 col-lg-5 my-4 d-flex justify-content-center'>
                            <Zoom direction='in' triggerOnce>
                            <div className={`rounded d-flex flex-column p-4 align-items-center`} style={{ backgroundColor:'rgb(243,240,227)'}}>
                                <div className={`${classes.arav} ${classes.bg}`}>

                                </div>
                                <div className="my-2" style={{fontWeight:'bold'}}>
                                    Arav
                                </div>
                                <div className='my-2' style={{fontWeight:'bold'}}>
                                    {t('HomePage.Testimonial.9')}
                                </div>
                            </div>
                            
                            </Zoom>
                        </div> */}
                    </div>
                </div>
                <div className='col-2 col-md-3 col-lg-2 d-flex justify-content-center  justify-content-md-start align-items-center'>
                            <div className={classes.slideRight} onClick={slideRight}>
                            </div>
                </div>
                
                
                
            </div>  
        </div>
    )
}
export default Testimonials;