import { useEffect,useState } from 'react';
import classes from '../../styles/Contact.module.css';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import {useTranslation} from 'react-i18next';

function ContactUs() {
    let [name,setName] = useState("");
    let [email,setEmail] = useState("");
    let [subject,setSubject] = useState("");
    let [message,setMessage] = useState("");
    let [validated, setValidated] = useState(false);
    let {t,i18n} = useTranslation();
    let [finishing,setFinishing] = useState(false)
    async function Submit(e) {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        }

        setValidated(true);
        if(!name||!email||!subject||!message){
            console.log('idhar aye the')
            return toast.error('Please check the form and ensure mandatory fields are filled');
        }
        setFinishing(true)   
        try{
            let reply = await fetch('https://scholarist.com:8000/api/customercontact',{
                method:'POST',
                headers:{
                    'Content-type':'application/json'
                },
                body:JSON.stringify({name,email,subject,message})
            });
            let response = await reply.json();
            console.log(response)
            if(response.success){
                setFinishing(false)
                return toast.success('Thanks for submitting! Check your inbox for follow-up');
            }
        }
        catch(err){
            setFinishing(false)
            console.log(err,'....in submitting contact form')
            return toast.error('Error in submitting form')
        }
    }
    const styles={
        color:{
            color:'rgb(74,74,74)'
        },
        grey:{
            color:'rgb(144,155,156)'
        }
    }
    return (
        <div className={`container-fluid py-5 px-lg-5 d-flex flex-column align-items-center ${classes.contactUsPage}`}>

                
                    <div className={`row text-light d-flex py-5 my-5 mx-lg-5 ${classes.bg_light}`}>
                        <div className='col-12 col-lg-6 d-flex align-items-center'>
                            <div className={classes.contactIcon}>

                            </div>
                        </div>
                        <div className='col-12 col-lg-6 d-flex py-4 flex-column'>
                            <h1 className={`text-center poppins ${classes.heading} my-5`}>{t('ContactUs.2')} !</h1>
                            <Form noValidate validated={validated} className='w-100 p-4'>
                                <div>
                                    <label for="nameInput" className="form-label">{t('ContactUs.3')} <sup className='text-danger'>*</sup></label>
                                    <input type="text" className="form-control bg-transparent text-light" id="nameInput" placeholder="John" required onChange={e=>setName(e.target.value)}/>
                                </div>
                                <div className='my-3'>
                                    <label for="emailInput" className="form-label">{t('ContactUs.4')} <sup className='text-danger'>*</sup></label>
                                    <input type="email" className="form-control bg-transparent text-light" id="emailInput" placeholder="abc@example.com" required onChange={e=>setEmail(e.target.value)}/>
                                </div>
                                <div className='my-3'>
                                    <label for="subjectInput" className="form-label">{t('ContactUs.5')} <sup className='text-danger'>*</sup></label>
                                    <input type="text" className="form-control bg-transparent text-light" id="subjectInput" placeholder='' required onChange={e=>setSubject(e.target.value)}/>
                                </div>
                                <div className='my-3'>
                                    <label for="messageInput" className="form-label">{t('ContactUs.6')} <sup className='text-danger'>*</sup></label>
                                    <textarea style={{height:'100px'}} type="text" className="form-control bg-transparent text-light" id="messageInput" placeholder='' required onChange={e=>setMessage(e.target.value)}/>
                                </div>
                                <div className='my-5 d-flex justify-content-center'>
                                    <button disabled={finishing} type='submit' className='btn btn-success' onClick={Submit}>{t('ContactUs.7')}</button>
                                </div>
                            </Form>
                        </div>
                    </div>
            
            </div>
    )
}

export default ContactUs