import Modal from 'react-bootstrap/Modal';
import classes from '../styles/Navbar.module.css';
import {useEffect, useState} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { useAuth } from '../providers/ProvideAuth';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';
import {LoginSocialFacebook} from 'reactjs-social-login';
import { useTranslation } from 'react-i18next';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import AppleSignin from 'react-apple-signin-auth';

function LoginPage(){

    const [email,setEmail] = useState("")
    const [pwd,setPWD] = useState("")
    const navigate=useNavigate();
    const auth = useAuth();
    const [validated, setValidated] = useState(false);
    let [finishing,setFinishing]=useState(false);
    let {t,i18n} = useTranslation();
    const [showSignUp, setShowSignup] = useState(false);
    const handleShowSignup = () => setShowSignup(true);
    const [showReset,setShowReset] = useState(false);
    const handleShowReset = () => setShowReset(true);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
        setFinishing(true)
        if(!email||!pwd){
            setFinishing(false)
            return toast.error('Please fill all details');
            
        }
        let res = await auth.login({email,password:pwd});
        if(res.success){
            if(res.role==2){
                toast(`Welcome ${res.name} to Scholarist. Happy teaching !!`,{
                    icon:'👨🏻‍💻'
                })
            }
            else if (res.role==3){
                toast(`Welcome ${res.name}. Best of luck for your learning goals !!`,{
                    icon:'🎓'
                })
            }
            setFinishing(false);
            return navigate('/user',{replace:true})
        }else{
            setFinishing(false)
            if(res.message){
                toast.error(`${res.message}`)
            }
            else{
                toast.error('Invalid credentials');
            }
            
        }
    };
    function handleThisClose(){
        setEmail('');
        setPWD('');
        setFinishing(false);

    }
    const googleLogin = useGoogleLogin({
        onSuccess: async({code}) => {
            let reply = await auth.googleLogin(code);
            if(reply.success){
                if(reply.role==2){
                    toast(`Welcome ${reply.name} to Scholarist. Happy teaching !!`,{
                        icon:'👨🏻‍💻'
                    })
                }
                else if (reply.role==3){
                    toast(`Welcome ${reply.name}. Best of luck for your learning goals !!`,{
                        icon:'🎓'
                    })
                }
                return navigate('/user',{replace:true})
            }else{
                toast.error('Invalid credentials');
            }
        },
        flow:'auth-code'
    })
    const handleFbLogin = async(res)=>{
        
        let reply = await auth.facebookLogin(res.data.accessToken);
        if(reply.success){
            if(reply.role==2){
                toast(`Welcome ${reply.name} to Scholarist. Happy teaching !!`,{
                    icon:'👨🏻‍💻'
                })
            }
            else if (reply.role==3){
                toast(`Welcome ${reply.name}. Best of luck for your learning goals !!`,{
                    icon:'🎓'
                })
            }
            return navigate('/user',{replace:true})
        }else{
            toast.error('Invalid credentials');
        }

    }

    const verifyBackEnd = async(res)=>{
        
        let reply = await auth.appleLogin(res);
        if(reply.success){
            await navigate('/user',{replace:true})
        }
        else{
            return toast.error('Error in logging in user')
        }
    }

    const authFailed = (err)=>{
        console.log(err)
        return toast.error('Error in logging in the user');
    }

    return(

        <>
            {auth.user?
            navigate('/user')   
            :
            <>
                <ForgotPassword show={showReset} onHide={()=>setShowReset(false)}/>
                <SignUp show={showSignUp}
                onHide={() => setShowSignup(false)}/>
                <div className={`container-fluid py-5 px-lg-5 d-flex flex-column align-items-center`}>
                    <h2>{t('Auth.1')}</h2>
                    <div className={`row w-100 text-light d-flex justify-content-center py-5 my-5 mx-lg-5`}>
                        <div className='col-12 col-lg-6 col-xxl-3 d-flex py-4 flex-column'>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} className='w-100 p-4'>
                                <Form.Group controlId="validationCustom01" >
                                    <Form.Label className='text-dark' style={{fontWeight:'bold'}}>{t('Auth.2')}</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="abc@example.com"
                                            onChange={(e)=>setEmail(e.target.value)}
                                        />
                                </Form.Group>
                                <Form.Group controlId="validationCustom02" className='my-3'>
                                    <Form.Label className='text-dark' style={{fontWeight:'bold'}}>{t('Auth.3')}</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        onChange={(e)=>setPWD(e.target.value)}
                                    />
                                </Form.Group>
                                <div className='my-4 w-100 text-primary d-flex flex-nowrap justify-content-end' >
                                    <h6 onClick={()=>{handleThisClose();handleShowReset();}} style={{cursor:'pointer'}}>{t('Auth.23')}</h6>
                                </div>
                                <div className='mt-4 text-center text-success' style={{fontWeight:'bold',cursor:'pointer'}} onClick={()=>{handleThisClose();handleShowSignup();}}>
                                    {t('Auth.22')}
                                </div>
                                <div className='my-4'>
                                        <button type='submit' disabled={finishing} className={`w-100 btn rounded-pill ${classes.submit}`}>
                                        {t('Auth.4')}</button>
                                    </div>

                                    <div className='my-2 d-flex justify-content-center align-items-center'>
                                        <div className={`${classes.line} mx-1`}></div>
                                        <div className='text-dark'>OR</div>
                                        <div className={`${classes.line} mx-1`}></div>
                                    </div>
                            </Form>

                            <div className='w-100 d-flex justify-content-center my-2' >
                                <div onClick={()=>googleLogin()} className={`rounded-pill border my-3 m-auto ${classes.googlepill}`}>
                                <div className={classes.iconGoogle}></div>{t('Auth.5')}</div>
                            </div>
                            <div className='w-100 d-flex justify-content-center' >
                                <LoginSocialFacebook
                                    isOnlyGetToken
                                    appId={process.env.REACT_APP_FB_ID}
                                    onResolve={(data)=>handleFbLogin(data)}
                                    onReject={(err) => {
                                        console.log(err)
                                    }}
                                    className='w-100 d-flex justify-content-center'
                                >
                                <div className={`rounded-pill border ${classes.bg_fb} ${classes.fbpill}`}>
                                <div className={classes.iconFacebook}></div>{t('Auth.6')}</div>
                                </LoginSocialFacebook>
                                
                            </div> 
                            <div className='w-100 d-flex justify-content-center my-2'>
                        {/* <div onClick={handleSignin} className={`rounded-pill border my-3 m-auto ${classes.applepill}`}>
                        <div className={classes.iconApple}></div>{t('Auth.29')}</div> */}
                        <AppleSignin

                            authOptions={{
                            clientId: 'com.scholarist.auth',       
                            scope: 'email name',                         
                            redirectURI: 'https://scholarist.com/login',
                            usePopup:true
                            }} 
                            uiType="dark"
                            className="apple-auth-btn"
                            noDefaultStyle={false}
                            buttonExtraChildren="Continue with Apple"
                            /** Extra controlling props */
                            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                            onSuccess={(response) => verifyBackEnd(response)} // default = undefined
                            /** Called upon signin error */
                            onError={(error) => authFailed(error)} // default = undefined
                            /** Skips loading the apple script if true */
                            skipScript={false} // default = undefined
                            /** Apple image props */
                            iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                            render={(props) => <div {...props} className={`rounded-pill border my-3 m-auto ${classes.applepill}`}>
                            <div className={classes.iconApple}></div>{t('Auth.29')}</div>}
                        />
                        
                    </div>
                        </div>
                    </div>
                </div>
                
            </>
            
            }
 
        </>
    )
}
export default LoginPage;