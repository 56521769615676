import React, {useEffect,useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import toast from 'react-hot-toast';
import { TokenKey } from '../../../utils';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import classes from '../../../styles/PageContent.module.css';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { FaCaretLeft, FaCaretRight, FaForward, FaBackward } from 'react-icons/fa';
import {HiBadgeCheck} from 'react-icons/hi';
import {MdSort} from 'react-icons/md';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

let transactions = [{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Pending', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Pending', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Completed', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Completed', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Pending', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Pending', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Pending', subscription:'N/A - One Time',amount:657},
{id:'EKG464SJFN17',invoice_date:'15 Dec, 2017', date_paid:'15 Dec, 2017', status:'Pending', subscription:'N/A - One Time',amount:657}]


const Paginate = ({ transactionsPerPage, totalTransactions, paginate, currentPage}) => {

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalTransactions / transactionsPerPage); i++) {
        pageNumbers.push(i);
    }

    return(
        <div className='d-flex align-items-center justify-content-center my-2'>
            {pageNumbers.map(i=>
                <button onClick={()=>paginate(i)} className='btn mx-2 rounded' style={{color:currentPage==i?'white':'rgb(66,144,230)', backgroundColor:currentPage==i?'rgb(66,144,230)':'white'}}>
                    {i}
                </button>)}
        </div>

    )
}


const Students = () => {

    const auth = useAuth();
    let {t,i18n} = useTranslation();
    const [searchResults,setSearchResults] = useState(null);
    let [students, setStudents] = useState([1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10]);
    let [student,setStudent] = useState({name:'Dummy Student', email:'ankush@ankush.com', balance: 0, promo:'N/A', newsletter:false, phone:'+1 416-(876)5678', language_name:'Hindi', country:'🇺🇦 Ukraine', courses:[{id:36, name:'English for Beginners', description:'Product Management Masterclass, you will learn with Sarah Johnson - Head of Product Customer Platform Gojek Indonesia.', avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/IELTS_Logo.jpeg'},{id:36, name:'English for Beginners', description:'Product Management Masterclass, you will learn with Sarah Johnson - Head of Product Customer Platform Gojek Indonesia.', avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/IELTS_Logo.jpeg'},{id:36, name:'English for Beginners', description:'Product Management Masterclass, you will learn with Sarah Johnson - Head of Product Customer Platform Gojek Indonesia.', avatar:'https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/IELTS_Logo.jpeg'}]})
    const navigate = useNavigate();
    let [currentPage, setCurrentPage] = useState(1);
    let [transactionsPerPage] = useState(4);
    

    let indexOfLastPost = currentPage * transactionsPerPage;
    let indexOfFirstPost = indexOfLastPost - transactionsPerPage;
    let currentTransactions = transactions.slice(indexOfFirstPost, indexOfLastPost);

    useEffect(()=>{
        indexOfLastPost = currentPage * transactionsPerPage;
        indexOfFirstPost = indexOfLastPost - transactionsPerPage;
        currentTransactions = transactions.slice(indexOfFirstPost, indexOfLastPost);
    },[currentPage])
    
    
   
    function slideLeft(){
        var slider = document.getElementById('admin_student_slider');
        slider.scrollLeft = slider.scrollLeft-300

    }
    function slideRight(){
        var slider = document.getElementById('admin_student_slider');
        slider.scrollLeft = slider.scrollLeft+300

    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    

    const handleSearch = async (e)=>{
        
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
        setSearchResults(['Add more data to website from teachers to start searching']);
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    
  return (
    <div id="page-content-wrapper">
            
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-0 px-md-4 text-light d-flex flex-nowrap justify-content-between">
                <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    <h2 className={`mb-1 poppins`}><b style={{fontWeight:'bolder'}}>Students</b></h2>
                </div>
                <div className='w-25 d-none d-lg-inline-block' style={{position:'relative'}}>
                    <Form.Control
                        type="text"
                        placeholder={`${t('PageContent.6')}...`}
                        className='rounded px-4 py-2'
                        style={{backgroundColor:'rgb(0,0,0)',border:0}}
                        onChange={handleSearch} 
                    />
                    <div className={`w-100 rounded ${searchResults? classes.searchDropdown:classes.searchDropdownInvisible} mb-3`}>
                        <div className='row'>
                            <div className='col-11 d-flex justify-content-end p-1 py-2' style={styles.label} >
                                <CloseButton className='text-light' onClick={()=>setSearchResults(null)} />
                            </div>
                        </div>
                                        
                        {searchResults?<>{searchResults.length>0?searchResults.map((item)=>
                            <>
                                        
                                <div className={`row ${classes.searchBarResults} d-flex flex-column justify-content-center align-items-center`}>
                                    <div className="col-8 ">
                                        <div style={styles.label}><i>{item}</i></div>
                                    </div>
                                </div>
                            </>
                                            
                            ):
                                <>
                                    <div className='row text-dark'>
                                        <div className=' my-2 col-12 d-flex justify-content-center'>
                                            <i>Nothing to report</i>  
                                        </div>
                                                
                                    </div>
                                </>}</>
                            :
                            <></>
                         }
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div style={styles.mail} className='mx-2'>
                        <i className="text-light fa-regular fa-envelope"></i>
                    </div>
                    <div style={styles.mail} className='mx-2'>
                        <i className="text-light fa-regular fa-bell"></i>
                    </div>
                    
                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                       <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </div>
            </nav>

            <div className='container-fluid'>
                {/**For Masonry Layouts */}
                <div className='row my-5 py-3' data-masonry={{percentPosition: true }}>

                    {/**Student Search Column */}
                    <div className='col-2'>

                        <div className='card rounded p-3'  style={{backgroundColor:'rgb(33, 41, 54)'}}>
                            <Form.Control
                                type="text"
                                placeholder={`${t('PageContent.6')}...`}
                                className='rounded px-2 py-1'
                                style={{backgroundColor:'rgb(0,0,0)',border:0}}
                                onChange={handleSearch} 
                            />
                            <div className='rounded-pill p-2 d-inline-block text-light my-3' style={{backgroundColor:'rgb(0,0,0)', fontSize:'0.8em'}}> 
                                <i class="fa-solid fa-filter"></i> Filter
                            </div>

                            <div className={classes.scrollY}>
                                {students.map(i=>
                                
                                <div className='rounded w-100 d-flex my-3 py-1 align-items-center justify-content-center'>
                                    <div className={classes.list_icon} style={styles.image}>
                                    </div>
                                    <div className='mx-3' style={{color:'white',fontWeight:'bold',fontSize:'0.8em'}}>Dummy Student</div>
                                </div>)}
                            </div>
                        
                        </div>

                    </div>

                    {/**Remaining Sections */}
                    <div className='offset-1 col-9'>
                        <div className='row' data-masonry={{percentPosition: true }}>

                            {/**Card used for masonry, photo section */}
                            <div className='col-5 rounded'>
                                <div className='card w-100 p-3'  style={{backgroundColor:'rgb(33, 41, 54)'}}>
                                    <div className='w-100 d-flex justify-content-end'>
                                        <button className='btn btn-danger rounded-pill' style={{fontWeight:'bold',fontSize:'0.8em'}}>Delete Account</button>
                                    </div>

                                    <div className='w-100 my-3 d-flex justify-content-center'>
                                        <div className={classes.student_icon} style={styles.image}>

                                        </div>
                                    </div>  

                                    <h3 className='text-center' style={{color:'white'}}>Dummy Student</h3>

                                </div>
                            </div>

                            {/**Card used for masonry, contact info section */}
                            <div className='col-7 rounded'>
                                <div className='card w-100 p-3' style={{backgroundColor:'rgb(33, 41, 54)'}}>
                                    <h5 className={`${classes.border_bottom} py-2`} style={{color:'white'}}><b>Contact Info</b></h5>
                                    <div className='w-100 row mt-3'>
                                        <div className='col-6'>
                                                <Form.Label style={{color:'white'}}>Email address</Form.Label>
                                                <Form.Control type="email"  style={{fontSize:'0.8em',backgroundColor:'transparent'}} disabled placeholder={student.email} />
                                        </div>
                                        <div className='col-6'>
                                                <Form.Label style={{color:'white'}}>Phone</Form.Label>
                                                <Form.Control type="email"  style={{fontSize:'0.8em',backgroundColor:'transparent'}} disabled placeholder={student.phone} />
                                        </div>
                                        <div className='col-6 my-3'>
                                                <Form.Label style={{color:'white'}}>Country</Form.Label>
                                                <Form.Control type="email"  style={{fontSize:'0.8em',backgroundColor:'transparent'}} disabled placeholder={student.country} />
                                        </div>
                                        <div className='col-6 my-3'>
                                                <Form.Label style={{color:'white'}}>Mother tongue</Form.Label>
                                                <Form.Control type="email"  style={{fontSize:'0.8em',backgroundColor:'transparent'}} disabled placeholder={student.language_name} />
                                        </div>
                                        <div className='col-6 my-3'>
                                                <Form.Label style={{color:'white'}}>Newsletter</Form.Label>
                                                <Form.Control type="email"  style={{fontSize:'0.8em',backgroundColor:'transparent'}} disabled placeholder={student.newsletter?'True':'False'} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {/**Courses Section */}
                            <div className='col-12 rounded p-3 my-5' style={{backgroundColor:'rgb(33, 41, 54)'}}>
                                <h5 className={`${classes.border_bottom} py-2`} style={{color:'white'}}><b>Courses</b></h5>
                                <div className='row my-5'>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                        <div className={classes.slideLeft} onClick={slideLeft}>
                                        </div>
                                    </div>
                                    <div className={`col-8 col-md-6 col-lg-8 col-xxl-10 d-flex justify-content-center align-items-center`}>
                                        {student.courses.length>0 ? 
                                        <div className={`row d-flex flex-nowrap ${classes.scroll} align-items-stretch`} id='admin_student_slider'>
                                            {student.courses.map((item,index)=>
                                            <div className='col-lg-12 col-xxl-5 my-4 d-flex flex-column align-items-center mx-3 p-4' style={{backgroundColor:'rgb(41,45,54)',borderRadius:20}} key={index}>
                                                    <div className={`${classes.bg_course}`} style={{backgroundImage:`url(${item.avatar})`}}>

                                                    </div>
                                                    <div className='d-flex w-100 flex-column align-items-center ' style={{flexGrow:1}}>
                                                        <div className='mt-4 text-center' style={{fontWeight:'bold',color:'rgb(212,93,46)'}}>
                                                            {item.name}
                                                        </div>
                                                        <div className='my-4 text-center' style={{color:'white'}}>
                                                            {item.description}
                                                        </div>
                                                        
                                                    </div>
                                                    <div className='d-flex w-100 justify-content-end' >
                                                            <Link to={`/student/course/info/${item.id}`} className='no-decor'><button className={`btn text-light ${classes.button_purple}`}>View More</button></Link>
                                                    </div>
                                            </div>
                                            )}
                                        </div>
                                        :
                                        <div className='w-100 text-center text-light'>
                                            <i>{t('Courses_2.10')}</i>
                                        </div>}
                                        
                                    </div>
                                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center align-items-center'>
                                            <div className={classes.slideRight} onClick={slideRight}>
                                            </div>
                                    </div>
                                </div>  
                            </div>

                            {/**Payment History Section */}
                            <div className='col-12 rounded p-3' style={{backgroundColor:'rgb(33, 41, 54)'}}>
                                <h5 className={`py-2`} style={{color:'white'}}><b>Payment History</b></h5>
                                <div className={`row d-flex flex-nowrap p-2 border-bottom ${classes.scroll}`} style={{backgroundColor:'rgb(181,184,189)', color:'black'}}>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        ID No.
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        Invoice Date
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        Date Paid
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        Status
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        Subscription
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        Amount
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        Actions
                                    </div>
                                    
                                </div>

                                {currentTransactions.map(i=>
                                    <div className={`row d-flex flex-nowrap p-2 py-3 poppins border-bottom ${classes.scroll}`} style={{color:'white'}}>
                                        <div className='col-2  d-flex justify-content-center align-items-center'>
                                            <div>{i.id}</div>
                                        </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        <div>{i.invoice_date}</div>
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        <div>{i.date_paid}</div>
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        <button className='btn rounded-pill btn-light' style={{color:i.status=='Completed'?'rgb(96,198,82)':'rgb(221,75,58)', fontWeight:'bold'}}>
                                            {i.status}
                                        </button>
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        <div>{i.subscription}</div>
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        <div>$ {i.amount}</div>
                                    </div>
                                    <div className='col-2 d-flex justify-content-center align-items-center'>
                                        <i className="fa-solid fa-ellipsis-vertical mx-3" style={{color:'white', cursor:'pointer'}}></i>
                                    </div>
                                </div>)}

                                <div className='row d-flex align-items-center justify-content-center my-2'>
                                        <Paginate transactionsPerPage={transactionsPerPage} totalTransactions={transactions.length} paginate={paginate} currentPage={currentPage}/>
                                </div>

                            </div>

                            {/**Task Performance */}
                            <div className='col-5 rounded my-5'>
                                <div className='card w-100 p-3 d-flex align-items-center'  style={{backgroundColor:'rgb(33, 41, 54)'}}>
                                    <div className={`d-flex justify-content-between align-items-center py-2 w-100 ${classes.border_bottom}`}>
                                        <h5 style={{color:'white'}}><b>Tasks Performance</b></h5>
                                        <i className="fa-solid fa-ellipsis-vertical mx-3" style={{color:'white', cursor:'pointer'}}></i>
                                    </div>
                                    
                                    <div className='w-75 my-5'>
                                        <div className='w-100 rounded-pill' style={{height:'1rem', backgroundColor:'rgb(0,0,0)'}}>
                                            <div className='bg-success rounded-pill' style={{width:'76%', height:'100%'}}></div>
                                        </div>
                                        <div className='w-100 my-3 rounded-pill' style={{height:'1rem', backgroundColor:'rgb(0,0,0)'}}>
                                            <div className='bg-warning rounded-pill' style={{width:'46%', height:'100%'}}></div>
                                        </div>
                                        <div className='w-100 rounded-pill' style={{height:'1rem', backgroundColor:'rgb(0,0,0)'}}>
                                            <div className='bg-danger rounded-pill' style={{width:'16%', height:'100%'}}></div>
                                        </div>
                                    </div>

                                    <div className='w-100 row mt-5'>
                                        <div className='col-4 d-flex flex-column' style={{color:'white'}}>
                                            <i className="d-block fa-lg text-success fa-regular fa-circle-check"></i>
                                            <h5 className='mt-4'><b>76%</b></h5>
                                            <div>Completed</div>
                                        </div>
                                        <div className='col-4 d-flex flex-column' style={{color:'white'}}>
                                            <i className="d-block fa-lg text-warning fa-solid fa-arrow-trend-down"></i>
                                            <h5 className='mt-4'><b>46%</b></h5>
                                            <div>Completed</div>
                                        </div>
                                        <div className='col-4 d-flex flex-column' style={{color:'white'}}>
                                            <i className="d-block fa-lg text-danger fa-solid fa-arrow-trend-up"></i>
                                            <h5 className='mt-4'><b>16%</b></h5>
                                            <div>Completed</div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    

                </div>
            </div>

        </div>
  )
}

const styles={
    mail:{
        height:'2.5rem',
        width:'2.5rem',
        borderRadius:'1.25rem',
        backgroundColor:'rgb(0,0,0)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    clock:{
        fontFamily:"DS-Digital, sans-serif;"
    },
    monospace:{
        fontFamily:'monospace',
        color:'rgb(228,138,105)'
    },
    slightwhite:{
        color:'rgba(255,255,255,0.56)'
    },
    label:{
        fontWeight:'bold',
    },
    image:{
        backgroundImage:'url(https://upload.wikimedia.org/wikipedia/commons/f/f1/Andrew_Tate_on_%27Anything_Goes_With_James_English%27_in_2021.jpg)',
        backgroundSize:'cover'
    }
}
export default Students