import React, { useEffect, useState } from 'react';
import {useAuth} from '../../../providers/ProvideAuth'
import {Link, useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-date-picker';
import Select from "react-select";
import classes from '../../../styles/Notifications.module.css';
import { toast } from 'react-hot-toast';
import { getItemFromLocalStorage,TokenKey } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { MdSort } from 'react-icons/md';
const Notifications = () => {

    const auth = useAuth();
    let navigate = useNavigate()
    let {t,i18n} = useTranslation();
    const [period,setSelectedPeriod] = useState({value:'90',label:`🗓️  ${t('Notifications.7')}`});
    const options = [
        {value:'30',label:`🗓️  ${t('Notifications.5')}`},
        {value:'60',label:`🗓️  ${t('Notifications.6')}`},
        {value:'90',label:`🗓️  ${t('Notifications.7')}`}
    ]
    
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/notifications?days=30&lang=${i18n.language}`,{
                        method:'GET',
                        headers:{
                            'Content-Type':'application/json',
                            'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                        }
                    });
                    let response = await reply.json();
                    await auth.setNotifications(response.data)
                }catch(err){
                    console.log(err);
                    return toast.error('Error in fetching notifications')
                }
            }
        )();
    },[]);

    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/notifications?days=${period.value}&lang=${i18n.language}`,{
                        method:'GET',
                        headers:{
                            'Content-Type':'application/json',
                            'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                        }
                    });
                    let response = await reply.json();
                    await auth.setNotifications(response.data)
                }catch(err){
                    console.log(err);
                    return toast.error('Error in fetching notifications')
                }
            }
        )();
    },[period])

    const styles={
        image:{
            backgroundImage:`url(${auth.user.avatar})`
        }
    }
    return(
        <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                <div className="d-flex align-items-center">
                <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-3'/></a>
                </div>
                <h2 className="m-0">{t('Notifications.1')}</h2>
                <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
                    <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                </div>
            </nav>

            <div className='container-fluid px-4'>
                <div className='row d-flex justify-content-center my-3'>
                    <div className='col-12 my-3'>
                        <div className='w-100 bg-orange d-flex flex-column rounded align-items-start text-white p-2 px-5'>
                            <h3 className='my-3'><i className='fa-solid fa-circle-exclamation me-4'></i>{t('Notifications.2')}</h3>
                            <p>{t('Notifications.3')}</p>
                        </div>
                    </div>

                    <div className='col-10 my-3 d-flex justify-content-center'>
                        <Form.Group className={classes.w_50}>
                            <Select
                                options={options}
                                onChange={(option) => setSelectedPeriod(option)}
                                required
                                defaultValue={{value:'30', label:`🗓️  ${t('Notifications.5')}`}}     
                            />
                        </Form.Group>
                    </div>

                    <div className='col-11 my-5 text-white'>
                        <div className={` my-4 ${classes.scrollList} d-flex flex-column align-items-center`}>
                            {!auth.notifications.length && <b><i>{t('Notifications.4')}</i></b>}
                            {auth.notifications.map(notif=>
                                    <div className={`${classes.w_50} d-flex flex-column p-2 p-lg-3 my-3 bg-white rounded text-dark`} key={notif.id}>
                                       <div><b>{notif.description}</b></div>
                                       <div className='my-2 text-secondary'><i>@  {notif.posting_day.slice(0,10)} ({notif.posting_time})</i></div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Notifications