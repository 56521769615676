import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import { TokenKey } from '../../../utils';
const Details = (props) => {
    let [desc,setDesc] = useState("")
    let [review,setReview] = useState(false);
    let [submitting,setSubmitting] = useState(false);
    let [score,setScore] = useState(0);
    let {show,setShowDetails,setResource,setReply,resource,reply} = props;
    

    async function handleSubmit(e){
        e.preventDefault();
        if(!desc){
            return toast.error('Please write review first')
        }
        setSubmitting(true)
        try{
            let res = await fetch(`https://scholarist.com:8000/api/teachers/review?id=${reply.id}`,{
                method:'POST',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body: JSON.stringify({desc,score})
            })
            let response = await res.json();
            setReply(response.data)
            setSubmitting(false);
            setReview(false);
        }catch(err){
            console.log(err);
            setSubmitting(false);
            return toast.error('Error in submitting review')
        }
        setSubmitting(false);
    }
    function isEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
            return false;
        }
        return true;
      }
    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    };
    function onHide(){
        setShowDetails(false);
        setResource();
        setReply();
        setSubmitting(false);
        setReview(false);
   
    }
    return (

        <Modal show={show} onHide={onHide} centered>
     
                <Modal.Header closeButton>
                <Modal.Title>{resource?resource.name:'Loading'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-4 px-3 d-flex flex-column align-items-start'>
                    {!isEmpty(resource)&&
                    <>
                    <div className='my-3'>
                        <b>File: &nbsp;</b><a target='_blank' href={resource.file_path}><i>Click here</i></a>
                    </div>
                    <div className='my-3'>
                        <b>Posted: &nbsp;</b><span>{resource.posting_day.slice(0,10)}</span>
                    </div>
                    <div className='my-3'>
                        <b>Status: &nbsp;</b>{reply.review?'Reviewed':'Pending Review'}
                    </div>
                    <div className='my-3'>
                        {reply.review&&
                        <div className=' d-flex flex-column'>
                            <b className='mb-3 d-inline-block'>Review: &nbsp;</b>{reply.review}
                            <b className='d-inline-block'>Score: &nbsp;</b>{reply.score}
                        </div>}
                        {!reply.review&&<button className='btn btn-success' hidden={review} onClick={()=>setReview(true)}>Click to review</button>}
                    </div>
                   
                        {review&&
                        <Form className='d-flex flex-column w-100'>
                        
                        <div className='my-2'>
                            <Form.Group >
                                <Form.Label><b>Review:</b></Form.Label>
                                <Form.Control as="textarea" rows={6} 
                                    maxLength={350}
                                    value={desc}
                                    onChange={handleDescChange}
                                    required
                                />
                            </Form.Group>
                        </div>

                        <div className='my-2'>
                            <Form.Group >
                                <Form.Label><b>Score (out of 10):</b></Form.Label>
                                <Form.Control 
                                    value={score}
                                    onChange={e=>setScore(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </div>
                        
                        <div className='my-2 d-flex justify-content-center'>
                            <button type='submit' disabled={submitting} className='btn btn-success' onClick={handleSubmit}>{submitting?'Submitting':'Submit'}</button>
                        </div>
                    </Form>}
                    
                    </>
                }
                    
                </Modal.Body>
                <Modal.Footer className='border-0'>
                
                </Modal.Footer>
                
        </Modal>
    )
}

export default Details