import { StudentContext } from "./StudentProvider";
import { useContext, useEffect, useState } from "react";
import { deleteItemFromLocalStorage, getItemFromLocalStorage, setItemInLocalStorage, TokenKey } from "../utils";
import { useAuth } from "./ProvideAuth";

export function useStudent(){
    return useContext(StudentContext)
}
export function useProvideStudent(){
    
    var [courses,setCourses] = useState([]);
    let baseURL = 'https://scholarist.com:8000/api/students/';

    async function getResources(courseId,teacher){
        try{
            let reply = await fetch(baseURL+`fetch/resources/?course=${courseId}&name=${teacher}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data};
            }
            
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function fetchResource(id){
        try{
            let reply = await fetch(baseURL+`fetch/resource?id=${id}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
           
            if(response.success){
                return {success:true,data:response.data,reply:response.reply};
            }

        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function addReply(submission,id){
        try{
            let reply = await fetch(baseURL+`reply/resource?id=${id}`,{
                method:'POST',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body:submission
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data};
            }
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function getGrades(course,teacherId){
        try{
            let reply = await fetch(baseURL+`fetch/grades?course=${course}&teacher=${teacherId}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            if(response.success){
                return {success:true,data:response.data};
            }
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function getTeacher(id,lang){
        try{
            let reply = await fetch(baseURL+`teacher/${id}?lang=${lang}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            return {success:true,data:response.data}
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function getReview(course){
        try{
            let reply = await fetch(baseURL+`fetch/review/${course}`,{
                method:'GET',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            });
            let response = await reply.json();
            console.log('provider mein,\...\n',response.data)
            return {success:true,data:response.data}
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }

    async function addReview(course,body){
        try{
            let reply = await fetch(baseURL+`add/review/${course}`,{
                method:'POST',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body:JSON.stringify(body)
            });
            let response = await reply.json();
            return {success:true,data:response.data}
        }catch(err){
            console.log(err);
            return {success:false};
        }
    }
    return ({
        courses,
        setCourses,
        getResources,
        fetchResource,
        addReply,
        getGrades,
        getTeacher,
        getReview,
        addReview
    })
}
