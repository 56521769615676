import React,{useEffect,useState} from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../../providers/ProvideAuth';
import {useTeacher} from '../../../providers/ProvideTeacher';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import classes from '../../../styles/NewCourse.module.css'
import Slide1 from './Slides/Slide1';
import Slide2 from './Slides/Slide2';
import Slide3 from './Slides/Slide3';
import Slide4 from './Slides/Slide4';
import Slide5 from './Slides/Slide5';
import { DateObject } from 'react-multi-date-picker';
const NewCourseModal = (props)=>{
    let {t,i18n} = useTranslation();
    let [slide,setSlide] = useState(0)
    let [name,setName] = useState("");
    let [description,setDesc] = useState("");
    let[courseType,setCourseType] = useState('per-package')
    let [price,setPrice] = useState("0");
    let [startDay,setStartDay] = useState(dayjs());
    let [endDay,setEndDay] = useState(dayjs());
    let [daysOfPackage,setDays] = useState([new DateObject()])
    let [startTime,setStartTime] = useState(dayjs());
    let [endTime,setEndTime] = useState(dayjs());
    let [selectedLanguage,setSelectedLanguage] = useState({ value: 'english', label: 'English' });
    let [events,setEvents]=useState([]);
    let [selectedFile, setSelectedFile] = useState(null);
    let [finishing,setFinishing] = useState(false)
    const teacher = useTeacher();
    const auth = useAuth();
    const navigate=useNavigate();
    let {open,onClose} = props;

    const options = [
        { value: 'english', label: 'English' , code:'en' },
        { value: 'hindi', label: 'Hindi', code:'hi' },
        { value: 'chinese', label: 'Chinese', code:'ch' },
        { value: 'russian', label: 'Russian' , code:'ru'},
        { value: 'spanish', label: 'Spanish', code:'es' },
        { value: 'french', label: 'French' , code:'fr'}
      ];
  
    async function handleSubmit(e){
        let formData = new FormData()
        e.preventDefault();
        setFinishing(true);
        let regex1=/^[0-9]*[.][0-9]+$/;
        let regex2 = /^[0-9]+$/;
        if(courseType=='per-package'){
            if(!(price.match(regex2)||price.match(regex1))){
                return toast.error('Please fill all the fields in the wizard')
            }
            let start_time = startTime.format('HH:mm')+' EDT';
            let end_time = endTime.format('HH:mm')+' EDT';
            let start_day = startDay.format('DD/MM/YYYY');
            let end_day = endDay.format('DD/MM/YYYY');
            formData.append('file',selectedFile)
            formData.append('name',name)
            formData.append('subject',selectedLanguage.value)
            formData.append('description',description)
            formData.append('price',price)
            formData.append('start_day',start_day)
            formData.append('end_day',end_day)
            formData.append('start_time',start_time)
            formData.append('end_time',end_time)
            formData.append('day_of_week',daysOfPackage)
            let {success,message} = await teacher.addCourse(formData)
            if(!success){
                setFinishing(false)
                return toast.error(`Error in adding a course: ${message}`);
            }
        }else {
            formData.append('file',selectedFile)
            formData.append('name',name)
            formData.append('description',description)
            formData.append('price',price)
            formData.append('selectedLanguage',selectedLanguage.value)
            formData.append('events',JSON.stringify(events))
            let {success,message} = await teacher.addAvailability(formData)
            if(!success){
                setFinishing(false)
                return toast.error(`Error in adding a course: ${message}`);
            }
        }
        setFinishing(false);
        handleClose();
        toast.success('A new course has been added')
        navigate('/teacher')
    }
    
    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    }
    function handleBack(){
        const currValue = slide;
        setSlide(currValue-1);
    }
    function handleNext(){
        let canWeMove=true
        switch(slide){
            case 0:
                if(!name||!description||!selectedLanguage){
                    canWeMove=false
                }
                break;
            case 1:
                if(!courseType){
                    canWeMove=false
                }
                break;
            case 2:
                if(courseType=='per-package'){
                    if(!startDay||!startTime||!endDay||!endTime||!daysOfPackage){
                        canWeMove=false
                    }
                }
                else{
                    if(events.length==0){
                        canWeMove=false
                    }
                    
                }
                break;
        }
        if(canWeMove){
            const currValue = slide;
            setSlide(currValue+1);
        }
        else{
            return toast.error('Please fill all the details')
        }
        
    }
    function handleClose(){
        setName('');
        setDesc('');
        setSlide(0);
        setCourseType('per-package');
        setDays([]);
        setEvents([]);
        setPrice(0);
        setStartDay(dayjs());
        setEndDay(dayjs());
        setFinishing(false)
        onClose();
    }
    const styles={
       
        heading:{
            fontWeight:'bolder'
        },
        neon:{
            color:'rgb(144,251,220)!important',
            borderColor:'rgb(144,251,220)!important'
        },
        box:{
            
            maxHeight:'100%',
            width: {
                xs: '100%', // theme.breakpoints.up('xs')
                md:'90%',
                lg: 500, // theme.breakpoints.up('lg')
                xl: 600, // theme.breakpoints.up('xl')
              },
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius:'5px',
            overflowY:'scroll'
        },
        blackBox:{
            maxHeight:'100%',
            width: {
                xs: '100%', // theme.breakpoints.up('xs')
                md:'90%',
                lg: 500, // theme.breakpoints.up('lg')
                xl: 600, // theme.breakpoints.up('xl')
              },
            bgcolor: 'rgb(40,40,40)',
            border: '2px solid #000',
            boxShadow: 24,
            color:'white',
            p: 4,
            borderRadius:'5px',
            overflowY:'scroll'
        }
    }
    return (
        <Modal open={open} onClose={handleClose} sx={{overflowY:'scroll',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Box sx={(slide==2 && courseType!='per-package') ? styles.blackBox: styles.box}>
                <div className={`d-flex justify-content-between`}>
                    <h4 style={styles.heading}>{t('AddCourse.1')}</h4>
                    <ClearIcon style={{cursor:'pointer'}} onClick={handleClose}/>
                </div>
                
                <div className='py-4 d-flex flex-column'>
                    {slide==0 &&
                    <Slide1 name={name} setName={setName} description={description} handleDescChange={handleDescChange} selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} options={options}></Slide1>}
                    {slide==1 &&
                    <Slide2 courseType={courseType} setCourseType={setCourseType}/>}
                    {slide==2 &&
                    <>
                        {courseType=='per-package'?
                            <Slide3 startDay={startDay} setStartDay={setStartDay} startTime={startTime} setStartTime={setStartTime} endDay={endDay}setEndDay={setEndDay} endTime={endTime}setEndTime={setEndTime} daysOfPackage={daysOfPackage} setDays={setDays}/>
                        :
                            <Slide4 events={events} setEvents={setEvents}/>}
                    </>
                    }
                    {slide==3 && 
                    <Slide5 courseType={courseType} price={price} setPrice={setPrice} setSelectedFile={setSelectedFile}/>} 
                </div>
                <div className='d-flex justify-content-center'>
                    <button onClick={handleBack} className={`btn btn-primary mx-3`} disabled={slide==0}><b>Back</b></button>
                    {slide==3?
                    <button className='btn btn-success mx-3'  onClick={handleSubmit} disabled={finishing}><b>Finish</b></button>
                    :
                    <button className='btn btn-success mx-3' onClick={handleNext}><b>Next</b></button>}
                </div>
          
            </Box> 
        </Modal>
    )
}

export default NewCourseModal;