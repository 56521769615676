import React,{useState} from 'react';
import { Scheduler } from "@aldabil/react-scheduler";

function Slide4(props){

   let {events,setEvents} = props
   const handleConfirm=async (event,action)=>{
    let returnedEvent;
    
    if (action === "edit") {
      //match with id and then edit the info here as well as server
    } else if (action === "create") {
      returnedEvent = {
        ...event,
        event_id: Math.random()
      };
      console.log(events,'....khiji khiji')
      /** await addEventOnServer() */
      let prevValues=events;
      setEvents(prevValue=>[...prevValue,returnedEvent]);
    }

    return returnedEvent;
        
   }
    return (
        <div className='w-100 d-flex flex-column align-items-center'>
          {console.log(events,'....vfd')}
            <div className='row w-100 d-flex py-3 justify-content-between'>
                <div className='col-12 my-2'>
                    <Scheduler
                    view='day'
                    day={
                      {startHour:0,
                      endHour: 24, 
                      step: 60,
                      navigation: true}
                    } 
                    onConfirm={handleConfirm}
                    />
                </div>
                <div className='col-12 my-2'>
                  <b className='text-danger'>Note: Before moving ahead, please keep in mind that these start and end dates/times are according to Scholarist time i.e. Toronto timezone. For putting dates/times according to your own timezone, kindly convert and then select accordingly</b>
                </div>
            </div>
        </div>
    )
}

export default Slide4;