import React from 'react';
import { useState } from 'react';
import MyChat from "./MyChat";
import ChatBox from "./ChatBox";
import { useAuth } from '../../../providers/ProvideAuth';


const Chat = () => {
  const {selectedChat,setSelectedChat} = useAuth();
  const [fetch,setFetch] = useState(false)
  
  return (
    <>
      <div className="container-fluid" style={{maxHeight:'100vh'}}>
        <div className="row h-100" >

          {selectedChat?
            <>
              <div className="col-12 col-lg-4 d-none d-lg-inline-block bg-white h-100">
                  <MyChat fetch={fetch}/>
              </div>

              <div  className="col-12 col-lg-8 h-100 px-0">
                  <ChatBox fetch={fetch} setFetch={setFetch}/>
              </div>

              </>:
                <>
                  <div className="col-12 col-lg-4 bg-white h-100">
                  <MyChat fetch={fetch} />
                </div>

                <div  className="col-12 col-lg-8 d-none d-lg-block px-0">
                    <ChatBox fetch={fetch} setFetch={setFetch}/>
                </div>
            </>
          }
                
        </div>

      </div>
    </>
  )
}

export default Chat