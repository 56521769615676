import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
const Slide1 = (props) => {
    let {t,i18n} = useTranslation();
    let {name,setName,selectedLanguage,setSelectedLanguage,description,handleDescChange,options} = props
  return (
    <div className='w-100 d-flex flex-column align-items-center'>
                    <Form.Group className='w_90 mt-4 mb-2'>
                        <Form.Label>{t('AddCourse.6')}<sup style={{color:'red'}}>*</sup> <span style={{color:'red'}}>({t('AddCourse.20')})</span></Form.Label>
                        <Form.Control
                            placeholder="e.g., Advanced IELTS Course"
                            aria-label="Course Name"
                            aria-describedby="basic-addon1"
                            onChange={e=>setName(e.target.value)}
                            value={name}
                        />
                    </Form.Group>
                    <Form.Group className='w_90 my-2'>
                        <Form.Label>{t('AddCourse.7')}<sup style={{color:'red'}}>*</sup> <span style={{color:'red'}}>({t('AddCourse.20')})</span></Form.Label>
                        <Select                
                            value={selectedLanguage}
                            onChange={(e)=>setSelectedLanguage(e)}
                            options={options}
                            required
                        />
                    </Form.Group> 
                    <Form.Group className='w_90 my-2'>
                        <Form.Label>{t('AddCourse.8')}<sup style={{color:'red'}}>*</sup> <span style={{color:'red'}}>({t('AddCourse.20')})</span></Form.Label>
                        <Form.Control as="textarea" rows={8} 
                            placeholder="Course description - Max 450 characters"
                            maxLength={450}
                            onChange={handleDescChange}
                            value={description}
                                />
                    </Form.Group>
        </div>
  )
}

export default Slide1