import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import Button from 'react-bootstrap/Button';

const ChatFileShare = (props) => {
    let [submitting,setSubmitting] = useState(false);
    let [name,setName] = useState("");
    let {show, handleClose,sendFile} = props;
    let [file_path,setSelectedFile] = useState("");
    const options = [
        { value: 'pdf', label: 'PDF' },
        { value: 'image', label: 'Image' },
        { value: 'video', label: 'Video' }
    ]

    
    async function handleSubmit(e){
        e.preventDefault();
        if(!name||!file_path){
            return toast('Please fill a name and upload file first',{
                icon:'💡'
            })

        }
        setSubmitting(true);
        await sendFile({name,file_path});
        setSubmitting(false);
        setName('');
        setSelectedFile(null);
        props.handleClose();
    }
    return(
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className='d-flex justify-content-center' closeButton>
                <Modal.Title>Send a File</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center justify-content-center'>
                <Form className='my-2 d-flex flex-column w-100'>
                    <div className='my-2'>
                        <Form.Group>
                            <Form.Label>Name of file:</Form.Label>
                            <Form.Control
                                type='text'
                                value={name}
                                onChange={e=>setName(e.target.value)}
                                required
                            ></Form.Control>
                        </Form.Group>
                    </div>
                    
                    <div className='my-4 d-inline-block'>
                        <Form.Label>Any additional files (supports .png,.jpeg,.pdf,.docx,.mp4)</Form.Label>
                        <Form.Control
                            type='file'
                            onChange={e=>setSelectedFile(e.target.files[0])}
                            accept='.png,.jpg,.jpeg,.pdf,.mp4,.docx'
                        />
                    </div>
                    <div className='my-2 d-flex justify-content-center'>
                        <button type='submit' disabled={submitting} className='btn btn-success' onClick={handleSubmit}>{submitting?'Submitting':'Submit'}</button>
                    </div>
                    </Form>
            </Modal.Body>
            
        </Modal>
    )
}

export default ChatFileShare