import React from 'react'
import { useNavigate } from 'react-router-dom'
import classes from '../../styles/HowToUse.module.css';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
export default function TSlide4(props){

    let navigate = useNavigate();
    let {t,i18n} = useTranslation();
    return (
        <div className={`col-10 col-lg-8 col-xxl-5 text-light py-3 px-2 d-flex flex-column poppins justify-content-center align-items-center  ${classes.container}`}>
           <h3 className=' my-5 px-2 text-warning px-lg-5'>{t('HowToUse.19')}</h3>
           <div className='w-100 rounded' style={{backgroundImage:'url(https://scholaristdemo.s3.us-east-2.amazonaws.com/assets/Meeting.png)',backgroundSize:'contain',height:0,paddingBottom:'50%',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>

           </div>

           <h3 className=' my-5 px-2 text-warning px-lg-5'>{t('HowToUse.11')}</h3>
           <div className='bg-blue d-flex flex-column rounded align-items-start text-white p-2 px-5' style={{width:'80%'}}>
                <h3 className='my-3'><i className='fa-solid fa-headset me-4'></i>{t('Support.2')}</h3>
           </div>
           <div className='rounded bg-white  d-flex flex-column justify-content-center my-5 border text-dark' style={{width:'85%'}} >
                          <h3 className='w-100 p-3'>{t('Support.4')}</h3>
                          <div className='bg-customgrey border-top w-100 p-3'>
                            <Form className='my-4 row d-flex flex-column align-items-center justify-content-between text-dark'>
                            <div className='col-12 col-md-8 my-2'>
                              <Form.Group>
                                <Form.Label>{t('Support.5')}</Form.Label>
                                <Form.Control
                                  placeholder="Assignment-1"
                                  aria-label="Assignment-1"
                                  aria-describedby="basic-addon1"
                                  disabled
                                />
                              </Form.Group>
                            </div>       

                            
                            <div className='col-12 col-md-8 my-2'>
                              <Form.Group >
                                <Form.Label>{t('Support.7')}</Form.Label>
                                  <Form.Control as="textarea" rows={8} 
                                    maxLength={350}
                                    disabled
                                  />
                              </Form.Group>
                            </div>                                                                        

                            

                            <div className='col-12 col-md-8 my-3'>
                              <Form.Label>{t('HowToUse.12')} (supports .png,.jpeg,.pdf,.docx,.mp4)</Form.Label>
                              <Form.Control
                                  type='file'
                                  disabled
                              />
                            </div>                                         

                            
                            </Form>
                            </div>
            </div>
           <div className='w-100 px-5 py-5 d-flex justify-content-end'>
               <button className='rounded btn btn-success text-light' onClick={()=>navigate('/')}>{t('HowToUse.13')}</button>
           </div> 
    </div>
    )
}