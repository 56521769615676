import classes from '../styles/Navbar.module.css';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Login from './Login';
import SignUp from './SignUp';
import Select from "react-select";
import {useTranslation} from 'react-i18next';
function Navbar(){

    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignup] = useState(false);
    const handleClose = () => setShowLogin(false);
    const handleShow = () => setShowLogin(true);
    const handleCloseSignup = () => setShowSignup(false);
    const handleShowSignup = () => setShowSignup(true);
    let [selectedType,setType] = useState({ value: 'en', label: '🇺🇸  English' });
    const {t,i18n} = useTranslation();
    // localStorage.setItem('lang',selectedType.value);
    const options = [
        { value: 'en', label: '🇺🇸  English' },
        { value: 'fr', label: '🇫🇷  French' },
        { value: 'es', label: '🇪🇸  Spanish' },
        { value: 'zh_CN', label:'🇨🇳  Chinese'}
      ];

    useEffect(()=>{
        if(i18n.language=='fr'){
            setType(options[1])
        }else if(i18n.language=='es'){
            setType(options[2])
        }else if(i18n.language=='zh_CN'){
            setType(options[3])
        }else{
            setType(options[0])
        }
    },[])
    const style = {
        navdark:{
            backgroundColor:'rgb(0,0,0)',
        
        },
        header:{
            borderBottom: '0 none !important'
        },
        footer:{
            borderTop:'0 none !important'
        }
        
    }

    return(
        <>
     
            <Login show={showLogin}
            onHide={() => setShowLogin(false)}/>
            <SignUp show={showSignUp}
            onHide={() => setShowSignup(false)}/>
            <nav className="navbar navbar-expand-lg" style={style.navdark}>
            <div className="container">
                <div className='navbar-brand d-flex'>
                    <Link to='/'>
                        <div className={`${classes.img_fit} ${classes.logo} me-3 animate__animated animate__fadeInDown`}>
                        
                        </div>
                    </Link>
                    
    
                </div> 
                {console.log(localStorage.getItem('lang'))}
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className={`navbar-toggler-icon `} style={{color:'white'}}><i className="fa-solid fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse  d-lg-flex justify-content-between align-items-center" id="navbarSupportedContent">
                        <ul className="navbar-nav mt-4 mt-lg-0 m-auto mb-2 mb-lg-0 w-adjust d-flex flex-column flex-lg-row justify-content-lg-end align-items-lg-center">
                            <li className={`nav-item my-2 my-lg-0 mb-3 mb-lg-0 mx-lg-4 ${classes.language}`}style={{color:'white'}}>
                                <Select 
                                  value={selectedType}
                                  onChange={(e)=>{setType(e);i18n.changeLanguage(e.value)}}
                                  options={options}
                                  required
                                  styles={{
                                    control:(bs)=>({
                                        ...bs,
                                        backgroundColor:'black',
                                        border:'none'
                                    }),
                                    singleValue:(bs)=>({
                                        ...bs,
                                        color:'white !important',
                                        fontWeight:'bold'
                                    }),
                                    menu:(bs)=>({
                                        ...bs,
                                        color:'black !important',
                                        
                                    })
                                  }}
                                />
                            </li>
                            <li className={`nav-item d-inline-block my-3 mx-lg-4 text-light`}>
                                <Link className='no-decor' to='/services'><div className='animate__animated animate__fadeInDown'><b>{t('Navbar.1')} </b></div></Link>    
                            </li>
                            
                            <li className='nav-item my-2 my-lg-0 mb-3 mb-lg-0 mx-lg-4 d-flex flex-column'>
                                <button onClick={handleShow} type="button" className={`btn animate__animated animate__fadeInDown ${classes.btn_white}`}><b>{t('Navbar.3')}</b></button>
                            </li>
                            <li className='nav-item my-2 my-lg-0 mb-3 mb-lg-0 mx-lg-4 text-light d-flex flex-column d-lg-inline-block'>
                                <b className='d-inline-block mb-3 my-lg-0 mx-0 mx-lg-2 ' style={{fontWeight:'bold'}}>{t('Navbar.5')} </b><button onClick={handleShowSignup} type="button" className={`btn d-inline-block btn-danger animate__animated animate__fadeInDown`}><b>{t('Navbar.4')}</b></button>
                            </li>
                        </ul>
                    </div>
                
                
                
                
            </div>
        </nav>
        </>
    )
}

export default Navbar;