import React,{Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './providers/AuthProvider';
import './index.css';
import App from './App';
import './i18next';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { StudentProvider } from './providers/StudentProvider';
import { TeacherProvider } from './providers/TeacherProvider';
import { SocketProvider } from './providers/SocketProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={(<div>Loading....</div>)}>
    <AuthProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
        <TeacherProvider>
          <StudentProvider>
            <SocketProvider>
              <Router>
                <App />
              </Router>
            </SocketProvider>
          </StudentProvider>
        </TeacherProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// export HTTPS=true&&SSL_CRT_FILE=cert.pem&&SSL_KEY_FILE=key.pem
reportWebVitals();
