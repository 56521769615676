import React, { useEffect,useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate,Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import { useTeacher } from '../../../providers/ProvideTeacher';
import classes from '../../../styles/Availability.module.css';
import { TokenKey } from '../../../utils';
import {useTranslation} from 'react-i18next';
import { Scheduler } from "@aldabil/react-scheduler";
import { MdSort } from 'react-icons/md';
const Sidebar = () => {
        const navigate = useNavigate();
        const auth = useAuth();
        const location = useLocation();
        let {t,i18n} = useTranslation();
        let [events, setEvents] = useState([]);
        let [editable, setEditable] = useState(false);
        let [bookings,setBookings] = useState([]);
       
      
        async function fetchRemote(query){
            return new Promise(async (resolve, reject) => {
                
                  try {
                    const response = await fetch(`https://scholarist.com:8000/api/teachers/getavailability`,{
                        method:'GET',
                        headers:{
                            'Content-type':'application/json',
                            'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                        }
                    });
                    if (!response.ok) {
                      throw new Error('Failed to fetch remote data');
                    }
                    const reply= await response.json();
                    if(reply.success){
                        var booked = reply.data.map(e=>{
                            if(e.isBooked){
                                return {name:e.User.name,avatar:e.User.avatar,day:e.start.slice(0,10),start:e.start.slice(11,16),end:e.end.slice(11,16),title:e.title}
                            }
                        })
                        var events=reply.data.map(e=>{
                            const { id, title,start,end } = e;
                            return { event_id: id, title,start:new Date(start),end:new Date(end)};
                        })
                        setBookings(booked);
                    }
                    resolve(events);
                  } catch (error) {
                    reject(error);
                  }
                
              });
        }
        async function enableEditing(){
            await setEditable(!editable);
            
        }
        async function handleLogout(){
            let res=await auth.logout();
            toast.success('Logged out successfully');
            return navigate('/',{replace:true})
        }
        const styles={
            image:{
                backgroundImage:`url(${auth.user.avatar})`
            },
            font:{
                fontFamily:'Baloo'
            }
        }
  return (
    <div id="page-content-wrapper" className='py-2'>
        {console.log(editable,'....hfhbvfbvf\n')}
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-light d-flex justify-content-between">
        <div className="d-flex align-items-center">
        <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-3'/></a>
        </div>
        <h2 className="m-0">Availability</h2>
        <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
            <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
        </div>
    </nav>

    <div className='container-fluid px-3 d-flex justify-content-end'>
        {editable&&<button className='btn btn-danger my-5' onClick={enableEditing}><b>Stop Editing</b></button>}
        {!editable &&<button className='btn btn-success my-5' onClick={enableEditing}><b>Edit availability</b></button>}
    </div>
    <div className='container-fluid px-4'>
        <div className='row d-flex justify-content-center my-3'>
            <div className={`col-12 my-3`}>
                {editable&&
                <Scheduler
                editable={true}
                deletable={false}
                getRemoteEvents={fetchRemote}
                view='month'
                day={
                  {startHour:0,
                  endHour: 24, 
                  step: 60,
                  navigation: true}
                } 
            />}
            {!editable &&
            <Scheduler
            editable={false}
            deletable={false}
            getRemoteEvents={fetchRemote}
            view='month'
            day={
              {startHour:0,
              endHour: 24, 
              step: 60,
              navigation: true}
            } 
            />}
               
            </div>
        </div>
    </div>
    <div className='container-fluid px-4 text-light'>
        <h2 className='text-center'>Bookings</h2>
        {bookings.length>0 ? 
        <div className='row d-flex justify-content-evenly my-5'>

            {bookings.map((item,index)=>
            {
                if(item){
                    return <div className='col-12 col-lg-6 d-flex justify-content-center flex-nowrap align-items-center my-3' key={index}>
                    <span className={classes.bookingProfile} style={{backgroundImage:`url(${item.avatar})`}}></span>
                    <div className='mx-3'><b>{item.name} has booked lesson {item.title} on {item.day} from {item.start} to {item.end} </b></div>
                 </div>
                }
            }
            
            )}
        </div>
        :

        <>
        </>}
        
    </div>

</div>

  )
}

export default Sidebar