/** ***** EVENTS ACTIONS BEGINS***** */
//  1. RECORDING
const RECORDING_STARTED = 'RECORDING_STARTED';
const RECORDING_STOPPED = 'RECORDING_STOPPED';
// 2. SCREENSHARE
const SCREENSHARE_STARTED = 'SCREENSHARE_STARTED';
const SCREENSHARE_STOPPED = 'SCREENSHARE_STOPPED';

const EventActions = {
  RECORDING_STARTED,
  RECORDING_STOPPED,
  SCREENSHARE_STARTED,
  SCREENSHARE_STOPPED,
};

/** ***** EVENTS  ACTIONS ENDS ***** */

/** ***** EVENT NAMES BEGINS ***** */
// 1. RECORDING
const RECORDING_ATTRIBUTE = 'recording';
// 2. SCREENSHARE
const SCREENSHARE_ATTRIBUTE = 'screenshare';
// 2. LIVE STREAMING
const RAISED_ATTRIBUTE = 'raised';
const ROLE_ATTRIBUTE = 'role';
// 3. CHAT MESSAGES
const PUBLIC_CHAT_MESSAGE = 'PUBLIC_CHAT_MESSAGE';
const PRIVATE_CHAT_MESSAGE = 'PRIVATE_CHAT_MESSAGE';
// 4. NAME ATTRIBUTE
const NAME_ATTRIBUTE = 'name';
// 5. VIDEO MEETING ROLE
const VIDEO_MEETING_HOST = 'VIDEO_MEETING_HOST';
const VIDEO_MEETING_ATTENDEE = 'VIDEO_MEETING_ATTENDEE';
const EventNames = {
  RECORDING_ATTRIBUTE,
  RAISED_ATTRIBUTE,
  ROLE_ATTRIBUTE,
  PUBLIC_CHAT_MESSAGE,
  PRIVATE_CHAT_MESSAGE,
  SCREENSHARE_ATTRIBUTE,
  NAME_ATTRIBUTE,
  VIDEO_MEETING_HOST,
  VIDEO_MEETING_ATTENDEE,
};
/** ***** EVENT NAMES ENDS ***** */

export {EventActions, EventNames};
