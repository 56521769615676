
import React,{useState,useEffect} from 'react';
import classes from '../../styles/About.module.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
function AboutUs(){

    let {t,i18n} = useTranslation();
    let navigate = useNavigate();
    let [profiles,setProfiles] = useState([]);
    let [loading,setLoading] = useState(true)
    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/aboutus?lang=${i18n.language}`);
                    let response = await reply.json();
                    setProfiles(response.data);
                    setLoading(false)
                }catch(err){
                    setLoading(false)
                    console.log(err);
                }
            }
        )();
    },[i18n.language])
    return (
        <>
        {console.log(profiles,'bachke...\n\n')}
        <div className={`container-fluid ${classes.black}`}>
            <div className='container py-5'>
                <div className='row py-5'>
                    <div class='col-12 col-lg-7 order-2 order-lg-1 d-flex flex-column align-items-center'>
                        <h1 className={`my-5 ${classes.heading} animate__animated animate__fadeInLeft`}>{t('AboutUs.1')}</h1>
                        <h5 className={`${classes.heading} animate__animated animate__fadeInLeft`}>{t('AboutUs.2')}</h5>
                    </div>
                    <div class='col-12 col-lg-5 order-1 order-lg-2 d-flex justify-content-center'>
                        <div className='w-75'>
                            <div className={`animate__animated animate__fadeInRight ${classes.future_img}`}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`container-fluid ${classes.story}`}>
            <div className='container py-5'>
                <div className='row py-5'>
                    <div class='col-12 col-lg-7 order-2 d-flex flex-column align-items-center'>
                        <h1 className={`my-5 animate__animated animate__fadeInRight`}>{t('AboutUs.3')}</h1>
                        <p className='animate__animated animate__fadeInRight'>{t('AboutUs.4')} </p>
                    </div>
                    <div class='col-12 col-lg-5 order-1 d-flex justify-content-center'>
                        <div className='w-75'>
                            <div className={`animate__animated animate__fadeInLeft ${classes.demo_img}`}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='container py-5'>
                <div className='row py-4'>
                    <div className='col-12 px-2 mb-4'>
                        <h1>{t('AboutUs.5')}</h1>
                    </div>
                </div>
                {loading && 
                <Box sx={{ display: 'flex',justifyContent:'center' }}>
                <CircularProgress />
              </Box>}
                {!loading&&
                <div className='row py-5 d-flex justify-content-center'>
                {profiles.map((item,index)=>{
                    if(item.name=='Ankush Jain'){
                        return <></>
                    }
                    else{
                        return (
                            <Link to={`/teacherdemo/${item.id}`} className='col-11 col-lg-5 my-3 mx-3 shadow text-dark  p-4 no-decor rounded'  key={index} >
                        
                            <div className='row'>
                                <div className='col-4 d-flex justify-content-center'>
                                    <div className={classes.teacher_img} style={{backgroundImage:`url(${item.avatar})`}}>

                                    </div>
                                </div>
                                <div className='col-8 d-flex flex-column justify-content-center'>
                                    <h5 className='mb-2'>{item.name}</h5>
                                    <p>{item.skills}</p>
                                </div>
                            </div>
                            <div className='row my-5'>
                                <div className='col-11'>
                                {item.description}
                                </div>
                                
                            </div>
                        </Link>
                        )
                    }
                }
                   
                )}
                
            </div>}
                
                    
                
            </div>
        </div>
        </>
    )
}
export default AboutUs;