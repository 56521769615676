import React,{useState} from 'react';
import classes from '../../styles/QueueComponent.module.css'
import {Link} from 'react-router-dom';
function QueueComponent(props){
    var array=[1,2,3,4,5];
    let {item} = props;
    return(
        <div className={`col-12 no-decor col-md-10 col-lg-6 col-xxl-5 me-3 px-4`} >
           
            <Link to={`teacherdemo/${item.id}`} className={`${classes.decor} d-flex flex-column py-2`}>
            <div className='w-100'>
                <div  className={`${classes.img} m-auto`} style={{backgroundImage:`url(${item.avatar})`}}>

                </div>
            </div>
            <div className={`w-100 py-5 px-2 text-light d-flex flex-column align-items-center poppins`} style={{backgroundColor:'rgb(27,31,36)', borderRadius:'20px'}}>
                <h4 className='my-2 my-lg-4 py-3'><b>Team Member</b></h4>
                <div className='my-3 '>
                    <b>{item.name}</b>
                </div>
                <div className={classes.rating}>
                    <b>5 &nbsp;</b>
                    {array.map(item=>
                        <i style={{color:'rgb(218,154,60)'}}className='fa-solid fa-star mx-1'></i>)}
                </div>
                <div className='my-2'>
                    <b>Trial Classes available</b>
                </div>
            </div>
            </Link>
            
        </div>
    )
}

export default QueueComponent;