import React, { useEffect, useState } from 'react'
import { useParams,Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import { useStudent } from '../../../providers/ProvideStudent';
import classes from '../../../styles/TeacherProfile.module.css';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MdSort } from 'react-icons/md';
import { Scheduler } from "@aldabil/react-scheduler";
import Select from "react-select";
import {getItemFromLocalStorage, TokenKey } from "../../../utils";
import Form from 'react-bootstrap/Form';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../CustomScheduler.css';
const TeacherProfile = () => {
    const auth = useAuth();
    let {t,i18n} = useTranslation();
    let student = useStudent();
    let navigate = useNavigate();
    let [teacher, setTeacher] = useState({})
    let {id} = useParams();
    let [loading,setLoading] = useState(false)
    let [book,setBook] = useState(false);
    let array = [1,2,3,4,5]
    let [options,setOptions] = useState([]);
    let [lesson,setSelectedLesson] = useState({price:0});
    let [price,setPrice] = useState(0);
    let [tax,setTax] = useState(0);
    let [fees,setFees] = useState(0);
    let [discount,setDiscount] = useState(0);
    let [final_amount,setFA] = useState(parseFloat(price)+parseFloat(tax)+parseFloat(fees)-parseFloat(discount));
    let [checkoutToken,setToken] = useState('');
    let [isBought,setBought] = useState(false);
    let [isBuying,setBuying] = useState(false);
    let today = new Date().toLocaleDateString();
    useEffect(()=>{
        (
            async function (){
                setLoading(true)
                let reply = await student.getTeacher(id,i18n.language)
                setLoading(false)
                if(reply.success){
                    setTeacher(reply.data)
                }
                
            }
        )();
    },[i18n.language]);

    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }

    useEffect(()=>{
        (
          async()=>{
            await setFA((parseFloat(price)+parseFloat(tax)+parseFloat(fees)-parseFloat(discount)).toFixed(2));
          }
        )();
        
      },[price,discount]);
    
      useEffect(()=>{
        
        setPrice(lesson.price);
        setTax((0.13*lesson.price.toFixed(2)));
    
      },[lesson]);
    
      useEffect(()=>{
        (async()=>{
          let reply = await fetch(`https://scholarist.com:8000/api/payment/token?amount=${final_amount}`,{
            method:'GET',
            headers:{
              'Content-Type':'application/json',
              'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
            }
          });
          let response = await reply.json();
          console.log('response is',response)
          await setToken(response.checkout);
        })();
      
      },[final_amount])
      

    async function fetchRemote(query){
        return new Promise(async (resolve, reject) => {
            
              try {
                const response = await fetch(`https://scholarist.com:8000/api/students/getavailability/${id}`,{
                    method:'GET',
                    headers:{
                        'Content-type':'application/json',
                        'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                    }
                });
                if (!response.ok) {
                  throw new Error('Failed to fetch remote data');
                }
                const reply= await response.json();
                if(reply.success){
                    var events=reply.data.events.map(e=>{
                        const { id, title,start,end } = e;
                        return { event_id: id, title,start:new Date(start),end:new Date(end)};
                    })
                    var options = reply.data.events.map(e=>
                       {return{value:e.title,label:e.title,price:e.price,id:e.event_id}; }
                    )
                    setOptions(options)
                }
                resolve(events);
              } catch (error) {
                reject(error);
              }
            
          });
    }

    useEffect(()=>{
        window.addEventListener('message', async (event) => {
          
          const helcimPayJsIdentifierKey = 'helcim-pay-js-' + checkoutToken;
          console.log('idhar aaye',event.data.eventName,helcimPayJsIdentifierKey);
          if(event.data.eventName === helcimPayJsIdentifierKey){
            console.log('fir pehle pe',event.data);
            if(event.data.eventStatus === 'ABORTED'){
              console.log('ab doosre pe');
               window.alert('Transaction failed ! Please try again')
            }
            
            if(event.data.eventStatus === 'SUCCESS'){
              console.log('teesre pe');
              setBuying(true);
              try{
                let reply = await fetch(`https://scholarist.com:8000/api/students/purchase/lesson?event=${lesson.id}&price=${final_amount}&day=${today}`,{
                    method:'GET',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                      }
                })
                let response = await reply.json();
                console.log('hua enroll');
                var elem = document.getElementById('helcimPayIframe');
                elem.remove();
                if(response.success){
                    await setBought(true);
                    await setBuying(false);
                }
                
    
              }catch(err){
                  console.log(err)
              }
            }
            
          }
          
        });
      },[checkoutToken]);
    

    async function requestLesson(e){
        e.preventDefault();
        try{
            window.appendHelcimPayIframe(checkoutToken, true)
        }catch(err){
            console.log(err)
        }
    }

    function slideLeft(){
        var slider = document.getElementById('teachercourses');
        slider.scrollLeft = slider.scrollLeft-600
    }

    function slideRight(){
        var slider = document.getElementById('teachercourses');
        slider.scrollLeft = slider.scrollLeft+600

    }

    const styles={
        image:{
            backgroundImage:`url(${auth.user.avatar})`
        }
    }
    return (
        <div id="page-content-wrapper">
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                <div className="d-flex align-items-center">
                <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                </div>
                <h2 className="m-0 poppins" style={{flexShrink:1,overflowWrap:'break-word'}}><b>{teacher.name}</b></h2>
                <div className='no-decor align-start d-none d-md-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                    <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                </div>
            </nav>
            {isBought && 
            <div className='container my-5'>
                <div className='row my-5'>
                    <div className='col-12 my-5 py-5 d-flex flex-column align-items-center text-light justify-content-center'>
                        <h2 className='text-center text-success'><big><i className="fa-solid fa-circle-check"></i></big></h2>
                        <h2 className='text-center mt-2 mb-5'>{t('Payment.17')}</h2>
                        <Link className='no-decor' to='/user'><button className='btn btn-success'>{t('Payment.18')}</button></Link>
                    </div>

                </div>
            </div>
            }
            {!isBought&&
            <>
                <div className='container-fluid'>
                <div className='row d-flex pt-5 justify-content-center text-light'>
                    {loading &&
                    <h4 className='col-12 my-5 py-5 justify-content-center text-center'>
                        <Box sx={{ display: 'flex',justifyContent:'center' }}>
                                <CircularProgress />
                            </Box>
                    </h4>}
                    {!loading &&
                    <>
                        <p className='m-auto text-center text-light my-5'>
                            {teacher.description}
                        </p>
                        <p className='text-primary m-auto text-center'><b>Skills: {teacher.skills}</b></p>
                        <div className='w-100 d-flex justify-content-center mt-5 mb-3'>
                            <button type='button' className='btn btn-danger px-4 mx-3'><b>Contact</b></button>
                            <button type='button' className={`btn ${classes.btn_white} px-4 mx-3`}><b>View my profile</b></button>
                        </div>
                        <div className={`m-auto ${classes.teacher_icon}`} style={{backgroundImage:`url(${teacher.avatar})`}}>

                        </div>
                    </>             
                    }
                </div>
            </div>
            <div className='container-fluid bg-light pt-5 pb-2'>  
                {teacher ?
                <>
                <h2 className='m-auto text-dark text-center poppins' style={{paddingTop:'8rem'}}><b>{t('Teachers.2')}</b></h2>
                <div className='row d-flex flex-nowrap justify-content-center py-5 my-5'>
                {teacher.courses&&teacher.courses.length!=0 ? 
                <>
                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center justify-content-md-end justify-content-xxl-start align-items-center'>
                        <div className={classes.slideLeft} onClick={slideLeft}></div>
                    </div>
                    <div className={`col-8 col-md-6 col-lg-8`}>
                        <div className={`row d-flex flex-nowrap ${classes.scroll}`} id='teachercourses'>
                            {teacher.courses.map((item,index)=>
                            <>
                            <div className='col-9 col-lg-5 col-xxl-3 d-flex flex-column no-decor  me-2 p-4 rounded' key={index}>
                                
                                <div className={`${classes.img} rounded`} style={{backgroundImage:`url(${item.avatar})`}}>

                                </div>
                                
                                <div className={`my-2 ${classes.courseinstructor}`}>
                                    <b>{item.name}</b>
                                </div>
                                <div className={classes.rating}>
                                    <b>4.9 &nbsp;</b>
                                    {array.map(item=>
                                        <i style={{color:'rgb(218,154,60)'}}className='fa-solid fa-star mx-1'></i>)}
                                </div>
                                <div className='text-light'>
                                    <b>{item.price} CAD$</b>
                                </div>
                                
                        
                            </div>
                            
                            </>
                            
                            )}
                            
                            
                        </div>
                    </div>
                    <div className='col-2 col-md-3 col-lg-2 col-xxl-1 d-flex justify-content-center justify-content-md-start justify-content-xxl-end align-items-center'>
                        <div className={classes.slideRight} onClick={slideRight}></div>
                    </div>
                </>
                :
                <div className='d-flex col-12 justify-content-center align-items-center text-dark'>
                    <p><i>{t('Teachers.3')}</i></p>
                </div>
                }
                
            </div>
            </>    
                : <></>}



            {
                teacher &&
                <>
                <h2 className='m-auto text-dark poppins text-center'><b>Availability</b></h2>
                <div className='row d-flex justify-content-center my-5 d-flex justify-content-center'>
                    <div className={`col-11 col-xxl-8 p-3 ${classes.avail_container} my-3`}>
                        <Scheduler
                            editable={false}
                            deletable={false}
                            getRemoteEvents={fetchRemote}
                            view='month'
                            day={
                            {startHour:0,
                            endHour: 24, 
                            step: 60,
                            navigation: true}
                            } 
                        />
                    </div>
                    <div className='col-11 my-5 py-5 d-flex justify-content-center'>
                            <button className='btn btn-success' onClick={()=>setBook(true)}><b>BOOK A LESSON</b></button>
                    </div>
                </div>
                </>
            }
            {
                book && 
                <>
                    <div className='row d-flex justify-content-center'>
                            <div className='col-11 col-lg-8 p-3' style={{backgroundColor:'rgb(27,31,36)',borderRadius:'20px'}}>
                                    <Form className='my-4 row d-flex text-light justify-content-between'>
                                        <div className='col-12 col-md-6'>
                                        <Form.Group >
                                            <Form.Label>Price:</Form.Label>
                                            <div>{lesson.price}</div>
                                        </Form.Group>   
                                        </div>
                                        <div className='col-12 col-md-6'>
                                        <Form.Group >
                                            <Form.Label>Lessons</Form.Label>
                                            <Select
                                                id='lessons'
                                                options={options}
                                                onChange={(option) => setSelectedLesson(option)}
                                                required
                                                styles={{
                                                    control:(bs)=>({
                                                        ...bs,
                                                        backgroundColor:'white',
                                                        border:'none'
                                                    }),
                                                    singleValue:(bs)=>({
                                                        ...bs,
                                                        color:'black !important',
                                                        fontWeight:'bold'
                                                    }),
                                                    menu:(bs)=>({
                                                        ...bs,
                                                        color:'black !important',
                                                                    
                                                    })
                                                    }}
                                            />
                                        </Form.Group>
                                        </div>
                                        
                                    
                                        <div className='col-12 my-5 d-flex justify-content-center'>
                                            <button onClick={()=>setBook(false)} className='btn btn-danger mx-4'>
                                                Cancel
                                            </button>
                                            <button type='submit' onClick={requestLesson} className='btn btn-success mx-4'>
                                                Pay
                                            </button>
                                        </div>
                                    </Form>
                                </div>
                    </div>
                </>
            }
            </div>            
            </>
            }
            


        </div>
    )
}

export default TeacherProfile;