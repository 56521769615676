import {createContext} from 'react';
import { useProvideTeacher } from './ProvideTeacher';
var initialState={
    courses:[],
    setCourses:()=>{},
    getCourses:()=>{},
    addCourse:()=>{},
    fetchStudents:()=>{}
}

export let TeacherContext = createContext(initialState);

export function TeacherProvider({children}){
    var Teacher = useProvideTeacher();
    return (
        <TeacherContext.Provider value = {Teacher}>
            {children}
        </TeacherContext.Provider>
    )

}