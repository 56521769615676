import React, { useEffect,useState } from 'react'
import { useParams,Link, Navigate } from 'react-router-dom';
import { getItemFromLocalStorage,TokenKey } from '../utils';
import { useSocket } from '../providers/ProvideSocket';
const Thanks = () => {

    const {courseId} = useParams();
    const [loading,setLoading] = useState(true);
    let {studentAdded} = useSocket();
    useEffect(()=>{
        (
            async()=>{
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/students/bought?courses=${courseId}`,{
                        headers:{
                            'Content-Type':'application/json',
                            'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                          }
                    })
                    let response = await reply.json();
                    if(response.success){
                        studentAdded(response.message);
                    }
                    setLoading(false);
                }catch(err){
                    console.log(err)
                }
            }
        )();
    },[])
    
    return(
        <>
        {loading?
        <div className='container-fluid'>
        <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
                <h4 className='my-4'>Loading....</h4>
            </div>

        </div>

    </div>
        :
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 d-flex flex-column align-items-center justify-content-center'>
                    <h2 className='text-center my-4'>Thanks for the successful payment</h2>
                    <Link className='no-decor' to='/user'><button className='btn btn-success'>Head to Dashboard</button></Link>
                </div>

            </div>

        </div>
        }
        </>
    )
}

export default Thanks