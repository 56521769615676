import React,{useState, useEffect} from 'react'
import { useAuth } from '../../../providers/ProvideAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MdSort } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import classes from '../../../styles/Lecture.module.css'
import Form from 'react-bootstrap/Form';
import { TokenKey } from '../../../utils';
function ReviewPage() {

    let auth = useAuth();
    let {t,i18n} = useTranslation();
    let navigate = useNavigate();
    let [review,setReview] = useState();
    let [posting,setPosting] = useState(false);
    let [showForm,setShowForm] = useState(false);
    let [description, setDesc] = useState('');

    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch('https://scholarist.com:8000/api/review/platform',{
                        method:'GET',
                        headers:{
                            'Content-type':'application/json',
                            'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                        }
                    })
                    let response = await reply.json();
                    setReview(response.data)
                }catch(err){
                    console.log(err)
                }
            }
        )();
    },[])

    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    async function handleSubmit(e){
        e.preventDefault();
        setPosting(true)
        try{

            let reply = await fetch('https://scholarist.com:8000/api/review/platform',{
                method:'POST',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body:JSON.stringify({description})
            })
            let response = await reply.json();
            setPosting(false)
            setReview(response.data)
            return toast.success('Posted successfully')
        }catch(err){
            setPosting(false)
            console.log(err)
            return toast.error('Error in posting review')
        }
    }
  return (
    <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
            <div className="d-flex align-items-center">
                <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-3'/></a>
            </div>
            <h2 className="m-0">Review</h2>
            <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
                    <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
            </div>
        </nav>

        <div className='container-fluid my-5 py-5'>
            <div className='row text-light justify-content-center'>
                {review &&
                <div className='col-10 col-lg-7 col-xxl-5' style={{borderRadius:'20px',backgroundColor:'rgb(41,45,54)'}}>
                <div className='row d-flex flex-column flex-lg-row justify-content-center align-items-center p-3'>
                    <div className='col-8 col-lg-6 d-flex justify-content-center align-items-center'>
                        <div className={classes.review} style={{backgroundImage:`url(${auth.user.avatar})`}}></div>
                    </div>
                    <div className='col-11 col-lg-6 d-flex flex-column justify-content-center'>
                        <h3><b>{auth.user.name}</b></h3>
                        <div className='mb-3'>{review.posting_day}</div>
                        <div className='w-100 my-4'>
                            <i>{review.description}</i>
                        </div>

                    </div>
                    
                </div>
            </div>}
                {!review && !showForm && 
                <div className='col-12 d-flex flex-column justify-content-center text-center'>
                    <div><i>You haven't reviewed us yet. Please tell us how we are doing so that we can improve !</i></div>
                    <div><button className='my-5 d-inline-block btn btn-success' onClick={()=>setShowForm(true)}>Add Review</button></div>
                </div>} 
                {!review && showForm &&
                <div className='col-10 col-lg-7 col-xxl-5' style={{borderRadius:'20px',backgroundColor:'rgb(41,45,54)'}}>
                    <div className='row d-flex flex-column flex-lg-row justify-content-center align-items-center p-3'>
                        <div className='col-8 col-lg-6 d-flex justify-content-center align-items-center'>
                            <div className={classes.review} style={{backgroundImage:`url(${auth.user.avatar})`}}></div>
                        </div>
                        <div className='col-11 col-lg-6 d-flex flex-column justify-content-center'>
                            <h3><b>{auth.user.name}</b></h3>
                            <Form className='my-4 d-flex flex-column align-items-center'>
                            <div className='w-100'>
                                <Form.Group >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={8} 
                                        maxLength={350}
                                        value={description}
                                        onChange={handleDescChange}
                                        required
                                    />
                                </Form.Group>   
                            </div>

                            <div className='w-100 my-5 d-flex justify-content-evenly'>
                                <button className='btn btn-success' disabled={posting} onClick={handleSubmit}>Post</button>
                                <button className='btn btn-danger' onClick={()=>setShowForm(false)}>Cancel</button>
                            </div>
             
                        </Form>
                        </div>
                        
                    </div>
                </div>
                }
            </div>
        </div>

    </div>
  )
}

export default ReviewPage