import React, { useRef } from 'react';
import {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import classes from '../../../styles/Payment.module.css';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import toast from 'react-hot-toast';
import {getItemFromLocalStorage, TokenKey } from "../../../utils";
import GooglePayButton from '@google-pay/button-react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

const Payment = () => {
  const auth = useAuth();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  let [courses,setCourses] = useState([]);
  let [price,setPrice] = useState(0);
  let [tax,setTax] = useState(0);
  let [fees,setFees] = useState(0);
  let [discount,setDiscount] = useState(0);
  let [final_amount,setFA] = useState(parseFloat(price)+parseFloat(tax)+parseFloat(fees)-parseFloat(discount));
  let [checkoutToken,setToken] = useState('');
  let [selectedType,setType] = useState({ value: 'en', label: '🇺🇸  English' });
  let [isBought,setBought] = useState(false);
  let [isBuying,setBuying] = useState(false);
  let [history,setHistory] = useState([]);
  const elemref=useRef();
  const {t,i18n} = useTranslation();
  let today = dayjs().format('YYYY-MM-DD');
  var initial=0;


    // localStorage.setItem('lang',selectedType.value);
    const options = [
        { value: 'en', label: '🇺🇸  English' },
        { value: 'fr', label: '🇫🇷  French' },
        { value: 'es', label: '🇪🇸  Spanish' },
        { value: 'zh_CN', label:'🇨🇳  Chinese'}
      ];

    useEffect(()=>{
        if(i18n.language=='fr'){
            setType(options[1])
        }else if(i18n.language=='es'){
            setType(options[2])
        }else if(i18n.language=='zh_CN'){
            setType(options[3])
        }else{
            setType(options[0])
        }
    },[])
  
    
  useEffect(()=>{
    (
      async()=>{
        await setFA((parseFloat(price)+parseFloat(tax)+parseFloat(fees)-parseFloat(discount)).toFixed(2));
      }
    )();
    
  },[price,discount]);

  useEffect(()=>{
    
    setPrice((courses.reduce((acc,i)=>acc+i.price,initial)).toFixed(2));
    setTax((0.13*(courses.reduce((acc,i)=>acc+i.price,initial))).toFixed(2));

  },[courses]);

  useEffect(()=>{
    (async()=>{
      let reply = await fetch(`https://scholarist.com:8000/api/payment/token?amount=${final_amount}`,{
        method:'GET',
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
        }
      });
      let response = await reply.json();
      console.log('response is',response)
      await setToken(response.checkout);
    })();
  
  },[final_amount])
  
  useEffect(()=>{
    (
      async ()=>{
        let reply = await fetch('https://scholarist.com:8000/api/students/cart/fetch',{
          method:'GET',
          headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
          }
        });
        let response = await reply.json();
        await setCourses(response.data);
        reply = await fetch('https://scholarist.com:8000/api/students/history/payment',{
          method:'GET',
          headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
          }
        });
        response = await reply.json();
        await setHistory(response.data)

     }
    )();
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
    return ()=>{
      setBought(false);
      setBuying(false);
    } 
  },[]);
  
  useEffect(()=>{
    window.addEventListener('message', async (event) => {
      let courseId="";
      for (let ele of courses){
        courseId+=(ele.id.toString()+',');
      }
      console.log(courseId,'hai courseId pehle');
      courseId=courseId.slice(0,courseId.length-1);
      console.log(courseId,'hai courseId baadme');
      const helcimPayJsIdentifierKey = 'helcim-pay-js-' + checkoutToken;
      console.log('idhar aaye',event.data.eventName,helcimPayJsIdentifierKey);
      if(event.data.eventName === helcimPayJsIdentifierKey){
        console.log('fir pehle pe',event.data);
        if(event.data.eventStatus === 'ABORTED'){
          console.log('ab doosre pe');
           window.alert('Transaction failed ! Please try again')
        }
        
        if(event.data.eventStatus === 'SUCCESS'){
          console.log('teesre pe');
          setBuying(true);
          try{
            let reply = await fetch(`https://scholarist.com:8000/api/students/bought?courses=${courseId}&price=${final_amount}&day=${today}`,{
                method:'GET',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                  }
            })
            let response = await reply.json();
            console.log('hua enroll');
            var elem = document.getElementById('helcimPayIframe');
            elem.remove();
            if(response.success){
                await setBought(true);
                await setBuying(false);
            }
            

          }catch(err){
              console.log(err)
          }
        }
        
      }
      
    });
  },[checkoutToken]);


  async function removeFromCart(item){
    try{
      let reply = await fetch('https://scholarist.com:8000/api/students/cart/remove',{
          method:'POST',
          headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
          },
          body:JSON.stringify(item)
      });
      let response = await reply.json();
      console.log(response,'sawan aaya hai...');
      await setCourses(response.data);
    }catch(err){
      console.log(err,'....')
      return toast.error('Error in deleting course from cart')
    }
  };

  const handleSubmit =  (event) => {
   window.appendHelcimPayIframe(checkoutToken, true)
  };

  const slideDown = () => {
    var cart_slider = document.getElementById('cart-slider');
    cart_slider.scrollTop = cart_slider.scrollTop + 150
  }

  const handlePromo = async (e) => {
    if(e.target.value==''){
      return
    }
    try{
      let reply = await fetch(`https://scholarist.com:8000/api/students/promo?code=${e.target.value}`,{
        method:'GET',
        headers:{
          'Content-Type':'application/json',
          'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
        }
      });
      let response = await reply.json();
      setDiscount(response.data);
    }catch(err){
      console.log(err,'....')
      return toast.error('Error in deleting course from cart')
    }
  }
  const styles={
    image:{
      backgroundImage:`url(${auth.user.avatar})`
    },
    profileIcon:{
        backgroundImage:`url(${auth.user.avatar})`
    },
    heading:{
        fontFamily:'Baloo'
    },
    neon:{
        color:'rgb(144,251,220)!important',
        borderColor:'rgb(144,251,220)!important'
    },
    navdark:{
      backgroundColor:'rgb(0,0,0)'
    },
    header:{
      borderBottom: '0 none !important'
    },
    footer:{
      borderTop:'0 none !important'
    },
    name:{
      fontFamily:'sans-serif',
      fontWeight:'bold'
    }
}
  return (
    <div className={`w-100 h-100 bg-light ${classes.minHeight}`}>
      {console.log(isBought,checkoutToken,'hai....')}
      <nav className="navbar navbar-expand-lg" style={styles.navdark}>
            <div className="container">
                <div className='d-flex align-items-center text-light'>
                  <Link to='/user' className="no-decor"><div className="d-flex me-5 align-items-end mb-2 "><b><i className="fa-solid fa-arrow-left"></i></b></div></Link>
                </div>
                <div className='navbar-brand d-flex'>
                        <div className={`${classes.img_fit} ${classes.logo} me-3 animate__animated animate__fadeInDown`}>
                        </div>
                </div> 
                
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className={`navbar-toggler-icon `} style={{color:'white'}}><i className="fa-solid fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse  d-lg-flex justify-content-between align-items-center" id="navbarSupportedContent">
                        <ul className="navbar-nav mt-4 mt-lg-0 m-auto mb-2 mb-lg-0 w-adjust d-flex flex-column flex-lg-row justify-content-lg-end align-items-lg-center">
                            <li className={`nav-item my-2 my-lg-0 mb-3 mb-lg-0 mx-lg-4 ${classes.language}`}style={{color:'white'}}>
                                <Select 
                                  value={selectedType}
                                  onChange={(e)=>{setType(e);i18n.changeLanguage(e.value)}}
                                  options={options}
                                  required
                                  styles={{
                                    control:(bs)=>({
                                        ...bs,
                                        backgroundColor:'black',
                                        border:'none'
                                    }),
                                    singleValue:(bs)=>({
                                        ...bs,
                                        color:'white !important',
                                        fontWeight:'bold'
                                    }),
                                    menu:(bs)=>({
                                        ...bs,
                                        color:'black !important',
                                        
                                    })
                                  }}
                                />
                            </li>
                            <li className='nav-item my-2 my-lg-0 mb-3 mb-lg-0 mx-lg-4'>
                              <Link className='no-decor align-start' to='/student/profile'>
                                <div style={styles.image} className='profile-icon' ></div>
                              </Link>
                            </li>
                        </ul>
                    </div>
            </div>
        </nav>
        {isBought&&
          <div className='container my-5'>
            <div className='row my-5'>
                <div className='col-12 my-5 py-5 d-flex flex-column align-items-center justify-content-center'>
                    <h2 className='text-center text-success'><big><i className="fa-solid fa-circle-check"></i></big></h2>
                    <h2 className='text-center mt-2 mb-5'>{t('Payment.17')}</h2>
                    <Link className='no-decor' to='/user'><button className='btn btn-success'>{t('Payment.18')}</button></Link>
                </div>

            </div>
          </div>}
        {!isBought&&
          <div className='container my-5 py-5'>
            <div className='row'>
                <div className='col-12 d-flex flex-column col-lg-9 p-2'>
                    <h2 style={styles.heading}><b>{t('Payment.1')}</b></h2>
                    <div className={`w-100 py-2 d-flex flex-column justify-content-center align-items-center`} style={{position:'relative'}}>
                      {courses.length<=0&&
                      <div className='py-5'>
                        <i><big>{t('Payment.2')}</big></i>
                      </div>}
                      <div className={`${classes.scrollList} w-100 py-2 d-flex flex-column align-items-center `} id='cart-slider'>
                        {courses.map(i=>
                            <div className={`w-100 row my-2`}>
                              <div className='col-4 col-lg-3 rounded my-3 d-flex align-items-center '>
                                <div className={`${classes.image} animate__animated animate__fadeInLeft rounded`} style={{backgroundImage:`url(${i.avatar})`}}>
                                </div>
                              </div>
                              <div className='col-7 my-3 d-flex justify-content-between align-items-start flex-column'>
                                <h4 style={styles.name}>{i.name}</h4>
                                <div><i><big>{t('Payment.13')}{i.price}</big></i></div>
                                <div><button className='btn btn-danger my-1' onClick={removeFromCart.bind(null,i)}><b>{t('Payment.14')}</b></button></div>
                              </div>            
                          </div>)}
                        
                      </div>
                      <div className={`${classes.down} w-100 d-inline-block`}><h4 className='w-100'><div className={`${classes.scrollingLine} d-inline-block border`}></div><i onClick={slideDown} style={{cursor:'pointer'}} className="fa-solid fa-circle-chevron-down mx-1 align-bottom"></i><div className={`${classes.scrollingLine} d-inline-block border`}></div></h4></div>
                    </div>

                    <h2 className='my-5 py-5' style={styles.heading}><b>{t('Payment.3')}</b></h2>
                    <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
                          {history.length==0&&
                          <i><big>{t('Payment.15')}</big></i>}
                          {history.map(i=>
                          <div className='py-2 border-bottom row w-100 d-flex flex-nowrap my-3' style={{fontFamily:'monospace'}}>
                            <div className='col-4'><i className="fa-solid fa-circle-dot text-primary"></i> <b>{i.name}</b></div>
                            {i.type=='Payment'&&
                            <div className='col-3 text-warning'>
                              <i className='fa-solid fa-circle'></i> {t('Payment.16')}
                            </div>
                            }
                            <div className='col-2'>{i.posting_day}</div>
                            <div className='col-2'>
                              {i.type=='Payment'&&<b className='text-danger'>- {i.price}</b>}
                            </div>
                          </div>)}
                    </div>
                    
                </div>
                <div className='col-12 col-lg-3 d-flex flex-column my-5 my-lg-0'>
                    <h2 style={styles.heading}><b>{t('Payment.4')}</b></h2>
                    <div className='w-100 rounded shadow-lg p-3 my-5 d-flex flex-column align-items-center'>
                      <div className='w-100 d-flex flex-column align-items-center py-2 border-bottom'>
                        <div style={styles.image} className='profile-icon mt-4' ></div>
                        <div className='my-2'><big>{auth.user.name}</big></div> 
                      </div>

                      <div className='w-100 d-flex flex-column align-items-center py-2 border-bottom'>
                        <div className='my-2 text-secondary'>{t('Payment.5')}</div>
                        <div className='my-1 text-primary'><big><b>${final_amount}</b></big></div>
                      </div>

                      <div className='w-100 d-flex flex-column align-items-center py-2 border-bottom'>
                        <div className='my-2 text-dark'><b>{t('Payment.6')}</b></div>
                        <div className='my-1 w-100 d-flex justify-content-between'>
                            <div>{t('Payment.7')}:</div>
                            <div>${price}</div>
                        </div>
                        <div className='my-1 w-100 d-flex justify-content-between'>
                            <div>{t('Payment.8')}:</div>
                            <div>${tax}</div>
                        </div>
                            <i className="fa-solid fa-horizontal-rule" ></i>
                        <div className='my-1 w-100 d-flex justify-content-between'>
                            <div>{t('Payment.9')}:</div>
                            <div>${discount}</div>
                        </div>
                        <div className='my-3 w-100 d-flex justify-content-center flex-nowrap'>
                            <div className='mx-1'><Form.Control placeholder={`${t('Payment.10')}`}/></div>
                            <div><button className='mx-1 px-2 btn btn-success' type='button' onClick={handlePromo}>{t('Payment.11')}</button></div>
                        </div>
                      </div>

                      <div className='w-100 d-flex flex-column align-items-center py-2 border-bottom'>
                        <div className='my-2 w-100 d-flex justify-content-center'>
                          <button type='submit' disabled={isBuying} onClick={handleSubmit} className={`btn d-inline-block w-75 ${classes.purple}`} >{t('Payment.12')} <i className="fa-solid fa-credit-card mx-2"></i></button>
                        </div>
                        <div className='my-2'>
                          {/* <GooglePayButton
                                environment="TEST"
                                paymentRequest={{
                                  apiVersion: 2,
                                  apiVersionMinor: 0,
                                  allowedPaymentMethods: [
                                    {
                                      type: 'CARD',
                                      parameters: {
                                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                        allowedCardNetworks: ['MASTERCARD', 'VISA',"AMEX"],
                                      },
                                      tokenizationSpecification: {
                                        type: 'PAYMENT_GATEWAY',
                                        parameters: {
                                          gateway: 'example',
                                          gatewayMerchantId: 'exampleGatewayMerchantId',
                                        },
                                      },
                                    },
                                  ],
                                  merchantInfo: {
                                    merchantId: '12345678901234567890',
                                    merchantName: 'Demo Merchant',
                                  },
                                  transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: '100.00',
                                    currencyCode: 'USD',
                                    countryCode: 'US',
                                  },
                                }}
                                onLoadPaymentData={paymentRequest => {
                                  console.log('load payment data', paymentRequest);
                                }}
                              /> */}
                        </div>
                        
                      </div>

                    </div>
                </div>
            </div>
          </div>}
                      
    </div>
  )
}

export default Payment