import React from 'react';
import {Navigate} from 'react-router-dom';
import AgoraAppBuilder from "@appbuilder/web";
import { useAuth } from '../../../providers/ProvideAuth';

const VideoCall = () => {    
    var auth = useAuth();
    return (
        <>
        {auth.user?
            <div style={{minHeight:'100vh',minWidth:'100vw'}}>
            {console.log(AgoraAppBuilder)}
            <app-builder id='agora_app'/>
           </div>
        :
            <Navigate to='/'/>}
        </>
    )
}

export default VideoCall