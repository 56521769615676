import React from 'react'
import classes from '../../../styles/ChatBox.module.css';
import ScrollableChat from "./ScrollableChat";
import Form from 'react-bootstrap/Form';
import  toast  from 'react-hot-toast';
import { useEffect,useState } from "react";
import { useAuth } from '../../../providers/ProvideAuth';
import { TokenKey } from '../../../utils';
import ProfileModal from './ProfileModal';
import UpdateGM from './UpdateGM';
import io from 'socket.io-client';
import ChatFileShare from './ChatFileShare';
const ENDPOINT = 'https://scholarist.com:8000/chats';
var socket,selectedChatCompare;

const ChatBox = (props) => {
  const {user,setChats,selectedChat,setSelectedChat} = useAuth();
    const [show,setShow] = useState(false)
    //for messages
    var [messages,setMessages] = useState([]);
    const [sendShow,setSendShow] = useState(false);
    var [newMessage,setNewMessage] = useState()
    var [loading,setLoading] = useState(false)
    var [socketConnected,setSocketConnected] = useState(false)
    const handleClose = () => {setShow(false);
    } ;
    const handleShow = ()=>{setShow(true);
    } ;
    const handleCloseSend = () => {setSendShow(false);
    } ;
    const handleShowSend = ()=>{setSendShow(true);
    } ;
    useEffect(()=>{
        socket = io(ENDPOINT)
        socket.emit('setup',user);
        socket.on('connection',()=>{
            setSocketConnected(true)
        })
    },[])
    useEffect(()=>{
        fetchMessages()

        selectedChatCompare = selectedChat;

    },[selectedChat])

    useEffect(()=>{
        socket.on('message received',async (message)=>{
            if(!selectedChatCompare||selectedChatCompare.id!=message.chat_id.id){
                //give notification
                
            }
            
            else {
             await setMessages([...messages,message])
             props.setFetch(!props.fetch)
            }
            console.log('message hua receive',message)
        })
        
    })
    async function goBack(){
      setSelectedChat(null);
    }
    function otherOne(item){

      if(!item.isGroupChat) {
          let other = item.users.filter((a)=>a.name!=user.name)
          let chat = {...item,name:other[0].name,avatar:other[0].avatar,role:other[0].role}
          return chat
      }
      else {
          let other = {...item,name:item.chatName,avatar:'https://us.123rf.com/450wm/keviz/keviz1802/keviz180200567/96530338-teamwork-people-working-together-icon-vector.jpg?ver=6'}
          return other
      }

  }
    
    async function fetchMessages(){
        if(!selectedChat)return;
        setLoading(true)
        try{
            let url = `https://scholarist.com:8000/api/getmessages/${selectedChat.id}`
            let response = await fetch(url,{
                method:'GET',
                headers:{
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                }
            })
            let data = await response.json()
            console.log(data,'fetch ka aaya')
            setMessages(data.data)
            setLoading(false)
            socket.emit('join room',selectedChat.id)
        }catch(err){
            console.log(err)
            return toast.error('Error in fetching messages')
        }
    }
    async function sendMessage(e){
        e.preventDefault();
        if(newMessage.length<=0){
            return toast.warn('Type something for message to be sent')
        }
        console.log('content',newMessage,selectedChat.id);
        try{
            let url = 'https://scholarist.com:8000/api/message'
            let response = await fetch(url,{
                method:'POST',
                headers:{
                    'Content-type':'application/json',
                    'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
                },
                body:JSON.stringify({content:newMessage,chat_id:selectedChat.id})
            })
            let data = await response.json()
            console.log(data,'send ka ayaa')
            await setMessages([...messages,data.data])
            setNewMessage("")
            socket.emit('new message',data.data)
            props.setFetch(!props.fetch);
        }catch(err){
            console.log(err)
            return toast.error('Error in sending messages')
        }
    }
    async function sendFileAsMessage(body){
      let form = new FormData();
      form.append('content',body.name);
      form.append('chat_id',selectedChat.id);
      form.append('file',body.file_path);
      form.append('isFile',1)
      try{
        let url = 'https://scholarist.com:8000/api/message'
        let response = await fetch(url,{
            method:'POST',
            headers:{
                'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
            },
            body:form
        })
        let data = await response.json()
        console.log(data,'send ka ayaa')
        await setMessages([...messages,data.data])
        setNewMessage("")
        socket.emit('new message',data.data)
        props.setFetch(!props.fetch);
        return;
    }catch(err){
        console.log(err)
        toast.error('Error in sending message')
        return;
    }
    }
  return (
    <div className='h-100 w-100'>
      <ChatFileShare  show={sendShow} handleClose={handleCloseSend} sendFile={sendFileAsMessage}/>
    {selectedChat?
    
    <div className={`h-100 text-white ${classes.container} d-flex flex-column`}>
      
      <div className={`w-100 ${classes.bg_dark}`}>
        <div className='row'>
          <div className='col-1 mx-2 d-flex justify-content-center align-items-center'>
            <big><b><i style={{cursor:'pointer'}} onClick={goBack} className="fa-solid fa-arrow-left"></i></b></big>
          </div>
          <div className='col-8 col-lg-6 py-3 d-flex justify-content-start align-items-center'>
            <div className={classes.profilePic} style={{backgroundImage:`url(${otherOne(selectedChat).avatar})`}}> </div>
            <div className='d-flex mx-4 flex-column justify-content-center'>
              <div><h5><b>{otherOne(selectedChat).name}</b></h5></div>
              <div><i>{otherOne(selectedChat).role==3?'Student':'Teacher'}</i></div>
            </div>
            
          </div>
    
          
          <div className='col-2 col-lg-3 d-flex justify-content-end align-items-center'>
          <big><i style={{cursor:'pointer'}} 
          className="fa-solid fa-circle-info"
          onClick={()=>setShow(true)} ></i></big>
          </div>
          
        </div>
      
      </div>

      <div className={classes.scrollMessages}>
          <div className={`h-100 d-flex flex-column`}>
                {loading?
                  <div className='d-flex w-100 h-100 justify-content-center align-items-center'><h3>Loading....</h3></div>
                  :
                  <div className={`px-2 h-100 d-flex flex-column`} style={{boxSizing:'border-box'}}>
                      <ScrollableChat messages={messages}/>
                  </div>}
              
                
            </div>
          
      </div>
      <div className={`${classes.inputDiv} w-100 p-3 d-flex justify-content-center flex-nowrap`}>
                    <div className={`me-1 w-75`}>
                    <Form className='w-100' onSubmit={sendMessage}>
                            <Form.Control
                            placeholder="Type something..."
                            aria-label="Users"
                            aria-describedby="basic-addon1"
                            onChange={e=>setNewMessage(e.target.value)}
                            value={newMessage||''}
                            />
                    </Form>
                    </div>
                    <div className={`mx-4 d-flex align-items-center justify-content-center`} onClick={handleShowSend}>
                    <i className="fa-solid fa-paperclip" style={{cursor:'pointer'}}></i>
                    </div>
                    <div className={`${classes.div2} mx-2 px-3 rounded d-flex align-items-center justify-content-center`}  onClick={sendMessage}>
                    <i className={`${classes.icon} fa-solid fa-paper-plane`}></i>
                    </div>
                    

                </div>
      {selectedChat.isGroupChat ? <UpdateGM show={show} handleClose={handleClose}/>:<ProfileModal show={show} handleClose={handleClose} />}
  </div>
    :
    <div className="h-100 w-100 d-flex justify-content-center align-items-center text-white">
            <big><i>Click a chat to start conversation</i></big>
    </div>}
    </div>
    
  )
}

export default ChatBox