import Modal from 'react-bootstrap/Modal';
import classes from '../styles/Navbar.module.css';
import {useState,useEffect} from 'react';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import {Link,useNavigate} from 'react-router-dom';
import { useAuth } from '../providers/ProvideAuth';
import  toast  from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';
import {LoginSocialFacebook} from 'reactjs-social-login';
import { useTranslation } from 'react-i18next';
import AppleSignin from 'react-apple-signin-auth';

function SignUp(props){

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [pwd,setPWD] = useState("");
    const [cpwd,setCPWD] = useState("");
    const [role,setRole] = useState("3")
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedLanguage,setSelectedLanguage] = useState({ value: 'english', label: 'English' });
    const [selectedOption, setSelectedOption] = useState();
    const [validated, setValidated] = useState(false);
    let [agree,setAgree] = useState(0);
    let [verified, setVerified] = useState(null);
    const auth = useAuth();
    const navigate = useNavigate();
    let {t,i18n} = useTranslation();

    const options = [
        { value: 'english', label: 'English' , code:'en' },
        { value: 'hindi', label: 'Hindi', code:'hi' },
        { value: 'chinese', label: 'Chinese', code:'ch' },
        { value: 'russian', label: 'Russian' , code:'ru'},
        { value: 'spanish', label: 'Spanish', code:'es' },
        { value: 'arabic', label: 'Arabic' , code:'ar'}
      ];
      
    useEffect(() => {
        fetch(
          "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
        )
          .then((response) => response.json())
          .then((data) => {
            setCountries(data.countries);
            setSelectedCountry(data.userSelectValue);
          });
        
      }, []);


    function onChange(value) {
        setVerified(true);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        
        setValidated(true);
        console.log(selectedCountry)
        if(!name||!email||!pwd||!cpwd||(pwd!=cpwd)){
            return toast.error('Please fill all details and ensure password matches confirm password');
        }
        if(!agree){
            return toast.error("You cannot register with Scholairst if you don't agree with Terms and Privacy Policy")
        }
        
        //Think about recaptcha verification
        let res = await auth.signup({email:email.trim(),password:pwd,name:name.trim(),role,country:selectedCountry.label,language_name:selectedLanguage.label})
        if(res.success){
            props.onHide();
            return toast(
                (t) => (
                    <span>
                      We got your details. Check you email for verification before accessing our services.
                      <span style={{cursor:'pointer'}} className='text-primary' onClick={()=>toast.dismiss(t.id)}> Click me to dismiss this notification</span>
                    </span>
                  ),
                  {
                    icon: '🔥',
                    duration:Infinity
                  }
            );
        }
        else{
            toast.error('There has been an error with signing you up. Please contact support');
            setEmail('');
            setName('');
            setPWD('');
            setCPWD('');
        }
    };
    const googleLogin = useGoogleLogin({
        onSuccess: async({code}) => {
            let reply = await auth.googleLogin(code);
            if(reply.success){
                toast.success(`Welcome ${reply.name}`)
                return navigate('/user',{replace:true})
            }else{
                toast.error('Invalid credentials');
            }
        },
        flow:'auth-code'
    });
    const handleFbLogin = async(res)=>{
        
        let reply = await auth.facebookLogin(res.data.accessToken);
        if(reply.success){
            toast.success(`Welcome ${reply.name}`)
            return navigate('/user',{replace:true})
        }else{
            toast.error('Invalid credentials');
        }

    };

    const verifyBackEnd = async(res)=>{
        
        let reply = await auth.appleLogin(res);
        if(reply.success){
            await navigate('/user',{replace:true})
        }
        else{
            return toast.error('Error in logging in user')
        }
    }

    const authFailed = (err)=>{
        console.log(err)
        return toast.error('Error in logging in the user');
    }
    return(
        <>
            <Modal {...props} centered>
                {console.log(agree,'[]}}}')}
                <Modal.Header closeButton className='border-0'>
                <Modal.Title>{t('Auth.21')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='py-1'>
                <Form noValidate validated={validated} onSubmit={handleSubmit} className='w-100 px-2'>
                    <Form.Group className='my-3'>
                        <Form.Label>{t('Auth.7')}</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Sam Martin"
                                onChange={(e)=>setName(e.target.value)}
                            />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>{t('Auth.2')}</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="abc@example.com"
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>{t('Auth.3')}</Form.Label>
                        <Form.Control
                                required
                                type="password"
                                onChange={(e)=>setPWD(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className='my-3'>
                        <Form.Label>{t('Auth.8')}</Form.Label>
                        <Form.Control
                                required
                                type="password"
                                onChange={(e)=>setCPWD(e.target.value)}
                        />
                    </Form.Group>
                        <div className='my-3'>
                            <label for='country'>{t('Auth.9')}</label>
                            <Select
                                id='country'
                                options={countries}
                                value={selectedCountry}
                                onChange={(option) => setSelectedCountry(option)}
                                required
                            />
                            
                        </div>

                        <div className='my-3'>
                            <label for='language'>{t('Auth.10')}</label>
                            <Select 
                                id='language'
                                defaultValue={selectedLanguage}
                                onChange={(e)=>setSelectedLanguage(e)}
                                options={options}
                                required
                            />

                        </div>
                        <Form.Group className='my-4'>
                        <h5 className='my-2'>{t('Auth.11')}</h5>
                        <div key={`default-radio`} className="mb-3">
                            <Form.Check 
                                inline
                                type='radio'
                                id={`student-radio`}
                                label={t('Auth.12')}
                                name='role'
                                value='3'
                                checked={role=='3'}
                                onChange={(e)=>setRole(e.target.value)}
                            />

                            <Form.Check
                                inline
                                type='radio'
                                label={t('Auth.13')}
                                id={`teacher-radio`}
                                name='role'
                                value='2'
                                checked={role=='2'}
                                onChange={(e)=>setRole(e.target.value)}
                            />
                        </div>
                    </Form.Group>
                    <Form.Group className='my-4'>
                        <div key={`default-radio-2`} className="mb-3">
                            <Form.Check 
                                inline
                                type='radio'
                                id={`agree-radio`}
                                name='agree'
                                value='1'
                                checked={agree==1}
                                onChange={(e)=>setAgree(e.target.value)}
                            />
                            {t('Auth.14')} <Link to='/terms' target='_blank'>{t('Auth.15')}</Link> & <Link to='/privacy' target='_blank'>{t('Auth.16')}</Link>
                        </div>
                    </Form.Group>
                        <div className='my-3'>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={onChange}/>
                        </div>

                        <div className='my-4 d-flex justify-content-center text-primary'>
                            <b>{t('Auth.17')}</b>
                        </div>
                        
                        <div className='my-4'>
                            <button type='submit' className={`w-100 btn rounded-pill ${classes.submit}`}>{t('Auth.18')}</button>
                        </div>

                        <div className='my-2 d-flex justify-content-center align-items-center'>
                            <div className={`${classes.line} mx-1`}></div>
                            <div>OR</div>
                            <div className={`${classes.line} mx-1`}></div>
                        </div>
                    </Form>

                    <div className='w-100 d-flex justify-content-center my-2' >
                        <div onClick={()=>googleLogin()} className={`rounded-pill border my-3 m-auto ${classes.googlepill}`}>
                        <div className={classes.iconGoogle}></div>{t('Auth.19')}</div>
                    </div>
                    <div className='w-100 d-flex justify-content-center' >
                        <LoginSocialFacebook
                            isOnlyGetToken
                            appId={process.env.REACT_APP_FB_ID}
                            onResolve={(data)=>handleFbLogin(data)}
                            onReject={(err) => {
                                console.log(err)
                              }}
                            className='w-100 d-flex justify-content-center'
                        >
                        <div className={`rounded-pill border ${classes.bg_fb} ${classes.fbpill} text-white`}>
                            <div className={classes.iconFacebook}></div>{t('Auth.20')}
                        </div>
                        </LoginSocialFacebook>
                        
                    </div>
                    
                    <div className='w-100 d-flex justify-content-center my-2'>
                        <AppleSignin

                            authOptions={{
                            clientId: 'com.scholarist.auth',       
                            scope: 'email name',                         
                            redirectURI: 'https://scholarist.com/',
                            usePopup:true
                            }} 
                            uiType="dark"
                            className="apple-auth-btn"
                            noDefaultStyle={false}
                            buttonExtraChildren="Continue with Apple"
                            /** Extra controlling props */
                            /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                            onSuccess={(response) => verifyBackEnd(response)} // default = undefined
                            /** Called upon signin error */
                            onError={(error) => authFailed(error)} // default = undefined
                            /** Skips loading the apple script if true */
                            skipScript={false} // default = undefined
                            /** Apple image props */
                            iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                            /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
                            render={(props) => <div {...props} className={`rounded-pill border my-3 m-auto ${classes.applepill}`}>
                            <div className={classes.iconApple}></div>{t('Auth.30')}</div>}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className='border-0'>
                
                </Modal.Footer>
            </Modal>

        </>
    )
}
export default SignUp;