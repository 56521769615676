import React from 'react'
import { useTranslation } from 'react-i18next'

const Privacy = () => {

    let {t,i18n} = useTranslation();
    return(
    
            <div className='container-fluid terms py-5'>
                <div className='container'>
            <div className='row py-5 rounded d-flex justify-content-center'>
                <div className='col-10  bg-white d-flex flex-column '>
                <h1 className='my-2'>{t('Privacy.28')}</h1>
                    <p>{t('Privacy.1')}{t('Privacy.2')}</p>
                
                <h3 className='my-3'><b>{t('Privacy.29')}</b></h3>
                <div>
                    <p>{t('Privacy.3')}</p>
                    <ul className='d-inline-block my-1'>
                        <li>{t('Privacy.4')}</li>
                        <li>{t('Privacy.5')}</li>
                        <li>{t('Privacy.6')}</li>
                        <li>{t('Privacy.7')}</li>
                        <li>{t('Privacy.8')}</li>
                    </ul>
                </div>

                <h3 className='my-3'><b>{t('Privacy.30')}</b></h3>
                <div>
                    <p>{t('Privacy.9')}</p>
                    <ul className='d-inline-block my-1'>
                        <li>{t('Privacy.10')}</li>
                        <li>{t('Privacy.11')}</li>
                        <li>{t('Privacy.12')}</li>
                        <li>{t('Privacy.13')}</li>
                        <li>{t('Privacy.14')}</li>
                        <li>{t('Privacy.15')}</li>
                    </ul>
                </div>

                <h3 className='my-3'><b>{t('Privacy.31')}</b></h3>
                <div>
                    <p>{t('Privacy.16')}</p>
                    <ul className='d-inline-block my-1'>
                        <li>{t('Privacy.17')}</li>
                        <li>{t('Privacy.18')}</li>
                        <li>{t('Privacy.19')}</li>
                        
                    </ul>
                </div>

                <h3 className='my-3'><b>{t('Privacy.32')}</b></h3>
                <div>
                    <p>{t('Privacy.20')}</p>
                    <ul className='d-inline-block my-1'>
                        <li>{t('Privacy.21')}</li>
                        <li>{t('Privacy.22')}</li>
                        <li>{t('Privacy.23')}</li>
                        <li>{t('Privacy.24')}</li>
                    </ul>
                </div>

                <h3 className='my-3'><b>{t('Privacy.33')}</b></h3>
                <div>
                    <p>{t('Privacy.25')}</p>
                </div>
                
                <h3 className='my-3'><b>{t('Privacy.34')}</b></h3>
                <div>
                    <p>{t('Privacy.26')}</p>
                </div>

                <h3 className='my-3'><b>{t('Privacy.35')}</b></h3>
                <div>
                    <p>{t('Privacy.27')} <a target='_blank' href='mailto:scholaristicg@gmail.com'>scholaristicg@gmail.com</a></p>
                </div>
                </div>
            </div>
            </div>
            </div>

    )
}

export default Privacy