import {createContext} from 'react';
import { useProvideStudent } from './ProvideStudent';
var initialState={
    courses:[],
    setCourses:()=>{}
}

export let StudentContext = createContext(initialState);

export function StudentProvider({children}){
    var Student = useProvideStudent();
    return (
        <StudentContext.Provider value = {Student}>
            {children}
        </StudentContext.Provider>
    )

}