import React,{useState,useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast';
import Form from 'react-bootstrap/Form';
import classes from '../styles/Navbar.module.css';
import { useTranslation } from 'react-i18next';
function UnsubscribeNewsLetter() {
    
    const navigate = useNavigate();
    let {t,i18n} = useTranslation()
    let [valid,setValid] = useState(true);
    let [email,setEmail] = useState('');
    let [pwd,setPWD] = useState('');
    let [finishing,setFinishing] = useState(false);
    

    async function handleUnsubscribe(e){
        e.preventDefault();
        if(!pwd||!email){
            return toast.error('Please fill all fields')
        }

        setFinishing(true)
        let reply = await fetch(`https://scholarist.com:8000/api/unsubscribe/newsletter`,{
            method:'POST',
            headers:{
                'Content-type':'application/json'
            },
            body:JSON.stringify({password:pwd,email:email})
        });
        let response = await reply.json();
        if(response.success){
    
            toast.success('Unsubscribed from newsletter successfully!');
            return navigate('/')
        }
        else {
            toast.error(`${response.message}`)
        }
        setFinishing(false)
        return navigate('/')
    }

    return (
        <div className='container d-flex justify-content-center align-items-center my-5'>
            <div className='row w-100 my-5 d-flex justify-content-center'>
                <div className='col-12 col-md-6 d-flex flex-column justify-content-center min-height-100 py-5'>

                    <>
                        <Form onSubmit={handleUnsubscribe} className='w-100 p-4 d-flex flex-column'>
                            <Form.Group controlId="validationCustom01" className='my-4'>
                                <Form.Label>{t('ContactUs.4')}</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        onChange={(e)=>setEmail(e.target.value)}
                                    />
                            </Form.Group>
                            <Form.Group controlId="validationCustom01" >
                                <Form.Label>{t('Auth.3')}</Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        onChange={(e)=>setPWD(e.target.value)}
                                    />
                            </Form.Group>
                            <div className='mt-5'>
                                    <button type='submit' disabled={finishing} className={`w-100 btn rounded-pill ${classes.submit}`}>
                                    {t('Auth.28')}</button>
                            </div>
                        </Form>
                    </> 
                    
                </div>
            </div>
        </div>
    )
}

export default UnsubscribeNewsLetter