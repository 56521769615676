import React, {useEffect,useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import toast from 'react-hot-toast';
import { TokenKey } from '../../../utils';
import Form from 'react-bootstrap/Form';
import CloseButton from 'react-bootstrap/CloseButton';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { FaCaretLeft, FaCaretRight, FaForward, FaBackward } from 'react-icons/fa';
import {HiBadgeCheck} from 'react-icons/hi';
import {MdSort} from 'react-icons/md';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { blue } from '@mui/material/colors';
import classes from '../../../styles/PageContent.module.css';

dayjs.extend(utc)
dayjs.extend(timezone)

const Invoice = () => {

    const auth = useAuth();
    let {t,i18n} = useTranslation();
    let [selectedType,setType] = useState( { value: 'en', label: '🇺🇸  English' });
    const [searchResults,setSearchResults] = useState(null)
    const navigate = useNavigate();
    const options = [
        { value: 'en', label: '🇺🇸  English' },
        { value: 'fr', label: '🇫🇷  French' },
        { value: 'es', label: '🇪🇸  Spanish' },
        { value: 'zh_CN', label:'🇨🇳  Chinese'}
      ];
    let date = dayjs();
    
   
    function slideLeft(){
        var slider = document.getElementById('scrollAble');
        slider.scrollLeft = slider.scrollLeft-173

    }
    function slideRight(){
        var slider = document.getElementById('scrollAble');
        slider.scrollLeft = slider.scrollLeft+173

    }

    

    const handleSearch = async (e)=>{
        
        if(e.target.value.length<=0){
            setSearchResults(null)
            return 
        }
        setSearchResults(['Add more data to website from teachers to start searching']);
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    
  return (
    <div id="page-content-wrapper">
            
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-0 px-md-4 text-light d-flex flex-nowrap justify-content-between">
                <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    <h2 className={`mb-1 poppins`}><b style={{fontWeight:'bolder'}}>Invoice</b></h2>
                </div>
                <div className='w-25 d-none d-lg-inline-block' style={{position:'relative'}}>
                    <Form.Control
                        type="text"
                        placeholder={`${t('PageContent.6')}...`}
                        className='rounded px-4 py-2'
                        style={{backgroundColor:'rgb(0,0,0)',border:0}}
                        onChange={handleSearch} 
                    />
                    <div className={`w-100 rounded ${searchResults? classes.searchDropdown:classes.searchDropdownInvisible} mb-3`}>
                        <div className='row'>
                            <div className='col-11 d-flex justify-content-end p-1 py-2' style={styles.label} >
                                <CloseButton className='text-light' onClick={()=>setSearchResults(null)} />
                            </div>
                        </div>
                                        
                        {searchResults?<>{searchResults.length>0?searchResults.map((item)=>
                            <>
                                        
                                <div className={`row ${classes.searchBarResults} d-flex flex-column justify-content-center align-items-center`}>
                                    <div className="col-8 ">
                                        <div style={styles.label}><i>{item}</i></div>
                                    </div>
                                </div>
                            </>
                                            
                            ):
                                <>
                                    <div className='row text-dark'>
                                        <div className=' my-2 col-12 d-flex justify-content-center'>
                                            <i>Nothing to report</i>  
                                        </div>
                                                
                                    </div>
                                </>}</>
                            :
                            <></>
                         }
                    </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div style={styles.mail} className='me-2'>
                        <i className="text-light fa-regular fa-envelope"></i>
                    </div>

                    <div style={styles.mail} className='me-2'>
                        <i className="text-light fa-regular fa-bell"></i>
                    </div>
                    
                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                       <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </div>
            </nav>

            <div className='container-fluid my-5 py-5'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-11 rounded bg-light'>
                         <div className='row p-2'>
                            <div className='col-12 d-flex justify-content-center'>
                                <h2>Invoice</h2>
                            </div>
                         </div>
                         <div className={`row d-flex flex-nowrap pt-4 pb-2 poppins border-bottom ${classes.scroll}`} style={{backgroundColor:'rgb(238,239,239)'}}>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                No.
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                Invoice Subject
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                Client
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                VAT No.
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                Date
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                Status
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                Price
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                Action
                            </div>
                         </div>
                         {[1,2,3,4,5,6,7,8,9,10].map(i=>
                            <div className={`row d-flex flex-nowrap pt-4 pb-2 poppins border-bottom ${classes.scroll}`}>
                                <div className='col-2  d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div>EKG464SJFN17</div>
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div>Design Works</div>
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div>🇮🇳 Arun Patel</div>
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div>87956621</div>	
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div>15 Dec, 2017</div>
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div> <i class="fa-solid fa-circle text-success fa-2xs"></i> Approved</div>
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <div>$887.21</div>
                                </div>
                                <div className='col-2 d-flex justify-content-center align-items-center' style={{color:'rgb(164,167,169)'}}>
                                    <button style={styles.button} className='btn rounded-pill'>Download</button>
                                    <i className="fa-solid fa-ellipsis-vertical mx-3" style={{color:'rgb(32,95,223)', cursor:'pointer'}}></i>
                                </div>
                            </div>)}
                         <div >

                         </div>
                    </div>
                </div>
            </div>

        </div>
  )
}

const styles={
    mail:{
        height:'2.5rem',
        width:'2.5rem',
        borderRadius:'1.25rem',
        backgroundColor:'rgb(0,0,0)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    button:{
        color:'rgb(32,95,223)',
        backgroundColor:'rgb(241,246,254)',
        fontWeight:900
    }
}
export default Invoice