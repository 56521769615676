import React, { useEffect, useState } from 'react';
import {useAuth} from '../../../providers/ProvideAuth'
import {Link,useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-date-picker';
import Select from "react-select";
import toast from 'react-hot-toast';
import { useStudent } from '../../../providers/ProvideStudent';
import { TokenKey } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { MdSort } from 'react-icons/md';
const Support = () => {

  const auth = useAuth();
  let [name,setName] = useState("");
  let [description,setDesc] = useState("");
  let [selectedType,setType] = useState({ value: 'pdf', label: 'PDF' });
  let [file_path,setSelectedFile] = useState("");
  let student = useStudent();
  let {t,i18n} = useTranslation();
  let navigate = useNavigate()

  useEffect(()=>{
    var el = document.getElementById("wrapper");
    var toggleButton = document.getElementsByClassName("menu-toggle");
    for (var ele of toggleButton){
        ele.onclick = function () {
            el.classList.toggle("toggled");
        };
    }
  },[])
  async function handleSubmit(e){
    e.preventDefault();
    if(!name||!description){
        return toast.error('Please fill title and description')
    }
    let form = new FormData();
    form.append('name',name);
    form.append('description',description);
    form.append('file',file_path)
    let reply = await fetch('https://scholarist.com:8000/api/support',{
      method:'POST',
      headers:{
        'Authorization':`Bearer ${localStorage.getItem(TokenKey)}`
      },
      body: form
    })

    let result = await reply.json();
    
    if(result.success){
        console.log(result.data,'khudaui jai...')
        setName('');
        setDesc('');
        setSelectedFile('')
        return toast.success('File has been uploaded successfully!')
    }
    else{

        return toast.error(`${result.message}`);
    }

}

async function handleLogout(){
  let res=await auth.logout();
  toast.success('Logged out successfully');
  return navigate('/',{replace:true})
}

function handleDescChange(e){
    const {value,maxLength} = e.target;
    const words = value.slice(0,maxLength);
    setDesc(words)
}

  const styles={
    image:{
        backgroundImage:`url(${auth.user.avatar})`
    }
  }
  return (
    <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-3'/></a>
                    </div>
                    <h2 className="m-0">{t('Support.1')}</h2>
                    <div className='no-decor align-start d-flex align-items-center py-2 px-3' onClick={handleLogout}>
                      <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                </nav>

                <div className='container-fluid px-4'>
                  <div className='row d-flex justify-content-center my-5'>
                      <div className='col-12 my-3'>
                          <div className='w-100 bg-blue d-flex flex-column rounded align-items-start text-white p-2 px-5'>
                              <h3 className='my-3'><i className='fa-solid fa-headset me-4'></i>{t('Support.2')}</h3>
                              <p>{t('Support.3')}</p>
                          </div>
                      </div>
                  </div>

                  <div className='row d-flex justify-content-center my-5'>
                    <div className='col-12 col-lg-8 my-3'>
                      <div className='rounded bg-white  d-flex flex-column justify-content-center my-5 border' >
                          <h3 className='w-100 p-3'>{t('Support.4')}</h3>
                          <div className='bg-customgrey border-top w-100 p-3'>
                            <Form className='my-4 row d-flex flex-column align-items-center justify-content-between'>
                            <div className='col-12 col-md-8 my-2'>
                              <Form.Group>
                                <Form.Label>{t('Support.5')}</Form.Label>
                                <Form.Control
                                  placeholder="Assignment-1"
                                  aria-label="Assignment-1"
                                  aria-describedby="basic-addon1"
                                  onChange={e=>setName(e.target.value)}
                                  value={name}
                                  required
                                />
                              </Form.Group>
                            </div>       

                            
                            <div className='col-12 col-md-8 my-2'>
                              <Form.Group >
                                <Form.Label>{t('Support.7')}</Form.Label>
                                  <Form.Control as="textarea" rows={8} 
                                    maxLength={350}
                                    value={description}
                                    onChange={handleDescChange}
                                    required
                                  />
                              </Form.Group>
                            </div>                                                                        

                            

                            <div className='col-12 col-md-8 my-3'>
                              <Form.Label>Any additional files (supports .png,.jpeg,.pdf,.docx,.mp4)</Form.Label>
                              <Form.Control
                                  type='file'
                                  onChange={e=>setSelectedFile(e.target.files[0])}
                                  accept='.png,.jpg,.jpeg,.pdf,.mp4,.docx'
                              />
                            </div>                                         

                            <div className='col-12 mt-5 d-flex justify-content-center'>
                                <button type='button' onClick={handleSubmit} className='btn btn-success mx-4'>
                                {t('Support.8')}
                                </button>
                            </div>
                            </Form>
                          </div>
                      </div> 
                                                                           
                    </div>
                  </div>
                </div>
    </div>
  )
}

export default Support