import React, { useEffect, useState } from 'react'
import { useParams,Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/ProvideAuth';
import { useStudent } from '../../../providers/ProvideStudent';
import classes from '../../../styles/Lecture.module.css';
import dayjs from 'dayjs';
import Details from './Details';
import Form from 'react-bootstrap/Form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {MdSort} from 'react-icons/md';
import { Rating } from 'react-simple-star-rating'
const Lecture = () => {
    const auth = useAuth();
    const student = useStudent();
    const {course}=useParams();
    let navigate = useNavigate()
    let [lecture,setLecture] = useState({});
    const array = [1,2,3,4,5];
    let today=new Date(dayjs());
    let [upcoming,setUpcoming] = useState([]);
    let [expired,setExpired] = useState([]);
    let [resource,setResource] = useState({});
    let [reply,setReply] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    let [expiredModal,setExpiredModal] = useState(false);
    let [socketID,setSocketid]= useState('');
    let [grades, setGrades] = useState([]);
    let [editing, setEditing] = useState(false);
    let [description, setDesc] = useState('');
    let [rating,setRating] = useState(0);
    let [review,setReview] = useState(null);
    let {t,i18n} = useTranslation();
    
    useEffect(()=>{
        (
            
            async ()=>{
                let lecture_tb_displayed = {};
                for(let ele of student.courses){
                    if(ele.id==course){
                        lecture_tb_displayed = ele;
                        break;
                    }
                }
                await setLecture(lecture_tb_displayed);
                await getResources();
                await getGrades();
                await getReviews();
            }
        )();
    },[course])
    
    async function getResources(){
        try{
            let result = await student.getResources(course,lecture.teacher.name);
            if(result.success){
                let _upcoming = result.data.filter(i=>(new Date(i.expiry)).getTime()>today);
                let _expired = result.data.filter(i=>(new Date(i.expiry)).getTime()<today);
                
                setUpcoming(_upcoming);
                setExpired(_expired);
            }
        }catch(err){
            console.log(err)
        }
        
    }

    async function getGrades(){
        try{
            let result = await student.getGrades(course,lecture.teacher.UserId);
            if(result.success){
               setGrades(result.data); 
            }
        }catch(err){
            console.log(err)
        }
    }
    async function handleShow(id,status){
        
        setExpiredModal(status);
        setSocketid(lecture.subject.substring(0,3).toUpperCase()+(100+parseInt(lecture.id)))
        let result = await student.fetchResource(id);
        if(result.success){
            await setResource(result.data);
            if(result.reply){
                await setReply(result.reply);
                console.log(reply,'andar se');
            }
            setShowDetails(true)
        }
        else{
            return toast.error('Error in viewing resource!');
        }
    }
    async function getReviews(){
        try{
            let result = await student.getReview(course);
            if(result.success){
               await setReview(result.data); 
            }
        }catch(err){
            console.log(err)
        }
    }
    async function handleReview(){
        if(!description||!rating){
            return toast.error('Please give a description and atleast 1 star for lowest level')
        }
        
        let reply = await student.addReview(course,{description,rating})
        if(reply.success){
            setReview(reply.data)
            setEditing(false)
            return toast.success('Review added successfully')
        }
        else{
            return toast.error('Error in adding a review')
        }
    }

    async function handleRating(e) {
        setRating(e)
    }

    function handleDescChange(e){
        const {value,maxLength} = e.target;
        const words = value.slice(0,maxLength);
        setDesc(words)
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    const styles={
        profileIcon:{
            backgroundImage:`url(${auth.user.avatar})`
        },
        heading:{
            fontFamily:'Baloo'
        },
        neon:{
            color:'rgb(144,251,220)!important',
            borderColor:'rgb(144,251,220)!important'
        }
    }
    return(
        <>
            {console.log(review)}
            <Details show={showDetails}
            onHide={() => {setReply({});setResource({});setShowDetails(false);setExpiredModal(false)}} resource={resource} reply={reply} expiredModal={expiredModal} socketID={socketID}/>
            <div id="page-content-wrapper">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-md-5'/></a>
                    </div>
                    
                    <h2 className="m-0">{t('Classes.1')}</h2>

                    <div className='no-decor align-start d-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                       <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </nav>

                <div className='container-fluid text-white'>
                    <div className='row d-flex my-3 justify-content-center'>
                        <div className={`col-10 d-flex flex-column  ${classes.scroll}`}>
                            <div className={`py-2 text-light px-0 px-md-5 m-auto`}>
                                <ul className='nav d-flex flex-nowrap justify-content-between' id="pills-tab" role="tablist">
                                    <li className="nav-item " role="presentation" style={{cursor:'pointer'}}>
                                        <div className="nav-link active no-decor border-bottom px-4" type='button' id="pills-about-tab" data-bs-toggle="pill" data-bs-target="#pills-about" role="tab" aria-controls="pills-about" aria-selected="true">{t('Classes.2')}</div>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={getResources}  style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" type='button' id="pills-submissions-tab" data-bs-toggle="pill" data-bs-target="#pills-submissions" role="tab" aria-controls="pills-submissions" aria-selected="false">{t('Classes.4')}</div>
                                    </li>
                                    <li className="nav-item" role="presentation"  style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" type='button' id="pills-faq-tab" data-bs-toggle="pill" data-bs-target="#pills-faq" role="tab" aria-controls="pills-faq" aria-selected="false">{t('Classes.5')}</div>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={getReviews} style={{cursor:'pointer'}}>
                                        <div className="nav-link no-decor border-bottom px-4" type='button' id="pills-review-tab" data-bs-toggle="pill" data-bs-target="#pills-review" role="tab" aria-controls="pills-review" aria-selected="false">{t('Classes.19')}</div>
                                    </li>
                                </ul>    
                            </div> 
                        </div>
                        <div className="col-11 d-flex flex-column justify-content-center align-items-center my-3">
                            <div class="tab-content w-100" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
                                    <div className='row d-flex py-5 justify-content-center' data-masonry={{percentPosition: true }}>
                                        <div className='col-10 col-lg-5 col-xxl-3 order-2 offset-lg-1 my-3'>
                                            <div className='card py-4 px-2 animate__animated animate__fadeInLeft' style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                                                <div className='card-primary d-flex justify-content-center align-items-center flex-column'>
                                                    <div className={`m-auto ${classes.pp}`} style={{backgroundImage:`url(${lecture.teacher?lecture.teacher.avatar:''})`}}>

                                                    </div>
                                                    <h3 className='poppins my-3 mt-5'><b>{lecture.teacher?lecture.teacher.name:'Scholarist Course'}</b></h3>
                                                    <h5 className='poppins my-3'>{lecture.teacher?lecture.teacher.skills:'English/French'}</h5>
                                                    <p className='poppins my-3'>{lecture.teacher?lecture.teacher.description:''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-10 col-lg-6 col-xxl-3 my-3 '>
                                            <div className='card py-4 px-3 animate__animated animate__fadeInLeft'  style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                                                <div className='card-primary d-flex justify-content-center align-items-center flex-column'>
                                                    <div className={`${classes.course_image} mb-3`} style={{backgroundImage:`url(${lecture.avatar})`}}>
                                                    
                                                    </div>
                                                    <h2 style={styles.heading} className='text-center my-3'>
                                                        {lecture.name}
                                                    </h2>
                                                    
                                                    <p className='mt-5'>
                                                        {lecture.description}
                                                    </p>
                                                    <div className='my-4'>
                                                    <Link to={`/student/videocall/${course}`} target='_blank' className='no-decor' replace={true}><button type='button' className='btn px-3 btn-success'><b>{t('Classes.6')}</b></button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                                
                                <div class="tab-pane fade" id="pills-submissions" role="tabpanel" aria-labelledby="pills-submissions-tab">

                                    <div className='row d-flex justify-content-center my-5'>
                                        <div className='col-12 my-3'>
                                            <div className='w-100 bg-yellow d-flex flex-column rounded align-items-start text-white p-2 px-5'>
                                                <h3 className='my-3'><i className="fa-solid fa-circle-exclamation me-3"></i>{t('Classes.12')}</h3>
                                                <p>{t('Classes.13')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-light my-5 d-flex justify-content-center'>
                                        <div className='col-12 col-lg-10 col-xxl-8 d-flex flex-column'>
                                            <h2 style={styles.heading}>{t('Classes.14')}</h2>
                                            <div className='my-5'>
                                                <div className={`row d-flex flex-nowrap py-3 border-bottom ${classes.heading}`}>
                                                        <div className='col-2 col-lg-1'><b></b></div>
                                                        <div className='col-4 col-lg-3 d-flex align-items-center'><b>{t('Classes.7')}</b></div>
                                                        <div className='col-4 col-lg-2 d-flex align-items-center'><b>{t('Classes.8')}</b></div>
                                                        <div className='col-4 col-lg-2 d-flex align-items-center'><b>{t('Classes.15')}</b></div>
                                                        <div className='col-4 col-lg-2 d-flex align-items-center'><b>{t('Classes.16')}</b></div>
                                                </div>
                                                {upcoming.map((i,index)=>
                                                    <div className={`row d-flex flex-nowrap py-3 my-3 ${classes.tab}`} key={index}>
                                                        <div className='col-2 col-lg-1 d-flex align-items-center'>{index+1}</div>
                                                        <div className='col-4 col-lg-3 d-flex align-items-center'>{i.name}</div>
                                                        <div className='col-4 col-lg-2 d-flex align-items-center'>{i.posting_day.slice(0,10)}</div>
                                                        <div className='col-4 col-lg-2 d-flex align-items-center'>{i.expiry.slice(0,10)}</div>
                                                        <div className='col-4 col-lg-3 d-flex align-items-center'><button className='btn btn-success' onClick={handleShow.bind(null,i.id,false)} >{t('Classes.17')}</button></div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row text-light my-5 d-flex justify-content-center'>
                                        <div className='col-12 col-lg-10 col-xxl-8 d-flex flex-column'>

                                            <h2 style={styles.heading}>{t('Classes.18')}</h2>
                                            <div className={`my-5 ${classes.expired}`}>
                                                <div className={`row d-flex flex-nowrap py-3 border-bottom ${classes.heading}`}>
                                                        <div className='col-3'></div>
                                                        <div className='col-3'><b>{t('Classes.7')}</b></div>
                                                        <div className='col-3'><b>{t('Classes.18')}</b></div>
                                                        <div className='col-3'><b>{t('Classes.16')}</b></div>
                                      
                                                </div>
                                                {expired.map((i,index)=>
                                                    <div className={`row d-flex flex-nowrap my-3 ${classes.tab}`} style={styles.expired}>
                                                        <div className='col-3'>{index+1}</div>
                                                        <div className='col-3'>{i.name}</div>
                                                        <div className='col-3'>{i.expiry.slice(0,10)}</div>
                                                        <div className='col-3'><i onClick={handleShow.bind(null,i.id,true)} >{t('Classes.17')}</i></div>
                                                    </div>
                                                )}
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-faq" role="tabpanel" aria-labelledby="pills-faq-tab">
                                    
                                </div>
                                <div class="tab-pane fade" id="pills-review" role="tabpanel" aria-labelledby="pills-review-tab">
                                
                                    {!review&&!editing &&
                                    <div className='d-flex flex-column align-items-center'>
                                        <div className='m-auto text-center text-light my-4'>
                                            <i>No review added for this course</i>
                                        </div>
                                        <button type='button' className='d-inline-block btn btn-success' onClick={()=>setEditing(true)}>Add a review</button>
                                    </div>
                                    }
                                    {editing &&
                                    <div className='my-5 py-2'>
                                        <Form className='my-4 row d-flex flex-column align-items-center'>
                                            <div className='col-10 col-lg-4 my-5'>
                                            <Rating onClick={handleRating} initialValue={rating} />
                                            </div>
                                            <div className='col-10 col-lg-4'>
                                            <Form.Group >
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={8} 
                                                    maxLength={350}
                                                    value={description}
                                                    onChange={handleDescChange}
                                                    required
                                                />
                                            </Form.Group>   
                                            </div>
                                            
                                        </Form>
                                        <div className='col-12 my-4 d-flex justify-content-center'>
                                                <button onClick={()=>setEditing(false)} className='btn btn-danger mx-4'>
                                                    Cancel
                                                </button>
                                                <button onClick={handleReview} className='btn btn-success mx-4'>
                                                    Update
                                                </button>
                                            </div>
                                    </div>
                                    }
                                    {review&&
                                    <div className='row my-4 text-light d-flex justify-content-center'>
                                        
                                        <div className='col-11 p-2 p-lg-4 col-lg-8 col-xl-7 col-xxl-6 my-4' style={{backgroundColor:'rgb(41,45,54)',borderRadius:'20px'}}>
                                            <div className='row'>
                                                <div className='col-12 col-lg-6 p-3 p-lg-0 d-flex flex-column align-items-center'>
                                                    <div className={classes.ReviewPP} style={{backgroundImage:`url(${auth.user.avatar})`}}>

                                                    </div>
                                                    <div className='mt-4'><b>{auth.user.name}</b></div>
                                                    <div className='my-2'>Posted: {'2023-09-05'}</div>
                                                    <div><Rating readonly={true} initialValue={5}/></div>
                                                </div>
                                                <div className='col-12 col-lg-6 p-3 p-lg-0 d-flex flex-column justify-content-center align-items-center'>
                                                    {review.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    
                                    

                                </div>
                            </div>
                        </div>
                </div>
            </div>

         </div>
        
        </>

    )
}

export default Lecture