import { Outlet, Route, Routes, Navigate} from "react-router-dom";
import { useEffect,useState } from "react";
import { useAuth } from "./providers/ProvideAuth";
import {Toaster} from 'react-hot-toast';
import ScrollToTop from "./ScrollToTop";

import Home from "./components/HomePage/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutPage/AboutUs";
import ContactUs from "./components/ContactPage/ContactUs";
import FAQ from "./components/ContactPage/FAQ";
import Teacher from "./components/ProfilePage/Teachers";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Email_Verify from "./components/Email_Verify";
import Services from "./components/Services";
import Thanks from "./components/Thanks";
import Cancel from "./components/Cancel";
import Dashboard from "./components/Pages/Dashboard";
import DashboardSettings from "./components/Pages/DashboardSettings";
import Reset from "./components/Reset";
import HowToUse from "./components/HowToUse";
import LoginPage from "./components/LoginPage";
import UnsubscribeNewsLetter from "./components/UnsubscribeNewsLetter";
import SubscribeNewsLetter from "./components/SubscribeNewsLetter";

import TeacherDashboard from "./components/Pages/Teacher/Dashboard";
import TeacherUserProfile from "./components/Pages/Teacher/UserProfile";
import TeacherPageContent from "./components/Pages/Teacher/PageContent";
import TeacherLecture from "./components/Pages/Teacher/Lecture";
import TeacherVideoCall from "./components/Pages/Teacher/VideoCall";
import TeacherPayment from "./components/Pages/Teacher/Payment";
import TeacherCourses from "./components/Pages/Teacher/Courses";
import TeacherChat from "./components/Pages/Teacher/Chat";
import TeacherNewCourse from "./components/Pages/Teacher/NewCourse";
import TeacherSupport from "./components/Pages/Teacher/Support";
import TeacherNotif from './components/Pages/Teacher/Notifications';
import TeacherReports from "./components/Pages/Teacher/Reports";
import TeacherAvailability from "./components/Pages/Teacher/Availability";
import TeacherCourseInfo from "./components/Pages/Teacher/CourseInfo";
import TeacherReview from "./components/Pages/Teacher/ReviewPage";

import StudentDashboard from "./components/Pages/Student/Dashboard";
import StudentUserProfile from "./components/Pages/Student/UserProfile";
import StudentPageContent from "./components/Pages/Student/PageContent";
import StudentLecture from "./components/Pages/Student/Lecture";
import StudentVideoCall from "./components/Pages/Student/VideoCall";
import StudentPayment from "./components/Pages/Student/Payment";
import StudentCourses from "./components/Pages/Student/Courses";
import StudentChat from "./components/Pages/Student/Chat";
import StudentSupport from "./components/Pages/Student/Support";
import StudentNotif from './components/Pages/Student/Notifications';
import StudentReports from './components/Pages/Student/Reports';
import StudentTeachers from './components/Pages/Student/Teachers';
import StudentTeacherProfile from './components/Pages/Student/TeacherProfile';
import StudentCourseInfo from './components/Pages/Student/CourseInfo';
import StudentReview from "./components/Pages/Student/ReviewPage"

import AdminDashboard from "./components/Pages/Admin/Dashboard";
import AdminPageContent from "./components/Pages/Admin/PageContent";
import AdminAnalytics from "./components/Pages/Admin/Analytics";
import AdminChat from "./components/Pages/Admin/Chat";
import AdminTeachers from "./components/Pages/Admin/Teachers";
import AdminStudents from "./components/Pages/Admin/Students";
import AdminFinances from "./components/Pages/Admin/Finances";
import AdminSupport from "./components/Pages/Admin/Support";
import AdminMenu from "./components/Pages/Admin/Menu";
import AdminVideoCall from "./components/Pages/Admin/VideoCall";
import AdminInvoice from "./components/Pages/Admin/Invoice";


import { TokenKey } from "./utils";





export function PrivateRoute({children,...rest}){
  var auth = useAuth();
  return auth.user ? children 
  : 
  <>
  {localStorage.getItem(TokenKey)?children:
  <Navigate to='/' replace={true}/>}
  </>;

}

function App() {
 
  return(
    <div className="App">
      <audio autoPlay src={``}>

      </audio>

      <Routes>
      <Route exact path='/thanks/:courseId' element={<PrivateRoute><Thanks /></PrivateRoute>}></Route>
      {/* <Route exact path='/thanks' element={<PrivateRoute><Thanks /></PrivateRoute>}></Route> */}
      <Route exact path='/cancel' element={<PrivateRoute><Cancel /></PrivateRoute>}></Route>
        <Route element={
          <>
            <Navbar />
            <Outlet />
            <Footer />
          </>
        }>
          <Route exact path='' element={<Home />}></Route>
          <Route exact path='/about' element={<AboutUs />}></Route>
          <Route exact path='/faq' element={<FAQ />}></Route>
          <Route exact path='/contact' element={<ContactUs />}></Route>
          <Route exact path='/teacherdemo/:id' element={<Teacher />}></Route>
          <Route exact path='/terms' element={<Terms />}></Route>
          <Route exact path='/privacy' element={<Privacy />}></Route>
          <Route exact path='/use' element={<HowToUse />}></Route>
          <Route exact path='/email_verify/:token' element={<Email_Verify />}></Route>
          <Route exact path='/login' element={<LoginPage />}></Route>
          <Route exact path='/services' element={<Services />}></Route>
          <Route exact path='/reset/password/:token' element={<Reset />}></Route>
          <Route exact path='/newsletter/unsubscribe' element={<UnsubscribeNewsLetter />}></Route>
          <Route exact path='/newsletter/subscribe' element={<SubscribeNewsLetter />}></Route>
        </Route>
        
        <Route element={<Outlet />}>
          <Route exact path='/user' element={<PrivateRoute><Dashboard /></PrivateRoute>}>
          </Route>
          <Route exact path='/user/settings' element={<PrivateRoute><DashboardSettings /></PrivateRoute>}>
          </Route>
          <Route exact path='/student' element={<PrivateRoute><StudentDashboard /></PrivateRoute>}>
                <Route exact path='/student/' element={<PrivateRoute><StudentPageContent/></PrivateRoute>}></Route>
                <Route exact path='/student/settings' element={<PrivateRoute><StudentUserProfile /></PrivateRoute>}></Route>
                <Route exact path='/student/:course' element={<PrivateRoute><StudentLecture /></PrivateRoute>}></Route>
                <Route exact path='/student/videocall/:channel' element={<PrivateRoute><StudentVideoCall /></PrivateRoute>}></Route>
                <Route exact path='/student/chat' element={<PrivateRoute><StudentChat /></PrivateRoute>}></Route>
                <Route exact path='/student/courses' element={<PrivateRoute><StudentCourses /></PrivateRoute>}></Route>
                <Route exact path='/student/checkout' element={<PrivateRoute><StudentPayment /></PrivateRoute>}></Route>
                <Route exact path='/student/support' element={<PrivateRoute><StudentSupport /></PrivateRoute>}></Route>
                <Route exact path='/student/notifications' element={<PrivateRoute><StudentNotif /></PrivateRoute>}></Route>
                <Route exact path='/student/reports' element={<PrivateRoute><StudentReports /></PrivateRoute>}></Route>
                <Route exact path='/student/teachers' element={<PrivateRoute><StudentTeachers /></PrivateRoute>}></Route>
                <Route exact path='/student/teacherprofile/:id' element={<PrivateRoute><StudentTeacherProfile /></PrivateRoute>}></Route>
                <Route exact path='/student/course/info/:id' element={<PrivateRoute><StudentCourseInfo /></PrivateRoute>}></Route>
                <Route exact path='/student/review/page' element={<PrivateRoute><StudentReview /></PrivateRoute>}></Route>
              </Route>
          <Route exact path='/teacher' element={<PrivateRoute><TeacherDashboard /></PrivateRoute>}>
                <Route exact path='/teacher/' element={<PrivateRoute><TeacherPageContent/></PrivateRoute>}></Route>
                <Route exact path='/teacher/settings' element={<PrivateRoute><TeacherUserProfile /></PrivateRoute>}></Route>
                <Route exact path='/teacher/:course' element={<PrivateRoute><TeacherLecture /></PrivateRoute>}></Route>
                <Route exact path='/teacher/videocall/:channel' element={<PrivateRoute><TeacherVideoCall /></PrivateRoute>}></Route>
                <Route exact path='/teacher/chat' element={<PrivateRoute><TeacherChat /></PrivateRoute>}></Route>
                <Route exact path='/teacher/courses' element={<PrivateRoute><TeacherCourses /></PrivateRoute>}></Route>
                <Route exact path='/teacher/checkout' element={<PrivateRoute><TeacherPayment /></PrivateRoute>}></Route>
                <Route exact path='/teacher/reports' element={<PrivateRoute><TeacherReports /></PrivateRoute>}></Route>
                <Route exact path='/teacher/new/course' element={<PrivateRoute><TeacherNewCourse /></PrivateRoute>}></Route>
                <Route exact path='/teacher/support' element={<PrivateRoute><TeacherSupport /></PrivateRoute>}></Route>
                <Route exact path='/teacher/notifications' element={<PrivateRoute><TeacherNotif /></PrivateRoute>}></Route>
                <Route exact path='/teacher/availability' element={<PrivateRoute><TeacherAvailability /></PrivateRoute>}></Route>
                <Route exact path='/teacher/course/info/:id' element={<PrivateRoute><TeacherCourseInfo /></PrivateRoute>}></Route>
                <Route exact path='/teacher/review/page' element={<PrivateRoute><TeacherReview /></PrivateRoute>}></Route>
          </Route>
          <Route exact path='/admin' element={<PrivateRoute><AdminDashboard /></PrivateRoute>}>
                <Route exact path='/admin/' element={<PrivateRoute><AdminPageContent/></PrivateRoute>}></Route>
                <Route exact path='/admin/analytics' element={<PrivateRoute><AdminAnalytics/></PrivateRoute>}></Route>
                <Route exact path='/admin/videocall/:channel' element={<PrivateRoute><AdminVideoCall/></PrivateRoute>}></Route>
                <Route exact path='/admin/students' element={<PrivateRoute><AdminStudents/></PrivateRoute>}></Route>
                <Route exact path='/admin/teachers' element={<PrivateRoute><AdminTeachers/></PrivateRoute>}></Route>
                <Route exact path='/admin/menu' element={<PrivateRoute><AdminMenu/></PrivateRoute>}></Route>
                <Route exact path='/admin/finances' element={<PrivateRoute><AdminFinances/></PrivateRoute>}></Route>
                <Route exact path='/admin/chat' element={<PrivateRoute><AdminChat/></PrivateRoute>}></Route>
                <Route exact path='/admin/support' element={<PrivateRoute><AdminSupport/></PrivateRoute>}></Route>
                <Route exact path='/admin/invoice' element={<PrivateRoute><AdminInvoice/></PrivateRoute>}></Route>

          </Route>
        </Route>
        
      </Routes>
      <Toaster 
        containerStyle={{
            top:'50%',
        }}
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: 'rgb(250,250,250)',
            color: '#000',
            padding:'1rem',
            boxShadow:'0 1rem 3rem rgba(0,0,0,.175) !important'
          },
          duration:4000
        }}/>
        <ScrollToTop />
    </div>
  );
}

export default App;
