import React,{useState,useEffect} from 'react';
import { useParams,Link, useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import { Rating } from 'react-simple-star-rating';
import { useStudent } from '../../../providers/ProvideStudent';
import { useAuth } from '../../../providers/ProvideAuth';
import {getItemFromLocalStorage, TokenKey } from "../../../utils";
import { MdSort } from 'react-icons/md';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
function CourseInfo() {

    let [loading,setLoading] = useState(true);
    let [item,setItem] = useState({});
    let {t,i18n} = useTranslation();
    let [cart, setCart] = useState([]);
    const auth = useAuth();
    const student = useStudent();
    let {id} = useParams();
    let navigate = useNavigate();
    useEffect(()=>{
        (
            async function(){
                try{
                    let reply = await fetch(`https://scholarist.com:8000/api/course/info/${id}?lang=${i18n.language}`,{
                    method:'GET',
                    headers:{
                        'Content-type':'application/json'
                    }
                    });
                    
                    let response = await reply.json();
                    setItem(response.data)

                    
                    setLoading(false)
                }catch(err){
                    setLoading(false)
                    console.log(err,'dfvf.....\n\n')
                }
                
            }
        )();

    },[i18n.language]);
    
    function checkIfPurchased(item){
        for (let ele of student.courses){
            if(ele.id==item.id){
                return true;
            }
        }
        return false;
    }
    async function handleLogout(){
        let res=await auth.logout();
        toast.success('Logged out successfully');
        return navigate('/',{replace:true})
    }
    function checkInCart(item){
        for (let ele of cart){
            if(ele.id==item.id){
                return true;
            }
        }
    }
    async function removeFromCart(item){
        try{
          let reply = await fetch('https://scholarist.com:8000/api/students/cart/remove',{
              method:'POST',
              headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
              },
              body:JSON.stringify(item)
          });
          let response = await reply.json();
          await setCart(response.data);
        }catch(err){
          console.log(err,'....')
          return toast.error('Error in deleting course from cart')
        }
      };
    async function addToCart(item){
        try{
            let reply = await fetch('https://scholarist.com:8000/api/students/cart',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json',
                    'Authorization':`Bearer ${getItemFromLocalStorage(TokenKey)}`
                },
                body: JSON.stringify(item)
            })
            let response = await reply.json();
            
            if(response.success){
                await setCart([...cart,item])
                return toast.success('Added to Cart')
            }
        }catch(err){
            return toast.error('Some error in adding courses to cart')
        }
    }

    const styles={
        profileIcon:{
            backgroundImage:`url(${auth.user.avatar})`
        },
        heading:{
            fontFamily:'Baloo'
        },
        neon:{
            color:'rgb(144,251,220)!important',
            borderColor:'rgb(144,251,220)!important'
        }
    }
    return (
        <div id="page-content-wrapper">
            
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-4 px-4 text-white d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <a className='no-decor' data-bs-toggle="offcanvas" href="#sidebarOffcanvas" role="button" aria-controls="sidebarOffcanvas"><MdSort size={40} className='text-light me-3'/></a>
                    </div>
                    
                    <h2 className="m-0">{t('Courses_2.11')}</h2>

                    <div className='no-decor align-start d-md-flex align-items-center py-2 px-lg-3' onClick={handleLogout}>
                        <button className='btn btn-danger'><b>{t('Sidebar.10')}</b></button>
                    </div>
                    
                </nav>

                <div className='container-fluid my-5 py-5'>
                    {loading && 
                    <div className='my-5'>
                        <Box sx={{ display: 'flex',justifyContent:'center' }}>
                            <CircularProgress />
                        </Box>
                    </div>}
                    {!loading &&
                    <>
                        {console.log(checkIfPurchased(item),'dhbdhcbd.....\n\n')}
                        <div className='row w-75 m-auto d-flex py-5 justify-content-center'>
                        <div className='col-9 col-lg-4 offset-1 rounded my-3 d-flex align-items-center '>
                            <div className='course-image animate__animated animate__fadeInLeft' style={{backgroundImage:`url(${item.avatar})`}}>

                            </div>
                        </div>
                        <div className='col-11 d-flex justify-content-center align-items-center text-light flex-column d-lg-block col-lg-7 my-3 animate__animated animate__fadeInRight'>
                            <div className='my-3'>
                                <Rating initialValue={item.rating}/>
                            </div>
                            <h2 style={styles.heading} className='text-center text-lg-start'>
                                {item.name}
                            </h2>
                            <h6><i>{t('Courses_2.2')} <b>{item.Teacher.name}</b></i></h6>
                                {checkIfPurchased(item)?
                                <div className='my-4'>
                                    <button type='button' className=' bg-light btn px-3 me-2 btn-outline-success'><b>{t('Courses_2.12')} <i className="fa-solid fa-check"></i></b></button>
                                </div>
                                :
                                <>
                                
                                {checkInCart(item)?
                                <>
                                <div className='my-4'>
                                    <button onClick={removeFromCart.bind(null,item)} type='button' className='btn px-3 me-2 btn-outline-primary'><b>{t('Courses_2.4')} <i className="fa-solid fa-check"></i></b></button>
                                </div>
                                <Link className='my-4 no-decor' to='/student/checkout'>
                                    <button type='button' className='btn px-3 me-2 btn-danger'><b>{t('Courses_2.13')} <i className="fa-solid fa-check"></i></b></button>
                                </Link>
                                </>
                                
                                :
                                <div className='my-4'>
                                    <button onClick={addToCart.bind(null,item)} type='button' className='btn px-3 me-2 btn-primary'><b>{t('Courses_2.5')}</b></button>
                                </div>
                                }
                                </>
                                
                                }
                            
                        </div>
                        <div className='col-12 d-flex flex-column align-items-center text-light'>
                            <p className='mt-5 mb-3'>
                                {item.description}
                            </p>
                            <p className='mb-3'>
                                <b><i>{t('Courses_2.6')} &nbsp; {item.day_of_week} &nbsp; @ {item.start_time} to {item.end_time}</i></b>
                            </p>
                            <p className='mb-3'>
                                <b><i>{t('Courses_2.7')} Begins on {item.start_day} and ends on {item.end_day}</i></b>
                            </p>
                            <p className='mb-3'>
                                <b><i>{t('Courses_2.8')} &nbsp;CAD$ {item.price}</i></b>
                            </p>
                        </div>
                
                        </div>
                    </>}
                    
                </div>


        </div>
    )
}

export default CourseInfo
